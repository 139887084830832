import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IUser, CognitoService } from '../services/cognito.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirm-sign-up',
  templateUrl: './confirm-sign-up.component.html',
  styleUrls: ['./confirm-sign-up.component.css'],
})
export class ConfirmSignUpComponent {
  loading: boolean;
  isConfirm: boolean;
  user: IUser;
  code: string = '';
  orderObj: any;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.loading = false;
    this.isConfirm = false;
    this.user = {} as IUser;
    // this.createForm();
  }

  public confirmSignUp(): void {
    let is_valid = true;
    if (!this.code) {
      this.toastr.warning('Please enter the verification code');
      is_valid = false;
    }
    if (is_valid) {
      this.route.queryParamMap.subscribe((params) => {
        this.orderObj = { ...params.keys, ...params };
      });
      this.user.email = this.orderObj.params.email;
      this.loading = true;
      this.cognitoService
        .confirmSignUp(this.user, this.code)
        .then((u) => {
          this.toastr.success('user authenticated successfully');
          this.router.navigate(['/signIn']);
        })
        .catch((e) => {
          this.toastr.error(e.message);
          this.loading = false;
        });
    }
  }
}
