import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../services/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockService } from '../../services/block.service';

@Component({
  selector: 'app-block-bulk-details',
  templateUrl: './block-bulk-details.component.html',
  styleUrls: ['./block-bulk-details.component.css'],
  providers: [BlockService],
})
export class BlockBulkDetailsComponent implements OnInit {
  farm_id: string = '';
  showRemove: boolean = false;
  blocks: any = [];
  current_index: number = -1;
  varieties: any = ['MQ239A', 'MQ239B', 'MQ239C'];
  crop_classes: any = ['1st Ratoon', '2nd Ratoon', '3rd Ratoon'];
  presentations: any = ['Erect', 'Bent', 'Fallen'];

  constructor(
    private router: Router,
    private dialog: DialogService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private blockSAPI: BlockService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.farm_id = params['id'];
      this.addBlock();
    });
  }

  saveAll(): any {
    let is_valid = true;
    for (let i = 0; i < this.blocks.length; i++) {
      let block = this.blocks[i];
      if (!block.block_number) {
        is_valid = false;
        this.toastr.warning(
          'There are blocks without the block number please enter the block number for them.'
        );
      }
      if (!block.row_spacing) {
        is_valid = false;
        this.toastr.warning(
          'Please enter the Row Spacing for block number: ' + block.block_number
        );
      }
      if (!block.average_row_length) {
        is_valid = false;
        this.toastr.warning(
          'Please enter the Avg. Row Length for block number: ' +
            block.block_number
        );
      }
      if (!block.average_infield_haulout_distance) {
        is_valid = false;
        this.toastr.warning(
          'Please enter the Avg. Infield Haulout Distance for block number: ' +
            block.block_number
        );
      }
      if (!block.average_harvester_turnaround_time) {
        is_valid = false;
        this.toastr.warning(
          'Please enter the Avg. Harvester Turnaround Time for block number: ' +
            block.block_number
        );
      }

      if (!is_valid) {
        return;
      }
    }

    if (is_valid) {
      let all_blokcs_ok = true;
      this.blocks.forEach((block: any) => {
        let _block = {
          block_number: block.block_number,
          row_spacing: block.row_spacing,
          average_row_length: block.average_row_length,
          average_infield_haulout_distance:
            block.average_infield_haulout_distance,
          average_harvester_turnaround_time:
            block.average_harvester_turnaround_time,
          variety: block.variety,
          crop_class: block.crop_class,
          presentation: block.presentation,
          field_moisture: block.field_moisture,
          delivered_yield_stimate: Number(block.delivered_yield_stimate),
          estimated_ccs: block.estimated_ccs,
          topping: block.topping,
          number_haulouts_utilised: block.number_haulouts_utilised,
          average_bin_mass: block.average_bin_mass,
          allowance_received: block.allowance_received,
          proportion_total_allowances_paid_to_driver:
            block.proportion_total_allowances_paid_to_driver,
        };

        this.blockSAPI.create(_block, block.farm_id).subscribe((s) => {
          if (!s.success) {
            all_blokcs_ok = false;
          }
        });
      });

      if (!all_blokcs_ok) {
        this.toastr.warning('Not all blocks were created.');
      } else {
        this.toastr.success('All blocks were created successfully.');
        this.router.navigate(['/farm'], { queryParams: { id: this.farm_id } });
      }
    }
  }

  addBlock(): any {
    if (this.current_index < 0) {
      this.current_index = 0;
    } else {
      this.showRemove = true;
      this.current_index += 1;
    }

    this.blocks.push({
      index: this.current_index,
      block_number: '',
      row_spacing: '',
      average_row_length: '',
      average_infield_haulout_distance: '',
      average_harvester_turnaround_time: '',
      variety: '',
      crop_class: '',
      presentation: '',
      field_moisture: '',
      delivered_yield_stimate: '',
      estimated_ccs: '',
      topping: '',
      number_haulouts_utilised: '',
      average_bin_mass: '',
      allowance_received: '',
      proportion_total_allowances_paid_to_driver: '',
      farm_id: this.farm_id,
    });
  }

  yesNoDialog(index: number) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message:
          'Are you sure you want to delete the block ' +
          this.blocks[index].block_number +
          '?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        if (yes) {
          this.removeBlock(index);
        }
      });
  }

  removeBlock(index: number): any {
    this.blocks.splice(index, 1);
    if (this.blocks.length == 1) {
      this.showRemove = false;
    }

    for (let i = 0; i < this.blocks.length; i++) {
      this.current_index = i;
      this.blocks[i].index = i;
    }
  }
}
