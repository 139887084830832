<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>About Harvest Mate</h2>
    <mat-card class="example-card">
      <!-- <mat-card-header>
        <mat-card-title>Quaerat Voluptatem</mat-card-title>
        <mat-card-subtitle>Voluptate Velit</mat-card-subtitle>
      </mat-card-header> -->
      <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
      <mat-card-content>
        <p>
          The development of Harvest Mate was funded by Sugar Research Australia (SRA) and the Department of Agriculture
          and Fisheries,
          Queensland (DAF). Data was utilised from the 'Adoption of Practice to Mitigate Harvest Losses' project,
          co-funded by the Australian
          Commonwealth Rural R&D for Profit Program.
        </p>
        <p>
          Development of the online portal and app interface was by Clevvi.
        </p>
        <p>
          Acknowledgement to the following contributors for the development of Harvest Mate:<br>
          Phil-Anthony Patane (SRA)<br>
          Brendon Nothard (DAF)<br>
          Matthew Thompson (DAF)<br>
          Muyi Olayemi (SRA)<br>
          Joanne Stringer (SRA)<br>
          Garry Landers (SRA)<br>
          Carol Norris (SRA)<br>
          <br>
          <br>
        </p>
      </mat-card-content>
      <mat-card-actions>
        <a id='about-us-link' mat-button href="assets/Harvest Mate User Guide v1.013_Final edit V5.pdf">User
          Guide</a>
        <a id='about-us-link' mat-button href="https://sugarresearch.com.au/research/harvest-mate-project/">Harvest Mate
          - Sugar Research Australia</a>
      </mat-card-actions>
    </mat-card>

    <div class="fieldwrap fieldwrap-type-submit align-left">
      <a class="btn__secondary btn-back" routerLink="/home">Back</a>
    </div>
  </div>
</div>