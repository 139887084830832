import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  ConfirmComponent,
  ConfirmDialogData,
} from '../dialogs/confirm/confirm.component';
import {
  InviteComponent,
  InviteDialogData,
} from '../dialogs/invite/invite.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirmDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.dialog
      .open(ConfirmComponent, {
        data,
        width: '400px',
        disableClose: true,
      })
      .afterClosed();
  }
  inviteDialog(data: InviteDialogData): Observable<boolean> {
    return this.dialog
      .open(InviteComponent, {
        data,
        width: '450px',
        disableClose: true,
      })
      .afterClosed();
  }
}
