<div id="login">
  <div class="login-inner text-white scrollbar">
    <div class="content-padding">
      <div class="login-header">
        <!-- <h1 class="login-title"><span><strong>GGG <br/>Mate</strong></span></h1> -->
        <img src="images/harvest_mate_logo_white.png" />
      </div>

      <div class="login-box">
        <div id="form-signup" class="login-form form-open">
          <h2 class="h4">Create an Account</h2>
          <form>
            <div class="fieldwrap fieldwrap_type_input">
              <label for="user_type" class="label-ontop">User Type:</label>
              <select id="user_type" name="user_type" #userType="ngModel" [(ngModel)]="user.userType"
                class="form-select form-control-sm">
                <option value="CONTRACTOR">Contractor</option>
                <option value="GROWER">Grower</option>
              </select>
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">First Name</label>
              <input type="text" id="given_name" name="given_name" #givenName="ngModel" [(ngModel)]="user.givenName" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Last Name</label>
              <input type="text" id="last_name" name="last_name" #lastName="ngModel" [(ngModel)]="user.lastName" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Email</label>
              <input type="email" id="email" name="email" #email="ngModel" [(ngModel)]="user.email" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Phone Number</label>
              <input type="text" id="phone_number" name="phone_number" #phoneNumber="ngModel"
                [(ngModel)]="user.phoneNumber" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Address</label>
              <input type="text" id="address" name="address" #address="ngModel" [(ngModel)]="user.address" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Password</label>
              <input type="password" id="password" name="password" #password="ngModel" [(ngModel)]="user.password" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Confirm Password</label>
              <input type="password" id="confirmPassword" name="confirmPassword" #confirmpassword="ngModel"
                [(ngModel)]="confirmPassword" />
            </div>

            <mat-checkbox id="acceptTermsOfUse" name="acceptTermsOfUse" [(ngModel)]="acceptTermsOfUse">Accept the
              <a href="assets/HarvestMateTM Terms of Use.docx">Terms of
                Use</a></mat-checkbox>

            <mat-checkbox id="acceptPrivacyPolicy" name="acceptPrivacyPolicy" [(ngModel)]="acceptPrivacyPolicy">Accept
              the <a href="assets/privacypolicy.pdf">Privacy Policy</a></mat-checkbox>

            <div class="fieldwrap fieldwrap_type_submit">
              <button type="submit" (click)="signUp()">Signup</button>
              <a class="btn__secondary btn-login" routerLink="/signIn">Back</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>