import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Console } from 'console';
import { GroupService } from '../../services/group.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { ActivatedRoute } from '@angular/router';
import { InputPostData } from 'src/app/models/InputData';
import { ToastrService } from 'ngx-toastr';
import { BlockService } from '../../services/block.service';
import { nextTick } from 'process';
import { Location } from '@angular/common';
import { CognitoService } from 'src/app/services/cognito.service';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-contract-negotiation',
  templateUrl: './contract-negotiation-grower.component.html',
  styleUrls: ['./contract-negotiation-grower.component.css'],
  providers: [GroupService, BlockService, HarvestService, InputPostData],
})
export class ContractNegotiationGrowerComponent implements OnInit {
  group_name: any;
  group_id: any;
  growersData: any = [];
  selectedFarmsList: any = [];
  show_results_dialog_open: boolean = false;
  dataPost: any = {};

  yield_change: number = 0;
  net_benefit: number = 0;
  fixed_costs: number = 0;

  cost_ha_change: number = 0;
  cost_tonne_change: number = 0;
  cost_hour_change: number = 0;
  std_cost_ha: number = 0;
  std_cost_tonne: number = 0;
  std_cost_hour: number = 0;
  interest_cost_std: number = 0;
  std_depreciation_cost: number = 0;
  std_fuel_cost: number = 0;
  std_fuel_use_harvesting_ltr_per_hour: number = 0;
  std_labour_cost: number = 0;
  std_repair_and_maintenance_cost: number = 0;
  std_total_cost: number = 0;
  pred_total_cost: number = 0;
  std_hours_per_day_cutting: number = 0;
  std_hours_per_day_total: number = 0;
  std_bin_weight: number = 0;
  std_allowance: number = 0;
  std_yield: number = 0;
  std_flow_rate: number = 0;
  std_ground_speed: number = 0;
  std_fan_speed: number = 0;

  interest_cost_pred: number = 0;
  pred_labour_cost: number = 0;
  pred_allowance: number = 0;
  pred_cost_per_tonne: number = 0;
  pred_fuel_cost: number = 0;
  pred_fuel_use_harvesting_ltr_per_hour: number = 0;
  pred_repair_and_maintenance_cost: number = 0;
  pred_hours_per_day_cutting: number = 0;
  pred_hours_per_day_total: number = 0;
  pred_bin_weight: number = 0;
  pred_cost_tonne: number = 0;
  pred_cost_hour: number = 0;
  pred_cost_ha: number = 0;
  pred_yield: number = 0;
  pred_flow_rate: number = 0;
  pred_ground_speed: number = 0;
  pred_fan_speed: number = 0;
  pred_depreciation_cost: number = 0;


  average_dialog_open: boolean = false;
  fuel_consumption_adjustment_input: number = 0;
  new_fuel: number = 0;
  fuel_consumption_adjustment: number = 0;
  
  block_results: any;
  counter: number = 0;
  
  selectedGroup: any = {};
  isContract: boolean = false;
  is_processing_calculations: boolean = false;
  userIsContactor?: boolean;

  contract_includes_fuel: boolean = false;


  constructor(
    private location: Location,
    private blockApi: BlockService,
    private groupApi: GroupService,
    private harvesterApi: HarvestService,
    private route: ActivatedRoute,
    private inputData: InputPostData,
    private toastr: ToastrService,
    private auth: CognitoService,
    private dialog: DialogService
  ) {}

  saveResults(payload: any) {
    this.blockApi.save_results(payload).subscribe((s) => {

      this.loadItems();
      this.loadGrowers();
    });

    this.is_processing_calculations = false;
  }

  async recalculateBlock() {
    let payload_save_results: any = {
      group_id: this.group_id,
      items: [],
    };

    for (const g of this.growersData) {
      //loop through growers
      for (const f of g.farms) {
        //loop through farms
        for (const b of f.blocks) {
          if (!b.contract_element) {
            continue;
          }

          let payload: any = {
            group_id: this.group_id,
            farm_id: f.id,
            block_number: b.block_number,
            pred_flow_rate:
              b.contract_element.result.block_agronomic_results.flow_rate_pred,
          };

          //let block_results:any={};
          this.blockApi.calculateBlock(payload).subscribe({
            next: (block_results: any) => {
              payload_save_results.items.push({
                farm_id: f.id,
                block_number: b.block_number,
                result: block_results.body[0],
                representative_area: Number(b.contract_element.representative_area),
              });
            },
            error: ({error}) => this.toastr.error(error.errorMessage)
          });
        }
      }
    }
    this.saveResults(payload_save_results);
  }

  updateGroup(data: any): any {
    let result = false;
    this.groupApi.update(data, this.group_id).subscribe(
      (h) => {
        if (h.body) {
          this.toastr.success('Group update successfully.');
          result = true;
        } else {
          this.toastr.error(h.errorMessage);
          result = false;
        }
      },
      (error) => {
        this.toastr.error(error.error.message)
        result = false;
      }
    );
    return result;
  }

  calculateNewFuel() {
    this.fuel_consumption_adjustment =
      this.fuel_consumption_adjustment_input /
      (this.std_fuel_use_harvesting_ltr_per_hour /
        this.counter /
        this.selectedGroup.fuel_consumption_adjustment);
  }

  saveFuelConsumption() {
    this.is_processing_calculations = true;
    this.selectedGroup.fuel_consumption_adjustment ??= 1;

    this.calculateNewFuel();
    let data = {
      fuel_consumption_adjustment: this.fuel_consumption_adjustment,
    };
    this.updateGroup(data);
    this.recalculateBlock();
  }

  loadItems(): any {
    this.groupApi.get(this.group_id).subscribe((res) => {
      this.selectedGroup = res.body;
      this.fuel_consumption_adjustment =
        this.selectedGroup.fuel_consumption_adjustment ?? 1;
      this.fuel_consumption_adjustment_input = +(
        this.std_fuel_use_harvesting_ltr_per_hour / this.counter
      ).toPrecision(3);
    });

    // this.groupApi.getAll()
    //   .subscribe(l => {

    //     if (l.body) {
    //       this.selectedGroup = this.groupApi l.body.find((obj: any) => obj.ext_id == this.group_id)

    //     }
    //   })
  }

  async loadGrowers(): Promise<any> {
    this.yield_change = 0;
    this.net_benefit = 0;
    this.fixed_costs = 0;

    this.cost_ha_change = 0;
    this.cost_tonne_change = 0;
    this.cost_hour_change = 0;
    this.std_cost_ha = 0;
    this.std_cost_tonne = 0;
    this.std_cost_hour = 0;
    this.interest_cost_std = 0;
    this.std_depreciation_cost = 0;
    this.std_fuel_cost = 0;
    this.std_fuel_use_harvesting_ltr_per_hour = 0;
    this.std_labour_cost = 0;
    this.std_repair_and_maintenance_cost = 0;
    this.std_total_cost = 0;
    this.pred_total_cost = 0;
    this.std_hours_per_day_cutting = 0;
    this.std_hours_per_day_total = 0;
    this.std_bin_weight = 0;
    this.std_allowance = 0;
    this.std_yield = 0;
    this.std_flow_rate = 0;
    this.std_ground_speed = 0;
    this.std_fan_speed = 0;

    this.interest_cost_pred = 0;
    this.pred_labour_cost = 0;
    this.pred_allowance = 0;
    this.pred_cost_per_tonne = 0;
    this.pred_fuel_cost = 0;
    this.pred_fuel_use_harvesting_ltr_per_hour = 0;
    this.pred_repair_and_maintenance_cost = 0;
    this.pred_hours_per_day_cutting = 0;
    this.pred_hours_per_day_total = 0;
    this.pred_bin_weight = 0;
    this.pred_cost_tonne = 0;
    this.pred_cost_hour = 0;
    this.pred_cost_ha = 0;
    this.pred_yield = 0;
    this.pred_flow_rate = 0;
    this.pred_ground_speed = 0;
    this.pred_fan_speed = 0;
    this.pred_depreciation_cost = 0;

    this.counter = 0;

    this.growersData = [];

    this.groupApi.list_growers_in_group(this.group_id).subscribe((l) => {
      if (l.body) {
        this.growersData = l.body.growers;
        
        this.growersData.forEach((grower: any) => {
          //loop through growers
          grower.farms.forEach((farm: any) => {
            //loop through farms
            farm.blocks.forEach((block: any) => {
              //loop through blocks
              if (block.contract_element && block.contract_element.result.full_costings) {
                //get the results from the blocks if theres some
                this.isContract = true;
                this.net_benefit +=
                  block.contract_element.result.full_costings.net_benefit;

                this.fixed_costs +=
                  block.contract_element.result.full_costings.fixed_costs;

                this.cost_ha_change +=
                  block.contract_element.result.full_costings.cost_ha_change;

                this.cost_tonne_change +=
                  block.contract_element.result.full_costings.cost_tonne_change;

                this.std_fuel_cost +=
                  block.contract_element.result.full_costings.std_fuel_cost;

                this.std_depreciation_cost +=
                  block.contract_element.result.full_costings.std_depreciation_cost;

                this.pred_depreciation_cost += block.contract_element.result.full_costings.pred_depreciation_cost;

                this.interest_cost_std += block.contract_element.result.full_costings.std_interest;
                this.interest_cost_pred += block.contract_element.result.full_costings.pred_interest;
                this.std_fuel_use_harvesting_ltr_per_hour +=
                  block.contract_element.result.full_costings.std_fuel_use_harvesting_ltr_per_hour;

                this.std_labour_cost +=
                  block.contract_element.result.full_costings.std_labour_cost;

                this.cost_hour_change += block.contract_element.result.block_economic_results_harvester.cost_change_hr

                this.pred_labour_cost += block.contract_element.result.full_costings.pred_labour_cost;

                this.std_repair_and_maintenance_cost +=
                  block.contract_element.result.full_costings.std_repair_and_maintenance_cost;

                this.pred_repair_and_maintenance_cost += block.contract_element.result.full_costings.pred_repair_and_maintenance_cost

                this.std_total_cost +=
                  block.contract_element.result.full_costings.std_total_cost;
                
                this.pred_total_cost += block.contract_element.result.full_costings.pred_total_cost

                this.pred_fuel_cost +=
                  block.contract_element.result.full_costings.pred_fuel_cost;

                this.std_allowance += block.contract_element.result.full_costings.std_allowance;
                this.pred_allowance +=
                  block.contract_element.result.full_costings.pred_allowance;

                this.pred_cost_per_tonne +=
                  block.contract_element.result.full_costings.pred_cost_per_tonne;

                this.pred_fuel_use_harvesting_ltr_per_hour +=
                  block.contract_element.result.full_costings.pred_fuel_use_harvesting_ltr_per_hour;
                
                this.std_fan_speed += block.contract_element.result.block_agronomic_results.fan_speed_std;
                this.pred_fan_speed += block.contract_element.result.block_agronomic_results.fan_speed_pred;

                this.std_hours_per_day_cutting += block.contract_element.result.block_agronomic_results.hours_per_day_cutting_std;
                this.pred_hours_per_day_cutting += block.contract_element.result.block_agronomic_results.hours_per_day_cutting_pred;

                this.std_hours_per_day_total += block.contract_element.result.block_agronomic_results.hours_per_day_total_std;
                this.pred_hours_per_day_total += block.contract_element.result.block_agronomic_results.hours_per_day_total_pred;

                this.std_flow_rate += block.contract_element.result.block_agronomic_results.flow_rate_std;
                this.pred_flow_rate += block.contract_element.result.block_agronomic_results.flow_rate_pred;
                this.yield_change += block.contract_element.result.block_agronomic_results.pred_yield_change;

                this.std_yield += block.contract_element.result.block_agronomic_results.yield_std;
                this.pred_yield += block.contract_element.result.block_agronomic_results.yield_pred;

                this.std_bin_weight += block.contract_element.result.block_agronomic_results.bin_weight_std;
                this.pred_bin_weight += block.contract_element.result.block_agronomic_results.bin_weight_pred;

                this.std_ground_speed += block.contract_element.result.block_agronomic_results.ground_speed_std;
                this.pred_ground_speed += block.contract_element.result.block_agronomic_results.ground_speed_pred;

                this.std_cost_ha += block.contract_element.result.block_economic_results_harvester.cost_ha_std;
                this.pred_cost_ha += block.contract_element.result.block_economic_results_harvester.cost_ha_pred;

                this.std_cost_hour += block.contract_element.result.block_economic_results_harvester.cost_hr_std;
                this.pred_cost_hour += block.contract_element.result.block_economic_results_harvester.cost_hr_pred;

                this.std_cost_tonne += block.contract_element.result.block_economic_results_harvester.cost_t_std;
                this.pred_cost_tonne += block.contract_element.result.block_economic_results_harvester.cost_t_pred;

                this.counter++;
              }
            });
          });

          this.fuel_consumption_adjustment_input = +(
            this.std_fuel_use_harvesting_ltr_per_hour / this.counter
          ).toPrecision(3);
        });
      }
    });
    if (this.counter == 0) {
      //if counter = 0 means theres no active contracts, display message
      this.isContract = false;
    }
  }

  show_results(): void {
    this.show_results_dialog_open = true;
  }

  ngOnInit(): void {
    this.dataPost = this.inputData.dataType;
    this.route.queryParams.subscribe((params) => {
      this.group_name = params['name'];
      this.group_id = params['id'];
      this.auth.getUser().then(user => {
        this.userIsContactor = user.attributes["custom:user_type"] === "CONTRACTOR"
      })
      this.loadGrowers().then(() => {this.is_processing_calculations = false;});
      this.loadItems();

      this.groupApi.get(this.group_id).subscribe(g => {
        this.contract_includes_fuel = g.body.contract_includes_fuel || false;
      });

    });
  }

  resetAllCalculationsDialog() {
    let data = this.growersData;
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to reset all calculations for all blocks in this group?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe(async (yes) => {
        if (yes) {
          try {
            for(let o = 0; o < data.length;o++) {
              let payload : any = {
                group_id : this.group_id,
                items: []
              }

              for(let f = 0; f < data[o].farms.length; f++) {
                let farm = data[o].farms[f];
                let farmBLocks = farm.blocks;
                for(let b = 0; b < farmBLocks.length; b++) {
                  let block = farmBLocks[b];
                  if(block.contract_element && block.contract_element.result.full_costings) {
                    payload.items.push({
                      farm_id: farm.id,
                      block_number: block.block_number,
                      representative_area: block.contract_element.representative_area,
                      result: []
                    })
                  }
                }
              }

              if(payload.items.length > 0) {
                await this.blockApi.save_results(payload).toPromise();
                this.toastr.success('Results for blocks reset successfully.');
                this.counter = 0;
              } else {
                this.toastr.success('No blocks need to reset.');
              }
            }
            
          }catch(ee : any) {
            this.toastr.error(ee.message);
          }
        }
      });
  }
}
