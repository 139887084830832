import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IUser, CognitoService } from '../services/cognito.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent {
  loading: boolean;
  isConfirm: boolean;
  user: IUser;
  // signupForm: FormGroup;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private toastr: ToastrService
  ) {
    this.loading = false;
    this.isConfirm = false;
    this.user = {} as IUser;
    // this.createForm();
  }

  public async sendPasswordCode(): Promise<any> {
    let is_valid = true;
    if (!this.user.email) {
      this.toastr.warning('Please enter Email');
      is_valid = false;
    }

    if (is_valid) {
      this.cognitoService
        .forgotPassword(this.user)
        .then((u) => {
          this.router.navigate(['/resetPassword'], {
            queryParams: { email: this.user.email },
          });
        })
        .catch((e) => {
          this.toastr.error(e.message);
        });
    }
  }
}
