import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  info(message: any) {
    if (isDevMode()) {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  }

  error(error: any) {
    if (isDevMode()) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
}
