import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface InviteDialogData {
  title: string;
  message: string;
  confirmCaption: string;
  cancelCaption: string;
  input: string;
}

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css'],
})
export class InviteComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: InviteDialogData) {}
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  testFunc() {
    return 'TEST DATA';
  }
}
