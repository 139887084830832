<div class="content-1920">
  <div class="content-padding margin-top-small">
    <div class="main">
      <h2>Add Block - Bulk</h2>
      <div
        *ngFor="let block of blocks"
        class="columns-wrap"
        style="border-bottom: 1px solid #a3bcbd !important"
      >
        <div class="columns3">
          <div class="fieldwrap">
            <label>Block Number</label>
            <input
              type="text"
              id="block_number"
              name="block_number"
              #block_number="ngModel"
              [(ngModel)]="block.block_number"
            />
          </div>
          <div class="fieldwrap">
            <label>Row Spacing</label>
            <input
              type="text"
              id="row_spacing"
              name="row_spacing"
              #row_spacing="ngModel"
              [(ngModel)]="block.row_spacing"
            />
          </div>
          <div class="fieldwrap">
            <label>Avg. Row Length</label>
            <input
              type="text"
              id="average_row_length"
              name="average_row_length"
              #average_row_length="ngModel"
              [(ngModel)]="block.average_row_length"
            />
          </div>
          <div class="fieldwrap">
            <label>Avg. Infield Haulout Distance</label>
            <input
              type="text"
              id="average_infield_haulout_distance"
              name="average_infield_haulout_distance"
              #average_infield_haulout_distance="ngModel"
              [(ngModel)]="block.average_infield_haulout_distance"
            />
          </div>
          <div class="fieldwrap">
            <label>Variety</label>
            <select
              id="variety"
              name="variety"
              required
              #variety="ngModel"
              [(ngModel)]="block.variety"
            >
              <option>Select variety</option>
              <option *ngFor="let v of varieties" value="{{ v }}">
                {{ v }}
              </option>
            </select>
          </div>
          <div class="fieldwrap">
            <label>Crop class</label>
            <select
              id="crop_class"
              name="crop_class"
              required
              #crop_class="ngModel"
              [(ngModel)]="block.crop_class"
            >
              <option>Select crop class</option>
              <option *ngFor="let c of crop_classes" value="{{ c }}">
                {{ c }}
              </option>
            </select>
          </div>
          <div class="fieldwrap">
            <label>Presentation</label>
            <select
              id="presentation"
              name="presentation"
              required
              #presentation="ngModel"
              [(ngModel)]="block.presentation"
            >
              <option>Select Presentation</option>
              <option *ngFor="let p of presentations" value="{{ p }}">
                {{ p }}
              </option>
            </select>
          </div>
          <div class="fieldwrap">
            <label>Field Moisture</label>
            <input
              type="text"
              id="field_moisture"
              name="field_moisture"
              #field_moisture="ngModel"
              [(ngModel)]="block.field_moisture"
            />
          </div>
          <div class="fieldwrap">
            <label>Delivered Yield Estimate (t/ha)</label>
            <input
              type="text"
              id="delivered_yield_stimate"
              name="delivered_yield_stimate"
              #delivered_yield_estimate="ngModel"
              [(ngModel)]="block.delivered_yield_stimate"
            />
          </div>
          <div class="fieldwrap">
            <label>Estimated CCS (units)</label>
            <input
              type="text"
              id="estimated_ccs"
              name="estimated_ccs"
              #estimated_ccs="ngModel"
              [(ngModel)]="block.estimated_ccs"
            />
          </div>
          <div class="fieldwrap">
            <label>Topping</label>
            <input
              type="text"
              id="topping"
              name="topping"
              #topping="ngModel"
              [(ngModel)]="block.topping"
            />
          </div>
          <div class="fieldwrap">
            <label>No. of Haulouts utilised</label>
            <input
              type="text"
              id="number_haulouts_utilised"
              name="number_haulouts_utilised"
              #number_haulouts_utilised="ngModel"
              [(ngModel)]="block.number_haulouts_utilised"
            />
          </div>
          <div class="fieldwrap">
            <label>Average siding bin mass (tonnes)</label>
            <input
              type="text"
              id="average_bin_mass"
              name="average_bin_mass"
              #average_bin_mass="ngModel"
              [(ngModel)]="block.average_bin_mass"
            />
          </div>
          <div class="fieldwrap">
            <label
              >Allowance Received (for block/representative area) $/t</label
            >
            <input
              type="text"
              id="allowance_received"
              name="allowance_received"
              #allowance_received="ngModel"
              [(ngModel)]="block.allowance_received"
            />
          </div>
          <div class="fieldwrap">
            <label>Proportion of total Allowances paid to Drivers (%)</label>
            <input
              type="text"
              id="proportion_total_allowances_paid_to_driver"
              name="proportion_total_allowances_paid_to_driver"
              #proportion_total_allowances_paid_to_driver="ngModel"
              [(ngModel)]="block.proportion_total_allowances_paid_to_driver"
            />
          </div>

          <div
            *ngIf="showRemove"
            class="fieldwrap fieldwrap-type-submit align-right"
          >
            <button
              class="btn-remove"
              type="submit"
              style="margin-top: 45px !important"
              (click)="yesNoDialog(block.index)"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
      <button class="btn-add" type="submit" (click)="addBlock()">Block</button>
      <div class="fieldwrap fieldwrap-type-submit align-right">
        <a
          class="btn__secondary btn-back"
          routerLink="/farm"
          routerLinkActive="active"
          [queryParams]="{ id: farm_id }"
          >Back</a
        >
        <button class="btn-save" type="submit" (click)="saveAll()">
          Save All
        </button>
      </div>
    </div>
  </div>
</div>
