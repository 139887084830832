<div class="content-1920">
  <div class="content-padding margin-top-small">
    <div class="main">
      <h2>crop Details</h2>
      <fieldset>
        <div class="columns-wrap">
          <div class="columns2">
            <!-- <div class="fieldwrap label-inline">
                            <label>Block No</label>
                            <select id="block_id" name="block_id" #block_id="ngModel"
                                [(ngModel)]="crop.block_id">
                                <option *ngFor="let e of blockList" value="{{e.id}}">{{e.block_number}}
                                </option>
                            </select>
                        </div> -->
            <div class="fieldwrap label-inline">
              <label>Block No.</label>
              <input readonly type="text" id="blockName" name="blockName" #blockName="ngModel"
                [(ngModel)]="block_number" />
            </div>
            <div class="fieldwrap label-inline">
              <label>Season</label>
              <select id="millSeason" name="millSeason" #millSeason="ngModel" [(ngModel)]="crop.season">
                <option *ngFor="let e of seasonList" value="{{ e }}">
                  {{ e }}
                </option>
              </select>
            </div>

            <div class="fieldwrap label-inline">
              <label>Variety</label>
              <select id="Variety" name="Variety" #variety_id="ngModel" [(ngModel)]="crop.variety_id">
                <option *ngFor="let e of varietyList" value="{{ e.id }}">
                   {{ e.name }} <!-- <sup *ngIf="e.has_pbr" class="pbr">(PBR)</sup> --> <span
                    *ngIf="e.status !== 'ACTIVE'">(discontinued)</span>
                </option>
              </select>
            </div>
            <div class="fieldwrap label-inline">
              <label>Crop Class</label>
              <select id="CropClass" name="CropClass" #CropClass="ngModel" [(ngModel)]="crop.crop_class">
                <option *ngFor="let e of CropList" value="{{ e }}">
                  {{ e }}
                </option>
              </select>
            </div>
            <div class="fieldwrap label-inline">
              <label>Presentation</label>
              <select id="Presentation" name="Presentation" #Presentation="ngModel" [(ngModel)]="crop.presentation">
                <option *ngFor="let e of presentationList" value="{{ e }}">
                  {{ e }}
                </option>
              </select>
            </div>
            <div class="fieldwrap label-inline">
              <label>Topping</label>
              <select id="Topping" name="Topping" #Topping="ngModel" [(ngModel)]="crop.topping">
                <option *ngFor="let e of toppingList" value="{{ e }}">
                  {{ e }}
                </option>
              </select>
            </div>
            <div class="fieldwrap label-inline">
              <label>Field Moisture</label>
              <select id="FieldMoisture" name="FieldMoisture" #FieldMoisture="ngModel"
                [(ngModel)]="crop.field_moisture">
                <option *ngFor="let e of fieldMoistureList" value="{{ e }}">
                  {{ e }}
                </option>
              </select>
            </div>

            <div class="fieldwrap label-inline">
              <label>Estimated CCS</label>
              <app-number-input [dataPost]="dataPost.num" [(numberInput)]="crop.default_ccs" [min]="6" [default]="12">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Estimated Yield</label>
              <app-number-input [dataPost]="dataPost.tha" [(numberInput)]="crop.default_yield">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Standard Ground Speed</label>
              <app-number-input [dataPost]="dataPost.kmh" [(numberInput)]="crop.default_ground_speed" [min]="0">
              </app-number-input>
            </div>

            <div class="fieldwrap label-inline">
              <label>Standard Fan Speed</label>
              <app-number-input [dataPost]="dataPost.rpm" [(numberInput)]="crop.default_fan_speed">
              </app-number-input>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="fieldwrap fieldwrap-type-submit align-right">
        <a class="btn__secondary btn-back" (click)="back()">Back</a>
        <button class="btn-save" type="submit" (click)="save()">Save</button>
      </div>
    </div>
  </div>
</div>