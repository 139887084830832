import { Component, OnInit, AfterContentInit, isDevMode } from '@angular/core';
import { Console } from 'console';
import { ToastrService } from 'ngx-toastr';
import { GroupService } from '../../services/group.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService } from '../../services/dialog.service';
@Component({
  selector: 'app-contractor-groups-list',
  templateUrl: './contractor-groups-list.component.html',
  styleUrls: ['./contractor-groups-list.component.css'],
  providers: [AdminService, GroupService, HarvestService],
})
export class ContractorGroupsListComponent implements OnInit {
  gridData: any = [];
  brandsLists: any = [];
  isAdd = true;
  constructor(
    private adminApi: AdminService,
    private groupApi: GroupService,
    private harvesterApi: HarvestService,
    private dialog: DialogService,
    private toastr: ToastrService
  ) {}

  loadBrands(): any {
    this.harvesterApi.getAll('harvester-brand').subscribe((l) => {
      this.brandsLists = l.body;

    });
  }

  loadItems(): any {
    this.groupApi.getAll().subscribe((l) => {
      if (l.body) {
        this.gridData = l.body.filter((obj: any) => obj.status == 'ACTIVE');
      }
    });
  }
  yesNoDialog(ext_id: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to delete the group ?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        let entity = 'group';
        let parameters = `?entity=${entity}&ext_id=${ext_id}`;
        if (yes) {

          this.adminApi.delete(parameters).subscribe(
            (s) => {

              if (s.body.includes('successfully')) {
                this.toastr.success(s.body);
                this.ngOnInit();
              } else if (s.body.errorMessage) {
                this.toastr.error(
                  `Error deleting the group. Error: ${s.errorMessage}`
                );
              } else {
                this.toastr.error(`Error deleting the group. Error: ${s.body}`);
              }
            },
            (error) => this.toastr.error(error.error.errorMessage)
          );
        }
      });
  }

  ngOnInit(): void {
    this.loadItems();
  }
}
