import { Component, OnInit, Input, Output, EventEmitter, PipeTransform, AfterContentInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { bool } from 'aws-sdk/clients/signer';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css'],
})
export class NumberInputComponent implements OnInit {
  @Input() dataPre?: string;
  @Input() dataPost?: string;
  @Input() numberInput?: number;
  @Input() default?: number;
  @Input() min?: number;
  @Input() max?: number;
  @Input() format?: (_: number) => string

  @Output() numberInputChange = new EventEmitter();

  ngOnInit(): void {


    this.numberInput ??= (this.default ?? 0);

    setTimeout(() => {
      this.numberInputChange.emit(this.numberInput);
    });
  }

  // Keep number between Max And Min if provided
  clamp(input: number): number {
    if (this.min && input < this.min) {
      return this.min;
    }
    if (this.max && input > this.max) {
      return this.max;
    }

    return input
  }

  countChange(value: any): void {
    this.numberInput = Number(this.numberInput)
    this.numberInput = this.clamp(value);

    if (this.format) {
      this.numberInput = Number(this.format(this.numberInput!));
    }

    this.numberInputChange.emit(Number(this.numberInput));
  }
}
