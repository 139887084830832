import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class FarmService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Bearer ' + localStorage.getItem(environment.keys.accessToken) || '',
    }),
  };

  constructor(private http: HttpClient) {}

  create(body: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + 'farms', body, this.httpOptions)
  }

  downloadDataFormat(format: string) {

    let link = document.createElement('a');
    if (format == 'Wilmar_Standard') {
      link.download = 'wilmar_standard_format';
      link.href = 'assets/mill_data_templates/wilmar_standard_format.csv';

      link.click();
      link.remove();
    }
  }

  update(body: any, id: string): Observable<any> {
    return this.http
      .put<any>(`${environment.apiUrl}farms/${id}`, body, this.httpOptions)
  }

  get(id: string): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + 'farms/' + id, this.httpOptions)
      .pipe(retry(1));
  }

  delete(id: string): Observable<any> {
    return this.http
      .delete<any>(environment.apiUrl + 'farms/' + id, this.httpOptions)
      .pipe(retry(1));
  }

  getAll(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + 'farms', this.httpOptions)
      .pipe(retry(1));
  }
  getAllCrops(block_number: string, farmId: string): Observable<any> {
    return this.http
      .get<any>(
        environment.apiUrl + '/farm/' + farmId + '/block/' + block_number,
        this.httpOptions
      )
      .pipe(retry(1));
  }
}
