<div id="login">
  <div class="login-inner text-white scrollbar">
    <div class="content-padding">
      <div class="login-header">
        <div class="login-header-logo">
          <a href="https://www.qld.gov.au/"
            ><img src="images/queensland_government_black.png" width="200"
          /></a>
          <img src="images/logo-sra_white.svg" width="200" />
        </div>
        <!-- <h1 class="login-title"><span><strong>GGG <br/>Mate</strong></span></h1> -->
        <img src="images/harvest_mate_logo_white.png" />
      </div>

      <div class="login-box">
        <div id="form-signup" class="login-form form-open">
          <h2 class="h4">Reset Password</h2>
          <form>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Verification Code</label>
              <input
                type="email"
                id="email"
                name="email"
                #email="ngModel"
                [(ngModel)]="code"
              />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">New Password</label>
              <input
                type="password"
                id="email"
                name="email"
                #email="ngModel"
                [(ngModel)]="new_password"
              />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Confirm Password</label>
              <input
                type="password"
                id="email"
                name="email"
                #email="ngModel"
                [(ngModel)]="confirm_password"
              />
            </div>
            <div class="fieldwrap fieldwrap_type_submit">
              <button type="submit" (click)="resetPassword()">
                Reset Password
              </button>
              <a class="btn__secondary btn-login" routerLink="/signIn">Back</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
