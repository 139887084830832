<div class="content-1920">
  <div class="content-padding margin-top-small">
    <div class="main">
      <h2>Farm Details</h2>
      <div class="columns-wrap">
        <div class="columns3">
          <div class="fieldwrap">
            <label>Farm Name</label>
            <input type="text" id="name" name="name" #name="ngModel" [(ngModel)]="farm.name" />
          </div>
          <div class="fieldwrap">
            <label>Mill</label>
            <select id="millName" name="millName" #millName="ngModel" [(ngModel)]="farm.mill_id"
              (change)="onSelectMill(millName.value)">
              <option *ngFor="let e of millLists" value="{{ e.id }}">
                {{ e.name }}
              </option>
            </select>
          </div>
          <div *ngIf="selectedMill.region_details" class="fieldwrap">
            <label>Region</label>
            <input disabled type="selectedRegion" id="selectedRegion" #name="ngModel"
              [(ngModel)]="selectedMill.region_details.name" />
          </div>
        </div>
      </div>

      <button *ngIf="id" class="btn-add" type="submit" (click)="show_data()">
        Import Data
      </button>

      <div class="fieldwrap fieldwrap-type-submit align-right">
        <a class="btn__secondary btn-back" routerLink="/farms">Back</a>
        <button class="btn-save" type="submit" (click)="save()">Save</button>
      </div>

      <h2 *ngIf="isEdit">Blocks</h2>

      <button *ngIf="isEdit" class="btn-add" type="submit" routerLink="/block-details" routerLinkActive="active"
        [queryParams]="{ farm_id: farm.ext_id }">
        Add Block
      </button>

      <h4>Showing crop data for <strong>{{currentYear}}</strong></h4>

      <div class="columns-wrap">
        <div class="columns12">

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Block Number</th>
                  <th scope="col">Row Spacing</th>
                  <th scope="col">Avg. Row Length</th>
                  <th scope="col">Avg. Infield Haulout Distance</th>
                  <th scope="col">Crop Variety</th>
                  <th scope="col">Crop Class</th>
                  <th scope="col">Crop Presentation</th>
                  <th scope="col" colspan="3"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of blockGridData">
                  <td>{{ row.block_number }}</td>
                  <td>{{ row.row_spacing }}</td>
                  <td>{{ row.average_row_length }}</td>
                  <td>{{ row.average_infield_haulout_distance }}</td>
                  <td>
                    <div *ngIf="row.current_crop && row.current_crop.variety">{{row.current_crop.variety.name}}<sup
                        *ngIf="row.current_crop.variety.has_pbr" class="pbr">(PBR)</sup> <span
                        *ngIf="row.current_crop.variety.status !== 'ACTIVE'">(discontinued)</span></div>
                  </td>
                  <td>{{ row.current_crop ? row.current_crop.crop_class : ''}}</td>
                  <td>{{ row.current_crop ? row.current_crop.presentation : ''}}</td>
                  <td>
                    <a class="btn-small" routerLink="/block-details" routerLinkActive="active" [queryParams]="{
                      id: row.id,
                      block_number: row.block_number,
                      farm_id: farm.ext_id
                    }">Details</a>
                  </td>
                  <td>
                    <a class="btn-small" routerLink="/block-details" routerLinkActive="active" [queryParams]="{
                      id: row.id,
                      block_number: row.block_number,
                      farm_id: farm.ext_id,
                      iscopy: 'true'
                    }">Copy</a>
                  </td>
                  <td>
                    <button class="btn-small btn-left-space" type="button" (click)="deleteBlock(row.block_number)">
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


        </div>
      </div>
      <div id="show_results" [ngClass]="{ 'overlay open': show_results_dialog_open }">
        <div class="modal-box">
          <button class="close" type="button" (click)="show_results_dialog_open = false"></button>
          <div class="modal-header">
            <h2>Mill Data</h2>
          </div>
          <form>
            <fieldset>
              <div class="fieldwrap label-inline">
                <label>Season</label>
                <select id="season" name="season" #season="ngModel" [(ngModel)]="selectedSeason"
                  (change)="changeModel(selectedSeason)">
                  <option *ngFor="let e of seasons">{{ e }}</option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Data Format</label>
                <label style="cursor: pointer; color: #d86018" *ngIf="formatHasDownload"
                  (click)="downloadFormat()">Download Format Template?</label>
                <select id="data_format" name="data_format" #data_format="ngModel" [(ngModel)]="dataFormat"
                  (change)="onSelectFormat(dataFormat)">
                  <option *ngFor="let e of format_type" value="{{ e.value }}">
                    {{ e.text }}
                  </option>
                </select>
              </div>
              <div class="columns1">
                <div class="fieldwrap">
                  <input class="form-control" type="file" (change)="onChange($event)" />
                </div>
              </div>

              <h3>Default Values</h3>
              <div class="fieldwrap label-inline">
                <label>Moisture</label>
                <select id="moisture" name="moisture" #moisture="ngModel" [(ngModel)]="blockdata.moisture">
                  <option *ngFor="let e of moistureList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Presentation</label>
                <select id="presentation" name="presentation" #moisture="ngModel" [(ngModel)]="blockdata.presentation">
                  <option *ngFor="let e of presentationList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
              </div>

              <div class="fieldwrap label-inline">
                <label>Topping</label>
                <select id="topping" name="topping" #moisture="ngModel" [(ngModel)]="blockdata.topping">
                  <option *ngFor="let e of toppingList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Estimated CCS</label>
                <app-number-input [dataPost]="dataPost.unit" [(numberInput)]="blockdata.ccs" [default]="12"
                  [format]="ccsFormat" [min]="0">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Ground Speed</label>
                <app-number-input [dataPost]="dataPost.kmh" [(numberInput)]="blockdata.default_ground_speed">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Fan Speed</label>
                <app-number-input [dataPost]="dataPost.rpm" [(numberInput)]="blockdata.default_fan_speed">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Row spacing</label>
                <app-number-input [dataPost]="dataPost.m" [(numberInput)]="blockdata.row_spacing">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Number of Haulouts Used</label>
                <app-number-input [dataPost]="dataPost.unit" [(numberInput)]="blockdata.number_haulouts_utilised">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Average row length</label>
                <app-number-input [dataPost]="dataPost.m" [(numberInput)]="blockdata.average_row_length">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Average infield haulout distance (one-way)</label>
                <app-number-input [dataPost]="dataPost.km" [numberInput]="blockdata.average_infield_haulout_distance"
                  (numberInputChange)="
                    countChangedHandler(
                      $event,
                      'average_infield_haulout_distance'
                    )
                  ">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Average harvester turnaround time</label>
                <app-number-input [dataPost]="dataPost.sec"
                  [(numberInput)]="blockdata.average_harvester_turnaround_time">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Average bin mass</label>
                <app-number-input [dataPost]="dataPost.t" [numberInput]="blockdata.average_bin_mass"
                  (numberInputChange)="
                    countChangedHandler($event, 'average_bin_mass')
                  ">
                </app-number-input>
              </div>
              <div class="columns4">
                <div class="fieldwrap fieldwrap-type-submit">
                  <button class="btn-save" type="submit" (click)="show_results_dialog_open = false">
                    Close
                  </button>
                  <button (click)="onUpload()" class="btn__secondary" type="button">
                    Upload
                  </button>
                </div>
              </div>
              <div class="container" *ngIf="loading">
                <h3>Loading ...</h3>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>