import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, retry } from 'rxjs';
import { AdminCognitoService } from '../services/admin-cognito.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  isAuthenticated: boolean;
  constructor(
    private router: Router,
    private cognitoService: AdminCognitoService
  ) {
    this.isAuthenticated = false;
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let isAuth = await this.cognitoService.isAuthenticated();

    if (!isAuth) {
      this.router.parseUrl('/signIn');
      return false;
    }

    if (!await this.cognitoService.isAdmin()) {
      this.router.navigateByUrl("/home");
      return false;
    }

    return true;
  }
}
