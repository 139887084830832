// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: "staging",
  production: false,
  apiUrl: 'https://api.stage.harvestmate.au/v1/',
  cognito: {
    userPoolId: 'ap-southeast-2_ZiI1Ng3LA',
    userPoolWebClientId: '4vvfjm9ubdubb5ht7uaj5glve7',
  },
  keys: {
    currentUser: 'sra_cognito_user',
    accessKey: 'sra_user_credentials_access_key',
    secretKey: 'sra_user_credentials_secret_key',
    sessionToken: 'sra_user_credentials_session_token',
    userType: 'sra_user_type',
    accessToken: 'sra_user_credentials_access_token',
    date: 'current date',
  },
  aws: {
    region: 'ap-southeast-2',
    identityPoolId: 'd53525a2-88c4-4674-bc23-fa14a9098f5e',
  },
};
