<div class="content-1920">
  <div class="content-padding margin-top-small">
    <div class="main">
      <h2>Block Details</h2>
      <fieldset>
        <div class="columns-wrap">
          <div class="columns2">
            <div class="fieldwrap label-inline">
              <label>Block No.</label>
              <input type="text" id="blockName" name="blockName" #block_number="ngModel"
                [(ngModel)]="block.block_number" />
            </div>
            <div class="fieldwrap label-inline">
              <label>Area</label>
              <app-number-input [dataPost]="dataPost.ha" [(numberInput)]="block.area">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>No. of Haulouts Utilised</label>
              <app-number-input [dataPost]="dataPost.num" [(numberInput)]="block.number_haulouts_utilised">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Row Spacing</label>
              <app-number-input [dataPost]="dataPost.m" [(numberInput)]="block.row_spacing">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Average Row Length</label>
              <app-number-input [dataPost]="dataPost.m" [(numberInput)]="block.average_row_length">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Average Infield Haulout Distance (one-way)</label>
              <app-number-input [dataPost]="dataPost.km" [(numberInput)]="block.average_infield_haulout_distance">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Average Siding Bin Mass</label>
              <app-number-input [dataPost]="dataPost.t" [(numberInput)]="block.average_bin_mass">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Average Harvester turnaround time</label>
              <app-number-input [dataPost]="dataPost.sec" [(numberInput)]="block.average_harvester_turnaround_time">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Allowance Received (for block / representative area)</label>
              <app-number-input [dataPost]="dataPost.dollar_t" [(numberInput)]="block.allowance_received">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Proportion of Total Allowance paid to Drivers<span class="small">Please enter the Proportion of
                  Allowances paid to Drivers if
                  required</span></label>
              <app-number-input [dataPost]="dataPost.dollar_t"
                [(numberInput)]="block.proportion_total_allowances_paid_drivers">
              </app-number-input>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="fieldwrap fieldwrap-type-submit align-right">
        <a class="btn__secondary btn-back" routerLink="/farm" routerLinkActive="active"
          [queryParams]="{ id: farm_id }">Back</a>
        <!-- <a class="btn__secondary btn-calculator" href="#calculator" (click)="showAllowance()">Allowance
                        Calculator</a> -->
        <button class="btn-save" type="submit" (click)="save()">Save</button>
      </div>
      <div *ngIf="block.id">
        <h2>My Crops</h2>
        <button class="btn-add" type="submit" routerLink="/crop-details" routerLinkActive="active"
          [queryParams]="{ block_number: block.block_number, farm_id: farm_id }">
          Add Crops
        </button>
        <div class="columns-wrap">
          <div class="columns12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Season</th>
                  <th scope="col">Block Number</th>
                  <th scope="col">Variety</th>
                  <th scope="col">Crop Class</th>
                  <th scope="col">Presentation</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of cropData">
                  <td>{{ row.season }}</td>
                  <td>{{ row.block_details.block_number }}</td>
                  <td>
                    <div *ngIf="row.variety_details">{{row.variety_details.name}}<sup
                        *ngIf="row.variety_details.has_pbr" class="pbr">(PBR)</sup> <span
                        *ngIf="row.variety_details.status !== 'ACTIVE'">(discontinued)</span></div>
                  </td>
                  <td>{{ row.crop_class }}</td>
                  <td>{{ row.presentation }}</td>
                  <td>
                    <!-- row.block_id -->
                    <a class="btn-small" routerLink="/crop-details" routerLinkActive="active" [queryParams]="{
                        block_id: row.block_id,
                        block_number: row.block_details.block_number,
                        season: row.season,
                        farm_id: farm_id
                      }">Details</a>
                  </td>
                  <td>
                    <a class="btn-small" routerLink="/crop-details" routerLinkActive="active" [queryParams]="{
                        block_id: row.block_id,
                        block_number: row.block_details.block_number,
                        season: row.season,
                        farm_id: farm_id,
                        iscopy: 'true'
                      }">Copy</a>
                  </td>
                  <td>
                    <button class="btn-small btn-left-space" type="button" (click)="
                        deleteCrops(row.block_details.block_number, row.season)
                      ">
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="calculator" [ngClass]="{ 'overlay open': allowance_open }">
      <div class="modal-box">
        <button class="close" type="button"></button>
        <div class="modal-header">
          <h2>Allowance <strong>Calculator</strong></h2>
          <p class="h5">(Harvester / Haulage / Night / Sunday)</p>
        </div>
        <form>
          <fieldset>
            <div class="fieldwrap label-inline">
              <label>Total hectares considered for representive block/area</label>
              <app-number-input [dataPost]="dataPost.ha" [(numberInput)]="block.delivered_yield_estimate">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Total tonnage (based on block standard yield)</label>
              <app-number-input [dataPost]="dataPost.t" [(numberInput)]="block.delivered_yield_estimate">
              </app-number-input>
            </div>

            <div class="field-group">
              <div class="fieldwrap label-inline">
                <label>Allowance A</label>
                <app-number-input [dataPost]="dataPost.dollar_t" [(numberInput)]="block.delivered_yield_estimate">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Allowance A - Tonnes on which it is paid</label>
                <app-number-input [dataPost]="dataPost.dollar_t" [(numberInput)]="block.delivered_yield_estimate">
                </app-number-input>
              </div>
            </div>

            <button class="btn__tertiary btn-add" type="button">Add</button>
            <div class="total-wrap h6">
              <span>Average allowance per tonne for representative block/area</span>
              <div class="total">
                <span>0.00</span>
              </div>
            </div>

            <div class="fieldwrap fieldwrap-type-submit">
              <button class="btn__secondary close-modal" type="button">
                Close
              </button>
              <button class="btn-save" type="submit">Save</button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>