<div class="content-1920">
  <div class="content-padding margin-top-small" [ngSwitch]="tabHeader">
    <a class="btn__secondary" (click)="changeTabs('mills-season')">Mill Season</a>
    <a class="btn__secondary" (click)="changeTabs('mills')">Mills</a>
    <a class="btn__secondary" (click)="changeTabs('regions')">Regions</a>
    <h2>{{ tabHeader }}</h2>
    <div class="columns-wrap" *ngSwitchCase="'Mills Seasons'">
      <div class="columns-wrap">
        <div class="columns12">
          <fieldset>
            <form>
              <div class="columns2">
                <div class="fieldwrap label-inline align-left">
                  <label>Selected Season(s)</label>
                  <select id="Type" name="Type" #type="ngModel" [(ngModel)]="selectedSeason" (change)="currentSeason()">
                    <option *ngFor="let e of seasonFilter" value="{{ e }}">
                      {{ e }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Season</th>
            <th scope="col">Mill Name</th>
            <th scope="col">Start Date</th>
            <th scope="col">Start End</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of millSeasonsItemsList">
            <td>{{ row.season }}</td>
            <td>{{ row.mill_details.name }}</td>
            <td>{{ row.season_start_date | date : "dd-MMM-yyyy" }}</td>
            <td>{{ row.season_end_date | date : "dd-MMM-yyyy" }}</td>
            <td>
              <button class="btn-small btn-left-space" type="button"
                (click)="update('mills-season', row.mill_id, row.season)">
                Edit
              </button>
              <!-- <button class="btn-small btn-left-space" type="button"
                                (click)="deleteDialog(row.id, 'mills-season')">Delete</button> -->
            </td>

            <td>
              <a class="btn-small" routerLink="/mill-season-detail" routerLinkActive="active" [queryParams]="{
                  id: row.mill_id,
                  season: row.season,
                  iscopy: 'true'
                }">Copy</a>
            </td>
          </tr>
        </tbody>
      </table>
      <fieldset>
        <div class="columns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Season</label>
                <input type="number" id="season" name="season" #season="ngModel"
                  [(ngModel)]="currentMillSeason.season" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Mill</label>
                <select id="millSeasonId" name="millSeasonId" #millSeasonId="ngModel"
                  [(ngModel)]="currentMillSeason.mill_id">
                  <option *ngFor="let e of millLists" value="{{ e.id }}">
                    {{ e.name }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Season Length (start)</label>
                <input type="date" id="ms_season_start_date" name="ms_season_start_date" #ms_season_start_date="ngModel"
                  [(ngModel)]="currentMillSeason.season_start_date" (change)="checkStartDate()" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Season Length (end)</label>
                <input type="date" id="ms_season_end_date" name="ms_season_end_date" #ms_season_end_date="ngModel"
                  [(ngModel)]="currentMillSeason.season_end_date" (change)="checkStartDate()" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Lost Days (wet days/mill breakdowns) </label>
                <input type="number" id="millSeasonLostdays" name="millSeasonLostdays" #millSeasonLostdays="ngModel"
                  [(ngModel)]="currentMillSeason.lost_days" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Payroll Tax Threshold (per annum) </label>
                <input type="number" id="ms_ayroll_tax_threshold_per_annum" name="ms_ayroll_tax_threshold_per_annum"
                  #ms_ayroll_tax_threshold_per_annum="ngModel" [(ngModel)]="
                    currentMillSeason.payroll_tax_threshold_per_annum
                  " />
              </div>
              <div class="fieldwrap label-inline">
                <label>Deduction above threshold ($1 in every $4)</label>
                <input type="number" id="ms_deduction_above_threshold" name="ms_deduction_above_threshold"
                  #ms_deduction_above_threshold="ngModel" [(ngModel)]="currentMillSeason.deduction_above_threshold" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Payroll Tax</label>
                <app-number-input [dataPost]="dataPost.percent" [(numberInput)]="currentMillSeason.payroll_tax">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Workers Compensation Threshold ($)</label>
                <input type="number" id="ms_workers_compensation_threshold" name="ms_workers_compensation_threshold"
                  #ms_workers_compensation_threshold="ngModel"
                  [(ngModel)]="currentMillSeason.workers_compensation_threshold" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Workers Compensation Premium (at a '3' rating)</label>
                <app-number-input [dataPost]="dataPost.percent"
                  [(numberInput)]="currentMillSeason.workers_compensation_premium" [min]="0">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Workers Compensation Premium (Experience Based Rating)</label>
                <app-number-input [dataPost]="dataPost.percent"
                  [(numberInput)]="currentMillSeason.workers_compensation_premium_experience" [min]="0">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Dust Disease Contribution</label>
                <app-number-input [dataPost]="dataPost.percent"
                  [(numberInput)]="currentMillSeason.dust_disease_contribution" [min]="0">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Fuel Price ($/l)</label>
                <app-number-input [dataPre]="dataPre.dollar" [(numberInput)]="currentMillSeason.fuel_price">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Levies ($/t)</label>
                <app-number-input [dataPre]="dataPre.dollar" [(numberInput)]="currentMillSeason.levies">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Mill Constant ($/t)</label>
                <app-number-input [dataPre]="dataPre.dollar" [(numberInput)]="currentMillSeason.mill_constant">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Season Sugar Price (Pool Price $/tonne)</label>
                <app-number-input [dataPre]="dataPre.dollar" [(numberInput)]="currentMillSeason.sugar_price">
                </app-number-input>
              </div>
            </div>
            <div class="fieldwrap label-inline">
              <button class="btn-save" type="submit" (click)="
                  save(
                    'mills-season',
                    currentMillSeason.mill_id,
                    currentMillSeason.season
                  )
                ">
                {{ millSeasonText }}
              </button>
              <button class="btn-save" type="submit" (click)="cancel('mills-season')">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </fieldset>
    </div>

    <div class="columns-wrap" *ngSwitchCase="'Mills'">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Mill Id</th>
            <th scope="col">Mill Name</th>
            <th scope="col">Region</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of millLists">
            <td>{{ row.id }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.region_details.name }}</td>
            <td>
              <button class="btn-small btn-left-space" type="button" (click)="update('mills', row.id)">
                Edit
              </button>
              <!-- <button class="btn-small btn-left-space" type="button"
                                (click)="deleteDialog(row.id, 'mills')">Delete</button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <fieldset>
        <div class="columns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Mill Name: </label>
                <input type="text" id="millName" name="millName" #millName="ngModel" [(ngModel)]="currentMill.name" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Region</label>
                <select id="millRegion" name="millRegion" #millRegion="ngModel" [(ngModel)]="currentMill.region_id">
                  <option *ngFor="let e of regionLists" value="{{ e.id }}">
                    {{ e.name }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline"></div>
              <div class="fieldwrap label-inline">
                <button class="btn-save" type="submit" (click)="save('mills', currentMill.id)">
                  {{ millText }}
                </button>
                <button class="btn-save" type="submit" (click)="cancel('mills')">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
    <div class="columns-wrap" *ngSwitchCase="'Regions'">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Region Id</th>
            <th scope="col">Region Name</th>
            <th scope="col">Broad Region</th>
            <th scope="col">Bulk Density</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of regionLists">
            <td>{{ row.id }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.broad_region }}</td>
            <td>{{ row.bulk_density }}</td>
            <td>
              <button class="btn-small btn-left-space" type="button" (click)="update('regions', row.id)">
                Edit
              </button>
              <!-- <button class="btn-small btn-left-space" type="button"
                                (click)="deleteDialog(row.id, 'regions')">Delete</button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <fieldset>
        <div class="columns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Region Name: </label>
                <input type="text" id="regionName" name="regionName" #regionName="ngModel"
                  [(ngModel)]="currentRegion.name" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Broad Region</label>
                <select id="broadRegion" name="broadRegion" #broadRegion="ngModel"
                  [(ngModel)]="currentRegion.broad_region">
                  <option *ngFor="let e of broadRegionList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <div class="toggle">
                  <input id="bulk_density" type="checkbox" [(ngModel)]="currentRegion.bulk_density"
                    [ngModelOptions]="{ standalone: true }" />
                  <label for="varietyPBR">Bulk Density</label>
                </div>
              </div>
              <div class="fieldwrap label-inline"></div>
              <div class="fieldwrap label-inline">
                <button class="btn-save" type="submit" (click)="save('regions', currentRegion.id)">
                  {{ regionText }}
                </button>
                <button class="btn-save" type="submit" (click)="cancel('regions')">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
  </div>
</div>