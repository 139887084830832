import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Amplify, { Auth } from 'aws-amplify';

import { environment } from '../../environments/environment';

import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
  GetIdCommand,
  GetIdCommandInput,
  GetCredentialsForIdentityCommandInput,
} from '@aws-sdk/client-cognito-identity';

import {
  CognitoIdentityProviderClient,
  AdminDisableUserCommandInput,
  AdminEnableUserCommand,
  AdminEnableUserCommandInput,
  AdminDisableUserCommand,
  ForgotPasswordCommand,
  ForgotPasswordCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export interface IUser {
  email: string;
  givenName: string;
  lastName: string;
  zoneInfo: string;
  phoneNumber: string;
  userType: string;
  address: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private authenticationSubject: BehaviorSubject<any>;

  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
      Cache: false,
    });

    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public signUp(user: IUser): Promise<any> {
    var phoneNumber = user.phoneNumber;

    if (!phoneNumber.startsWith('+61') && !phoneNumber.startsWith('61')) {
      phoneNumber = '+61' + phoneNumber;
    }
    return Auth.signUp({
      username: user.email,
      password: user.password,
      attributes: {
        given_name: user.givenName,
        family_name: user.lastName,
        zoneinfo: 'AU',
        address: user.address,
        phone_number: phoneNumber,
        'custom:user_type': user.userType,
        'custom:accepted_disclaimer': 'false',
      },
    });
  }

  public resendSignUp(user: IUser): Promise<any> {
    return Auth.resendSignUp(user.email);
  }

  public confirmSignUp(user: IUser, otp: any): Promise<any> {
    return Auth.confirmSignUp(user.email, otp);
  }

  //send the reset psw code to a specific user
  public async forgotPassword(user: IUser): Promise<any> {
    return await Auth.forgotPassword(user.email);
  }

  //reset psw for that user using the code
  public forgotPasswordSubmit(
    user: IUser,
    otp: any,
    newpassword: any
  ): Promise<any> {



    return Auth.forgotPasswordSubmit(user.email, otp, newpassword);
  }

  public async signIn(user: IUser): Promise<any> {
    return await Auth.signIn(user.email, user.password).then(async (u) => {
      const now = new Date();
      localStorage.setItem(environment.keys.currentUser, u);
      localStorage.setItem(
        environment.keys.userType,
        u.attributes['custom:type']
      );
      localStorage.setItem(environment.keys.date, now.toLocaleDateString()); //add current date to localstorage
      localStorage.setItem(
        environment.keys.accessToken,
        u.signInUserSession.accessToken.jwtToken
      );
      var jwtToken = u.signInUserSession.idToken.jwtToken;
      var client = new CognitoIdentityClient({
        region: environment.aws.region,
      });

      //get identity id value
      const paramsId = {} as GetIdCommandInput;
      paramsId.IdentityPoolId =
        environment.aws.region + ':' + environment.aws.identityPoolId;
      paramsId.Logins = {
        [`cognito-idp.${environment.aws.region}.amazonaws.com/${u.pool.userPoolId}`]:
          jwtToken,
      };
      const commandPoolId = new GetIdCommand(paramsId);

      await client.send(commandPoolId).then(
        async (dataPoolId) => {
          //get access credentials for current user
          const params = {} as GetCredentialsForIdentityCommandInput;
          params.Logins = {
            [`cognito-idp.${environment.aws.region}.amazonaws.com/${u.pool.userPoolId}`]:
              jwtToken,
          };
          params.IdentityId = dataPoolId.IdentityId;
          const command = new GetCredentialsForIdentityCommand(params);

          await client.send(command).then(
            (data) => {
              localStorage.setItem(
                environment.keys.accessKey,
                data.Credentials?.AccessKeyId || ''
              );
              localStorage.setItem(
                environment.keys.secretKey,
                data.Credentials?.SecretKey || ''
              );
              localStorage.setItem(
                environment.keys.sessionToken,
                data.Credentials?.SessionToken || ''
              );

              this.authenticationSubject.next(true);
            },
            (error) => {
              throw error;
            }
          );
        },
        (error) => {
          throw error;
        }
      );
    });
  }

  public disableUser(username: string): Promise<any> {
    var client = new CognitoIdentityProviderClient({
      region: environment.aws.region,
      credentials: {
        accessKeyId: localStorage.getItem(environment.keys.accessKey) || '',
        secretAccessKey: localStorage.getItem(environment.keys.secretKey) || '',
        sessionToken: localStorage.getItem(environment.keys.sessionToken) || '',
      },
    });
    const params = {} as AdminDisableUserCommandInput;
    params.UserPoolId = environment.cognito.userPoolId;
    params.Username = username;

    const command = new AdminDisableUserCommand(params);

    return client.send(command);
  }

  public enableUser(username: string): Promise<any> {
    var client = new CognitoIdentityProviderClient({
      region: environment.aws.region,
      credentials: {
        accessKeyId: localStorage.getItem(environment.keys.accessKey) || '',
        secretAccessKey: localStorage.getItem(environment.keys.secretKey) || '',
        sessionToken: localStorage.getItem(environment.keys.sessionToken) || '',
      },
    });
    const params = {} as AdminEnableUserCommandInput;
    params.UserPoolId = environment.cognito.userPoolId;
    params.Username = username;

    const command = new AdminEnableUserCommand(params);

    return client.send(command);
  }

  public signOut(): Promise<any> {
    return Auth.signOut()
      .then(() => {
        this.authenticationSubject.next(false);
      })
      .then(() => {
        this.clearUserData();
      });
  }

  public isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
        .then((user: any) => {

          if (user) {
            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    }
  }

  public async getUser(): Promise<any> {
    let user = await Auth.currentUserInfo();
    return user;
  }

  public updateUser(user: any): Promise<any> {
    return Auth.currentUserPoolUser().then((cognitoUser: any) => {
      delete user.attributes['custom:user_type']; //immutable attribute
      return Auth.updateUserAttributes(cognitoUser, user.attributes);
    });
  }

  clearUserData(): void {

    localStorage.removeItem(environment.keys.accessKey);
    localStorage.removeItem(environment.keys.secretKey);
    localStorage.removeItem(environment.keys.sessionToken);
    localStorage.removeItem(environment.keys.sessionToken);
    localStorage.removeItem(environment.keys.currentUser);
    localStorage.removeItem(environment.keys.userType);
  }
}

// //check if access token is more than 24h old, if it is
// const now = new Date();
// if (localStorage.getItem(environment.keys.date) != now.toLocaleDateString()) {
//   localStorage.removeItem(environment.keys.date);
// }
