<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>mill season details</h2>




    <fieldset>
      <div class="columns12">
        <form>
          <div class="columns2">
            <div class="fieldwrap label-inline">
              <label>Season</label>
              <input type="number" id="season" name="season" #season="ngModel" [(ngModel)]="currentMillSeason.season" />
            </div>
            <div class="fieldwrap label-inline">
              <label>Mill</label>
              <select id="millSeasonId" name="millSeasonId" #millSeasonId="ngModel"
                [(ngModel)]="currentMillSeason.mill_id">
                <option *ngFor="let e of millLists" value="{{ e.id }}">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <div class="fieldwrap label-inline">
              <label>Season Length (start)</label>
              <input type="date" id="ms_season_start_date" name="ms_season_start_date" #ms_season_start_date="ngModel"
                [(ngModel)]="currentMillSeason.season_start_date" (change)="checkStartDate()" />
            </div>
            <div class="fieldwrap label-inline">
              <label>Season Length (end)</label>
              <input type="date" id="ms_season_end_date" name="ms_season_end_date" #ms_season_end_date="ngModel"
                [(ngModel)]="currentMillSeason.season_end_date" (change)="checkStartDate()" />
            </div>
            <div class="fieldwrap label-inline">
              <label>Lost Days (wet days/mill breakdowns) </label>
              <input type="number" id="millSeasonLostdays" name="millSeasonLostdays" #millSeasonLostdays="ngModel"
                [(ngModel)]="currentMillSeason.lost_days" />
            </div>
            <div class="fieldwrap label-inline">
              <label>Payroll Tax Threshold (per annum) </label>
              <input type="number" id="ms_ayroll_tax_threshold_per_annum" name="ms_ayroll_tax_threshold_per_annum"
                #ms_ayroll_tax_threshold_per_annum="ngModel" [(ngModel)]="
                        currentMillSeason.payroll_tax_threshold_per_annum
                      " />
            </div>
            <div class="fieldwrap label-inline">
              <label>Deduction above threshold ($1 in every $4)</label>
              <input type="number" id="ms_deduction_above_threshold" name="ms_deduction_above_threshold"
                #ms_deduction_above_threshold="ngModel" [(ngModel)]="currentMillSeason.deduction_above_threshold" />
            </div>
            <div class="fieldwrap label-inline">
              <label>Payroll Tax</label>
              <app-number-input [dataPost]="dataPost.percent" [(numberInput)]="currentMillSeason.payroll_tax">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Workers Compensation Threshold ($)</label>
              <input type="number" id="ms_workers_compensation_threshold" name="ms_workers_compensation_threshold"
                #ms_workers_compensation_threshold="ngModel"
                [(ngModel)]="currentMillSeason.workers_compensation_threshold" />
            </div>
            <div class="fieldwrap label-inline">
              <label>Workers Compensation Premium (at a '3' rating)</label>
              <app-number-input [dataPost]="dataPost.percent"
                [(numberInput)]="currentMillSeason.workers_compensation_premium" [min]="0">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Workers Compensation Premium (Experience Based Rating)</label>
              <app-number-input [dataPost]="dataPost.percent"
                [(numberInput)]="currentMillSeason.workers_compensation_premium_experience" [min]="0">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Dust Disease Contribution</label>
              <app-number-input [dataPost]="dataPost.percent"
                [(numberInput)]="currentMillSeason.dust_disease_contribution" [min]="0">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Fuel Price ($/l)</label>
              <app-number-input [dataPost]="dataPost.dollar" [(numberInput)]="currentMillSeason.fuel_price">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Levies ($/t)</label>
              <app-number-input [dataPost]="dataPost.dollar" [(numberInput)]="currentMillSeason.levies">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Mill Constant ($/t)</label>
              <app-number-input [dataPost]="dataPost.dollar" [(numberInput)]="currentMillSeason.mill_constant">
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Season Sugar Price (Pool Price $/tonne)</label>
              <app-number-input [dataPost]="dataPost.dollar" [(numberInput)]="currentMillSeason.sugar_price">
              </app-number-input>
            </div>
          </div>

        </form>
      </div>
    </fieldset>

    <div class="fieldwrap fieldwrap-type-submit align-right">
      <a class="btn__secondary btn-back" routerLink="/admin/regionManagement" routerLinkActive="active">
        Back
      </a>
      <button class="btn-save" type="submit" (click)="save()">
        Save
      </button>

    </div>
  </div>

</div>