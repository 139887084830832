import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { AdminService } from 'src/app/services/admin.service';
import { InputPostData } from 'src/app/models/InputData';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

@Component({
  selector: 'app-region-management',
  templateUrl: './region-management.component.html',
  styleUrls: ['./region-management.component.css'],
  providers: [AdminService, InputPostData],
})
export class RegionManagementComponent implements OnInit {
  regionLists: any = [];
  millLists: any = [];
  millSeasonLists: any[] = [];

  millSeasonText = 'Add Mill Season';
  regionText: String = 'Add Region';
  millText: String = 'Add Mill';

  currentRegion: any = {};
  currentMill: any = {};
  currentMillSeason: any = {};
  seasonFilter = ["All"];
  isNewSeason: Boolean = true;
  selectedSeason: string = "All";
  selectedYear: number = 2022;
  seasonList: any = [2021, 2022, 2023, 2024];
  dataPost: any = {};
  dataPre: any = {};
  broadRegionList = ['North', 'Central', 'South'];
  tabHeader: String = 'Mills Seasons';
  millSeasonsItemsList: any = [];
  constructor(
    private dialog: DialogService,
    private toastr: ToastrService,
    private adminApi: AdminService,
    private inputData: InputPostData
  ) { }

  // Turn 100% into 1, keep extra 2 precision so that 49.5% doesnt round to .5
  percentToNumber(input: number, precision: number = 2): number {
    return Number((input / 100).toFixed(precision + 2));
  }
  numberToPercent(input: number, precision: number = 2): number {
    return Number((input * 100).toFixed(precision + 2))
  }

  currentSeason() {
    this.millSeasonsItemsList = this.millSeasonLists
    //filter just for obj.season = selectedSeason 
    if (this.selectedSeason.toUpperCase() == 'ALL') {
      return
    } else {
      this.millSeasonsItemsList = this.millSeasonsItemsList.filter((obj: any) => obj.season == this.selectedSeason)
    }
  }

  deleteDialog(id: string, endpoint: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to delete?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        if (yes) {
          let data = {
            deleted_at: new Date(),
            status: 'DELETED',
          };
          this.updateData(
            endpoint + '/' + String(id),
            'Regions',
            data,
            'deleted'
          );
        }
      });
  }

  loadData(type: string, data: any): any {
    this.adminApi.getAll(type).subscribe((l) => {
      if (l.body) {
        switch (type) {
          case 'mills/season':
            this.millSeasonLists = l.body.filter(
              (obj: any) => obj.status == 'ACTIVE'
            );

            const seasons = new Set<number>();
            this.millSeasonLists.forEach(millSeason => {
              seasons.add(millSeason.season);
            })

            const seasonList: string[] = Array.from(seasons).sort().reverse().map(item => item.toString())
            this.seasonFilter = ["All", ...seasonList]
            this.millSeasonsItemsList = this.millSeasonLists;
            break;
          case 'mills':
            this.millLists = l.body.filter(
              (obj: any) => obj.status == 'ACTIVE'
            );

            break;
          case 'regions':
            this.regionLists = l.body.filter(
              (obj: any) => obj.status == 'ACTIVE'
            );

            break;
          default:
            break;
        }
      }
    });
  }

  update(type: string, id: number, season?: number): void {
    switch (type) {
      case 'mills-season':
        this.isNewSeason = false;
        this.millSeasonText = 'Update Mill Seasons';

        this.currentMillSeason = {
          ...this.millSeasonLists.find(
            (obj: any) =>
              obj.mill_id == Number(id) && obj.season == Number(season)
          )
        };
        this.currentMillSeason.dust_disease_contribution = this.numberToPercent(this.currentMillSeason.dust_disease_contribution)
        this.currentMillSeason.workers_compensation_premium = this.numberToPercent(this.currentMillSeason.workers_compensation_premium)
        this.currentMillSeason.workers_compensation_premium_experience = this.numberToPercent(this.currentMillSeason.workers_compensation_premium_experience)
        this.currentMillSeason.payroll_tax = this.numberToPercent(this.currentMillSeason.payroll_tax)

        this.currentMillSeason.season_start_date = this.currentMillSeason.season_start_date.slice(0, 10);
        this.currentMillSeason.season_end_date = this.currentMillSeason.season_end_date.slice(0, 10);



        break;
      case 'mills':
        this.millText = 'Update Mill';
        this.currentMill = this.millLists.find((obj: any) => obj.id == id);

        break;
      case 'regions':
        this.regionText = 'Update Region';
        this.currentRegion = this.regionLists.find((obj: any) => obj.id == id);
        break;
      default:
        break;
    }
  }

  cancel(type: string): void {
    switch (type) {
      case 'mills-season':
        this.isNewSeason = true;
        this.currentMillSeason = {};
        this.millSeasonText = 'Add Mill Season';
        break;
      case 'mills':
        this.currentMill = {};
        this.millText = 'Add Mill';
        break;
      case 'regions':
        this.currentRegion = {};
        this.regionText = 'Add Region';
        break;
      default:
        break;
    }
  }

  async create(endpoint: string, type: string, data: any): Promise<any> {

    this.adminApi.create(endpoint, data).subscribe((h) => {
      if (h.body.status == 'ACTIVE') {
        this.toastr.success(type + ' created successfully.');
        this.loadData('mills/season', this.millSeasonLists);
        this.loadData('mills', this.millLists);
        this.loadData('regions', this.regionLists);
      } else {
        this.toastr.error(h.body.error);
      }
    });
  }

  async updateData(
    endpoint: string,
    type: string,
    data: any,
    action: string
  ): Promise<any> {

    this.adminApi.update(endpoint, data).subscribe((h) => {

      if (h.body == 'Successfully Updated' || h.body == 1) {
        this.toastr.success(type + ' ' + action + ' successfully.');
        this.loadData('mills/season', this.millSeasonLists);
        this.loadData('mills', this.millLists);
        this.loadData('regions', this.regionLists);
      } else {

        this.toastr.error(h);
      }
    });
  }

  save(type: string, id?: number, season?: number) {




    switch (type) {
      case 'mills-season':
        let data = { ...this.currentMillSeason };
        data.dust_disease_contribution = this.percentToNumber(data.dust_disease_contribution, 1)
        data.workers_compensation_premium = this.percentToNumber(data.workers_compensation_premium, 1)
        data.workers_compensation_premium_experience = this.percentToNumber(data.workers_compensation_premium_experience, 1);
        data.payroll_tax = this.percentToNumber(data.payroll_tax, 1);

        const endpoint = `mills/${id}/season/${season}`;

        if (!this.isNewSeason) {


          this.updateData(
            endpoint,
            'Mill Season',
            JSON.stringify(data),
            'updated'
          );
        } else {
          if (
            this.millSeasonLists.find(
              (obj: any) => obj.mill_id == id && obj.season == season
            )
          ) {
            this.toastr.warning('The mill season already exist');
          } else {

            delete data['season'];
            delete data['mill_id'];
            this.create(
              endpoint,
              'Mill Season',
              JSON.stringify(data)
            );
          }
        }
        break;
      case 'mills':
        if (id) {
          let data = JSON.stringify(this.currentMill);
          this.updateData(`mills/${id}`, 'Mills', data, 'updated');
        } else {
          this.create('mills', 'Mill', JSON.stringify(this.currentMill));
        }
        break;
      case 'regions':
        if (id) {
          //delete this.currentRegion['region_name']


          let data = JSON.stringify(this.currentRegion);
          this.updateData(`regions/${id}`, 'Regions', data, 'updated');
        } else {
          this.create('regions', 'Regions', JSON.stringify(this.currentRegion));
        }
        break;
      default:
        break;
    }
  }

  checkStartDate() {
    let start = Date.parse(this.currentMillSeason.season_start_date);
    let end = Date.parse(this.currentMillSeason.season_end_date);
    if (start >= end) {

      this.toastr.warning('The end date should be greater that start date.');
      this.currentMillSeason.season_end_date =
        this.currentMillSeason.season_start_date;
    }
  }

  changeTabs(type: string) {
    switch (type) {
      case 'mills-season':
        this.tabHeader = 'Mills Seasons';
        break;
      case 'mills':
        this.tabHeader = 'Mills';
        break;
      case 'regions':
        this.tabHeader = 'Regions';
        break;
      default:
        break;
    }
  }
  ngOnInit(): void {
    this.dataPost = this.inputData.dataType;
    this.dataPre = this.inputData.dataType;
    this.loadData('mills/season', this.millSeasonLists);
    this.loadData('mills', this.millLists);
    this.loadData('regions', this.regionLists);
  }
}
