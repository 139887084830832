<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>Growers for group: {{ group_name }}</h2>
    <div class="columns-wrap">
      <div class="columns12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Grower Type</th>
              <th scope="col">Grower Name</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of growersData">
              <td>{{ row.type }}</td>
              <td>{{ row.name }}</td>
              <td></td>
              <td>
                <a
                  class="btn-small"
                  routerLink="/group-grower-block"
                  routerLinkActive="active"
                  [queryParams]="{
                    group_id: this.group_id,
                    cognito_id: row.cognito_id,
                    name: row.name
                  }"
                  >Details</a
                >
              </td>
              <!-- <td>
                                <a class="btn-small" (click)="show_results()">Show results</a>
                            </td> -->
            </tr>
          </tbody>
        </table>
        <div *ngIf="!is_processing_calculations && userIsContactor">
          <button class="btn-save" type="submit" (click)="show_results()">
            Show Results
          </button>
          <button class="btn-save" type="submit" (click)="resetAllCalculationsDialog()" *ngIf="counter > 0">
            Reset All Calculations (All blocks in group)
          </button>
        </div>
        <div *ngIf="is_processing_calculations">
          <label>Running calculations...</label>
          <!-- <button class="btn-save" type="submit" (click)="show_results()" disabled>Show Results</button> -->
        </div>
      </div>
      <div
        id="show_results"
        [ngClass]="{ 'overlay open': show_results_dialog_open }"
      >
        <div class="modal-box modal-large">
          <button
            class="close"
            type="button"
            (click)="show_results_dialog_open = false"
          ></button>
          <div class="modal-header">
            <h2>Average for harvesting group</h2>
          </div>
          <form>
            <fieldset *ngIf="isContract">
              <table style="width: 100%" style="cursor: pointer">
                <tr>
                  <td colspan="3">Yield Change (t/ha): {{ yield_change / counter | number: "1.1-1"}}</td>
                  <td colspan="3">Grower Net Benefit ($/ha): {{ net_benefit / counter | number : "1.0-0" }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td><strong>Standard</strong></td>
                  <td><strong>Predicted</strong></td>
                  <td></td>
                  <td><strong>Standard</strong></td>
                  <td><strong>Predicted</strong></td>
                </tr>
                <tr>
                  <td>Yield (t/ha)</td>
                  <td>{{std_yield /counter | number: "1.1-1"}}</td>
                  <td>{{pred_yield /counter | number: "1.1-1"}}</td>
                  <td>Labour Cost ($)</td>
                  <td>{{ std_labour_cost / counter | number : "1.0-0" }}</td>
                  <td>{{ pred_labour_cost / counter | number: "1.0-0"}}</td>
                </tr>
                <tr>
                  <td>Ground Speed (km/hr)</td>
                  <td>{{std_ground_speed /counter | number: "1.1-1" }}</td>
                  <td>{{pred_ground_speed /counter | number: "1.1-1" }}</td>
                  <td>Depreciation Cost($)</td>
                  <td>{{ std_depreciation_cost / counter | number : "1.0-0" }}</td>
                  <td>{{ pred_depreciation_cost / counter | number : "1.0-0" }}</td>
                </tr>
                <tr>
                  <td>Flow Rate (t/hr)</td>
                  <td>{{std_flow_rate /counter | number: "1.1-1"}}</td>
                  <td>{{pred_flow_rate /counter | number: "1.1-1"}}</td>
                  <td>Interest cost ($)</td>
                  <td>{{interest_cost_std / counter | number: "1.0-0" }}</td>
                  <td>{{interest_cost_pred  / counter | number: "1.0-0"}}</td>
                </tr>
                <tr>
                  <td>Fan Speed (RPM)</td>
                  <td>{{std_fan_speed /counter | number: "1.0-0"}}</td>
                  <td>{{pred_fan_speed /counter | number: "1.0-0"}}</td>
                  
                  <td>Repairs & Maintenance ($)</td>
                  <td>{{
                    std_repair_and_maintenance_cost / counter
                      | number : "1.0-0"
                  }}</td>
                  <td>{{pred_repair_and_maintenance_cost / counter | number: "1.0-0"}}</td>
                </tr>
                <tr>
                  <td>Hours per day (total)</td>
                  <td>{{std_hours_per_day_total / counter | number: "1.1-1"}}</td>
                  <td>{{pred_hours_per_day_total / counter | number: "1.1-1"}}</td>
                  <td>Fuel cost ($)</td>
                  <td>{{ std_fuel_cost / counter | number : "1.0-0" }}</td>
                  <td>{{ pred_fuel_cost / counter | number : "1.0-0" }}</td>
                </tr>
                <tr>
                  <td>Hours per day (cutting)</td>
                  <td>{{std_hours_per_day_cutting / counter | number: "1.1-1"}}</td>
                  <td>{{pred_hours_per_day_cutting / counter | number: "1.1-1"}}</td>
                  <td>Fixed Cost ($)</td>
                  <td>{{fixed_costs / counter | number: "1.0"}}</td>
                  <td>{{fixed_costs / counter | number: "1.0"}}</td>
                </tr>
                <tr>
                  <td>Bin Weight (t)</td>
                  <td>{{std_bin_weight / counter | number: "1.1-1"}}</td>
                  <td>{{pred_bin_weight / counter | number: "1.1-1"}}</td>
                  <td>Allowances ($)</td>
                  <td>{{ std_allowance / counter | number : "1.0" }}</td>
                  <td>{{ pred_allowance / counter | number : "1.0" }}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td><strong>Total ($)<label *ngIf="!contract_includes_fuel" style="font-size: 12px!important;padding-left: 0px!important;color:red!important">*Not Including Fuel Costs</label></strong></td>
                  <td>{{std_total_cost / counter | number : "1.0-0"}}</td>
                  <td>{{pred_total_cost / counter | number : "1.0-0"}}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td>Cost ($/t): </td>
                  <td>{{std_cost_tonne / counter | number: "1.2-2" }}</td>
                  <td>{{pred_cost_tonne / counter | number: "1.2-2" }}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td>Cost Change($/t)</td>
                  <td></td>
                  <td>{{(pred_cost_tonne - std_cost_tonne)  / counter | number: "1.2-2"}}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td>Cost ($/ha)</td>
                  <td>{{std_cost_ha / counter | number: "1.0-2"}}</td>
                  <td>{{pred_cost_ha / counter | number: "1.0-2"}}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td>Cost Change($/ha)</td>
                  <td></td>
                  <td>{{ (pred_cost_ha - std_cost_ha) / counter | number : "1.0-2" }}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td>Cost ($/hr)</td>
                  <td>{{ std_cost_hour / counter | number : "1.0-0" }}</td>
                  <td>{{ pred_cost_hour / counter | number : "1.0-0" }}</td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                  <td>Cost Change($/hr)</td>
                  <td></td>
                  <td>{{ (pred_cost_hour - std_cost_hour) / counter | number : "1.0-2" }}</td>
                </tr>
              </table>

              <div >
                <div class="fieldwrap label-inline">
                  <label>Fuel Consumption Adjustment (l/h)</label
                  ><label
                    >{{ fuel_consumption_adjustment * 100 | number: '1.0-0' }}%</label
                  >
                  <input
                    type="number"
                    (change)="calculateNewFuel()"
                    id="fuel_consumption_adjustment_input"
                    name="fuel_consumption_adjustment_input"
                    #fuelConsumptionAdjustment="ngModel"
                    [(ngModel)]="fuel_consumption_adjustment_input"
                  />
                </div>
              </div>
              <div class="fieldwrap fieldwrap-type-submit">
                <button
                  class="btn__secondary close-modal"
                  type="button"
                  (click)="
                    saveFuelConsumption(); show_results_dialog_open = false
                  "
                >
                  Save Fuel Consumption Adjustment
                </button>
              </div>

            </fieldset>
            <fieldset *ngIf="!isContract">
              <div class="fieldwrap label-inline">
                <label>No blocks have been calculated for this harvester</label>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
