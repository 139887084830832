import { Component, OnInit, isDevMode } from '@angular/core';
import { IFarm } from '../../models/Farm';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FarmService } from '../../services/farm.service';
import { BlockService } from '../../services/block.service';
import { DialogService } from '../../services/dialog.service';
import { AdminService } from 'src/app/services/admin.service';
import { IDatafields } from '../../models/DataFields';
import { InputPostData } from '../../models/InputData';
import { Console } from 'console';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-farm-details',
  templateUrl: './farm-details.component.html',
  styleUrls: ['./farm-details.component.css'],
  providers: [FarmService, BlockService, AdminService, InputPostData],
})
export class FarmDetailsComponent implements OnInit {
  id: string;
  farm: any = {};
  isEdit: boolean = false;
  blockGridData: any = [];
  regionLists: any = [];
  millLists: any = [];
  selectedMill: any = {};
  millSeasonLists: any = [];
  selectedYear: number = 2022;
  farmId: string = '';
  dataFormat: any;
  selectedSeason: any;
  selectedMoisture: any;
  upload_mill_data: any = {};
  dataPost: any = {};
  blockdata: any = {};
  formatHasDownload: boolean = false;

  toppingList: any = ['moderate', 'light', 'none'];
  currentTopping: string = this.toppingList[0];
  default_ccs: number = 0;
  presentationList: any = ['erect', 'sprawled', 'lodged'];
  currentPresentation: any = this.presentationList[0];

  moistureList: any = ['dry', 'damp'];
  currentMoisture: any = this.moistureList[0];

  format_type = [
    {
      text: 'Tully',
      value: 'Tully',
    },
    {
      text: 'MSF',
      value: 'MSF',
    },
    {
      text: 'ISIS',
      value: 'ISIS',
    },
    {
      text: 'Mackay',
      value: 'Mackay',
    },
    {
      text: 'Bundaberg',
      value: 'Bundaberg',
    },
    {
      text: 'Wilmar Standardized',
      value: 'Wilmar_Standard',
    },
  ];
  currentYear = new Date().getFullYear();
  seasons = [
    this.currentYear - 3,
    this.currentYear - 2,
    this.currentYear - 1,
    this.currentYear,
    this.currentYear + 1,
    this.currentYear + 2,
    this.currentYear + 3,
  ];
  moisture_list = [
    'presentation',
    'topping',
    'ccs',
    'default_ground_speed',
    'default_fan_speed',
  ];
  show_results_dialog_open: boolean = false;
  fieldNames: IDatafields[] = [
    { value: 'name', desc: 'Farm Name' },
    { value: 'mill_id', desc: 'Mill' },
  ];

  //upload file
  shortLink: string = '';
  loading: boolean = false;
  file: File = new File([], '');
  season: string = '';

  constructor(
    private farmApi: FarmService,
    private dialog: DialogService,
    private blockAPI: BlockService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private adminApi: AdminService,
    private inputData: InputPostData
  ) {
    this.id = '';
  }
  // upload file
  onChange(event: any) {
    this.file = event.target.files[0];
  }

  countChangedHandler(event: number, variableName: string) {
    this.blockdata[variableName] = +event;

    if (variableName == 'default_ccs') {
      this.default_ccs = event / 100;
    }
  }

  async changeModel(model_id: number): Promise<void> {

  }

  onSelectFormat(format: any) {
    this.formatHasDownload = false;
    if (format == 'Wilmar_Standard') {
      this.formatHasDownload = true;
    }
  }
  downloadFormat() {
    this.farmApi.downloadDataFormat(this.dataFormat);
  }

  ccsFormat(value: number): string {
    return formatNumber(value, 'en-AU', '0.2-2')
  }

  percentToNumber(input: number, precision: number = 2): number {
    return Number((input / 100).toFixed(precision));
  }

  onUpload() {
    // this.loading = !this.loading;

    // this.blockAPI.upload_file(this.file).subscribe(
    //     (event: any) => {
    //           if (typeof (event) == 'object') {

    //               // Short link via api response
    //               this.shortLink = event.link;


    //               this.loading = false; // Flag variable
    //           }
    //       }
    //   );
    let is_valid = true;
    if (!this.selectedSeason) {
      this.toastr.warning('Please select the Season');
      is_valid = false;
    }
    if (!this.dataFormat) {
      this.toastr.warning('Please select the Format type');
      is_valid = false;
    }
    if (!this.file.name) {
      this.toastr.warning('Please update the File');
      is_valid = false;
    }
    if (!this.blockdata.moisture) {
      this.toastr.warning('Please enter moisture');
      is_valid = false;
    }
    if (!this.blockdata.presentation) {
      this.toastr.warning('Please enter presentation');
      is_valid = false;
    }
    if (this.blockdata.ccs === undefined) {
      this.toastr.warning('Please enter ccs');
      is_valid = false;
    }
    if (!this.blockdata.default_ground_speed  === undefined) {
      this.toastr.warning('Please enter default_ground_speed');
      is_valid = false;
    }
    if (!this.blockdata.default_fan_speed  === undefined) {
      this.toastr.warning('Please enter default_fan_speed');
      is_valid = false;
    }
    if (!this.blockdata.number_haulouts_utilised  === undefined) {
      this.toastr.warning('Please enter Number of haulouts');
      is_valid = false;
    }
    if (!this.blockdata.row_spacing  === undefined) {
      this.toastr.warning('Please enter Row spacing');
      is_valid = false;
    }
    if (!this.blockdata.average_row_length  === undefined) {
      this.toastr.warning('Please enter Average row length');
      is_valid = false;
    }
    if (!this.blockdata.average_infield_haulout_distance  === undefined) {
      this.toastr.warning('Please enter Average infield haulout distance');
      is_valid = false;
    }
    if (!this.blockdata.average_harvester_turnaround_time  === undefined) {
      this.toastr.warning('Please enter Average harvester turnaround time');
      is_valid = false;
    }
    if (!this.blockdata.average_bin_mass  === undefined) {
      this.toastr.warning('Please enter Average bin mass');
      is_valid = false;
    }

    this.loading = !this.loading;
    if (!is_valid) {
      return;
    }

    //prepare body
    const data = {
      ...this.blockdata,
      ccs: this.percentToNumber(this.blockdata.ccs, 4)
    };
    
    this.upload_mill_data.season = this.selectedSeason;
    this.upload_mill_data.farm_id = this.id;
    this.upload_mill_data.data_format = this.dataFormat;
    this.upload_mill_data.filename = this.file.name;
    this.upload_mill_data.block_data = data;


    //call api
    this.blockAPI.upload_mill_data(this.upload_mill_data).subscribe({
      next: (s: any) => {
        if (s.body) {
          const presigned_post: any = s.body.presigned_post;

          this.toastr.success('Request sent successfully.');
          this.blockAPI
            .uploadfile_AWS_S3(presigned_post, this.file.type, this.file)
            .subscribe((event: any) => {
              this.loading = false;

              // if (event == null) {
              //   this.toastr.success('File updated successfully successfully.');
              // }
              if (event.type) {
                this.toastr.error('Error uploading file to AWS S3');
              }
            });
        }
        if (s.error) {
          this.toastr.error(
            `Error sending the request. Error: ${s.error.errorMessage}`
          );
          this.toastr.error(
            `Error sending the request. Error: ${s.errorMessage}`
          );
        }
      },
      error: (error: any) => this.toastr.error(error.error.errorMessage)
  
    });
  }

  show_data(): void {
    this.show_results_dialog_open = true;
  }

  downloadStandardFormat() {

  }

  loadData(type: string, data: any): any {
    this.adminApi.getAll(type).subscribe((l) => {
      if (l.body) {
        switch (type) {
          case 'mills/season':
            this.millSeasonLists = l.body.filter(
              (obj: any) => obj.status == 'ACTIVE'
            );

            break;
          case 'mills':
            this.millLists = l.body.filter(
              (obj: any) => obj.status == 'ACTIVE' && obj.seasons.length > 0
            );

            if (!this.id) {
              this.selectedMill = this.millLists[0];
            }

            break;
          case 'regions':
            this.regionLists = l.body.filter(
              (obj: any) => obj.status == 'ACTIVE'
            );

            break;
          default:
            break;
        }
      }
    });
  }

  deleteBlock(block_number: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to delete the block ?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        let entity = 'block';
        let farm_ext_id = this.farm.ext_id;
        let parameters = `?entity=${entity}&farm_ext_id=${farm_ext_id}&block_number=${block_number}`;
        if (yes) {

          this.adminApi.delete(parameters).subscribe(
            (s) => {

              if (s.body.includes('successfully')) {
                this.toastr.success(s.body);
                this.ngOnInit();
              }
            },
            (error) => this.toastr.error(error.error.errorMessage)
          );
        }
      });
  }

  onSelectMill(id: any) {
    this.selectedMill = this.millLists.find((obj: any) => obj.id == Number(id));

  }

  ngOnInit(): void {
    this.dataPost = this.inputData.dataType;
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.blockAPI.getAll(this.id).subscribe((f: any) => {

          this.farm = f.body;
          this.farmId = f.body.id;
          this.isEdit = true;

          this.selectedMill = f.body.mill_details;
          this.blockGridData = f.body.blocks.filter(
            (obj: any) => obj.status == 'ACTIVE'
          );

          // Getting current year
          this.blockGridData.forEach((block:any) => {
            block['current_crop'] = null;

            if (block.crops.length > 0)
              block['current_crop'] = block.crops.find(
                (obj: any) => obj.season == this.currentYear
              );

          });
          
        });
      }
    });
    this.loadData('mills', this.millLists);
  }

  async save(): Promise<any> {
    let is_valid = true;
    this.fieldNames.forEach((e) => {
      if (!this.farm[e.value]) {
        this.toastr.warning(`Please select the ${e.desc}!`);
        return (is_valid = false);
      } else {
        return (is_valid = true);
      }
    });

    if (is_valid) {
      if (!this.farm.id) {
        this.farmApi.create(this.farm).subscribe(
          (h) => {
            if (h.body.status == 'ACTIVE') {
              this.farm.id = h.body.id;
              this.toastr.success('Farm created successfully.');
              location.href = '/farms';
            } else {
              this.toastr.error(h.body.errorMessage);
            }
          },
          (error) => this.toastr.error(error.error.errorMessage)
        );
      } else {
        //(this.farm)
        let data = {
          name: this.farm.name,
          mill_id: this.farm.mill_id,
        };
        this.farmApi.update(data, this.farm.ext_id).subscribe(
          (h: any) => {
            if (h.body == 'Successfully Updated') {
              this.toastr.success('Farm updated successfully.');
            } else {
              this.toastr.error(h.errorMessage);
            }
          },
          (error) => this.toastr.error(error.error.errorMessage)
        );
      }
    }
  }
}
