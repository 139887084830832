import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IUser, CognitoService } from '../services/cognito.service';
import { ToastrService } from 'ngx-toastr';
import { globals } from '../globals';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
  loading: boolean;
  user: IUser;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private toastr: ToastrService
  ) {
    this.loading = false;
    this.user = {} as IUser;
  }

  async ngOnInit(): Promise<void> {
    if (await this.cognitoService.isAuthenticated()) {
      this.router.navigate(['/home']);
    }
  }

  public async signIn(): Promise<any> {
    let is_valid = true;
    if (!this.user.email) {
      this.toastr.warning('Please enter Email');
      is_valid = false;
    }
    if (!this.user.password) {
      this.toastr.warning('Please enter Password');
      is_valid = false;
    }
    if (is_valid) {
      this.loading = true;
      await this.cognitoService
        .signIn(this.user)
        .then((u) => {
          this.router.navigate(['/home']);
        })
        .catch((e) => {
          //this.toastr.error(e.message);
          if (e.message == 'User is not confirmed.') {
            //resend code for authenticate the user
            this.cognitoService
              .resendSignUp(this.user)
              .then(() => {
                this.loading = false;
                this.toastr.error('Please provide verification code');
                this.toastr.error('User is not confirmed');
                this.router.navigate(['/confirmSignUp'], {
                  queryParams: { email: this.user.email },
                });
              })
              .catch((e) => {
                this.toastr.error(e.message);
                this.loading = false;
              });
          } else {
            this.toastr.error(e.message);
          }
          this.loading = false;
        });
    }
  }
}
