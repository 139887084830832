import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IUser, CognitoService } from '../services/cognito.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { globals } from '../globals';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent {
  loading: boolean;
  user: IUser;
  code: string = '';
  new_password: string = '';
  confirm_password: string = '';
  orderObj: any;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.loading = false;
    this.user = {} as IUser;
  }

  public async resetPassword(): Promise<any> {
    let is_valid = true;
    if (!this.code) {
      this.toastr.warning('Please enter Verification Code');
      is_valid = false;
    }
    if (!this.new_password) {
      this.toastr.warning('Please enter New Password');
      is_valid = false;
    }
    if (!this.confirm_password) {
      this.toastr.warning('Please Confirm New Password');
      is_valid = false;
    }
    if (this.new_password != this.confirm_password) {
      this.toastr.warning('Passwords dont match');
      is_valid = false;
    }
    if (is_valid) {
      this.route.queryParamMap.subscribe((params) => {
        this.orderObj = { ...params.keys, ...params };
      });
      this.user.email = this.orderObj.params.email;
      this.cognitoService
        .forgotPasswordSubmit(this.user, this.code, this.new_password)
        .then((u) => {
          this.toastr.success('Password changed successfully');
          this.router.navigate(['/signIn']);
        })
        .catch((e) => {
          this.toastr.error(e.message);
          this.loading = false;
        });
    }
  }
}
