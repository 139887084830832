<div class="content-1920">
    <div>
        <h2>seasons</h2>

        <div class="columns-wrap">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Season</th>
                            <th scope="col">Annual Leave Loading Rate</th>
                            <th scope="col">Superannuation Rate</th>
                            <th scope="col">Truck Price</th>
                            <th scope="col">Truck Life (years)</th>
                            <th scope="col">Truck Salvage Value</th>
                            <th scope="col">Equipment Price</th>
                            <th scope="col">Equipment Life (years)</th>
                            <th scope="col">Equipment Salvage Value</th>
                            <th scope="col">Tools Price</th>
                            <th scope="col">Tools Life (years)</th>
                            <th scope="col">Tools Salvage Value</th>
                            <th scope="col">Diesel Rebate</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of seasonList">
                            <td>{{row.season}}</td>
                            <td>{{row.annual_leave_loading_rate}} {{dataPost.percent}}</td>
                            <td>{{row.superannuation_rate}} {{dataPost.percent}}</td>
                            <td>{{dataPre.dollar}} {{row.truck_price}}</td>
                            <td>{{row.truck_life_years}}</td>
                            <td>{{dataPre.dollar}} {{row.truck_salvage_value}}</td>
                            <td>{{dataPre.dollar}} {{row.equipment_price}}</td>
                            <td>{{row.equipment_life_years}}</td>
                            <td>{{dataPre.dollar}} {{row.equipment_salvage_value}}</td>
                            <td>{{dataPre.dollar}} {{row.tools_price}}</td>
                            <td>{{row.tools_life_years}}</td>
                            <td>{{dataPre.dollar}} {{row.tools_salvage_value}}</td>
                            <td>{{row.diesel_rebate}}</td>
                            <td>
                                <button class="btn-small btn-left-space" type="button" (click)="update(row.season)">
                                    Edit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <form>
                <fieldset>
                    <div class="columns2">
                        <div class="fieldwrap label-inline">
                            <label>Season: </label>
                            <input type="number" id="seasonYear" name="seasonYear" #seasonYear="ngModel"
                                [(ngModel)]="seasonSelected.season" />
                        </div>
                        <div></div>
                    </div>
                </fieldset>

                <h2>Labour</h2>
                <fieldset>
                    <div class="columns2">
                        <div class="fieldwrap label-inline">
                            <label>Annual leave loading rate: </label>
                            <app-number-input [dataPost]="dataPost.percent"
                                [(numberInput)]="seasonSelected.annual_leave_loading_rate" [default]="0">
                            </app-number-input>
                        </div>
                        <div class="fieldwrap label-inline">
                            <label>Superannuation rate: </label>
                            <app-number-input [dataPost]="dataPost.percent"
                                [(numberInput)]="seasonSelected.superannuation_rate" [default]="0">
                            </app-number-input>
                        </div>

                        <div></div>
                    </div>
                </fieldset>

                <h2>Fuel</h2>
                <fieldset>
                    <div class="columns2">
                        <div class="fieldwrap label-inline">
                            <label>Diesel rebate: </label>
                            <app-number-input [dataPre]="dataPre.dollar" [(numberInput)]="seasonSelected.diesel_rebate"
                                [default]="0">
                            </app-number-input>
                        </div>

                        <div></div>
                    </div>
                </fieldset>

                <h2>Depreciation</h2>
                <fieldset>
                    <div class="columns-wrap">
                        <div class="columns2">
                            <div class="fieldwrap label-inline">
                                <label>Truck price: </label>
                                <app-number-input [dataPre]="dataPre.dollar"
                                    [(numberInput)]="seasonSelected.truck_price" [default]="0">
                                </app-number-input>
                            </div>
                            <div class="fieldwrap label-inline">
                                <label>Truck Life (years): </label>
                                <input type="number" id="truck_life_years" name="truck_life_years"
                                    #truck_life_years="ngModel" [(ngModel)]="seasonSelected.truck_life_years" />
                            </div>
                            <div class="fieldwrap label-inline">
                                <label>Truck Salvage Value: </label>
                                <app-number-input [dataPre]="dataPre.dollar"
                                    [(numberInput)]="seasonSelected.truck_salvage_value" [default]="0">
                                </app-number-input>
                            </div>

                            <div></div>
                        </div>

                        <label>
                            <small></small>
                        </label>

                        <div class="columns2">
                            <div class="fieldwrap label-inline">
                                <label>Equipment price: </label>
                                <app-number-input [dataPre]="dataPre.dollar"
                                    [(numberInput)]="seasonSelected.equipment_price" [default]="0">
                                </app-number-input>
                            </div>
                            <div class="fieldwrap label-inline">
                                <label>Equipment Life (years): </label>
                                <input type="number" id="equipment_life_years" name="equipment_life_years"
                                    #equipment_life_years="ngModel" [(ngModel)]="seasonSelected.equipment_life_years" />
                            </div>
                            <div class="fieldwrap label-inline">
                                <label>Equipment Salvage Value: </label>
                                <app-number-input [dataPre]="dataPre.dollar"
                                    [(numberInput)]="seasonSelected.equipment_salvage_value" [default]="0">
                                </app-number-input>
                            </div>

                            <div></div>
                        </div>

                        <label>
                            <small></small>
                        </label>

                        <div class="columns2">
                            <div class="fieldwrap label-inline">
                                <label>Tools price: </label>
                                <app-number-input [dataPre]="dataPre.dollar"
                                    [(numberInput)]="seasonSelected.tools_price" [default]="0">
                                </app-number-input>
                            </div>
                            <div class="fieldwrap label-inline">
                                <label>Tools Life (years): </label>
                                <input type="number" id="tools_life_years" name="tools_life_years"
                                    #tools_life_years="ngModel" [(ngModel)]="seasonSelected.tools_life_years" />
                            </div>
                            <div class="fieldwrap label-inline">
                                <label>Tools Salvage Value: </label>
                                <app-number-input [dataPre]="dataPre.dollar"
                                    [(numberInput)]="seasonSelected.tools_salvage_value" [default]="0">
                                </app-number-input>
                            </div>

                            <div></div>
                        </div>
                    </div>
                </fieldset>

                <div class="fieldwrap label-inline">
                    <button class="btn-save" type="submit" (click)="save()">
                        {{ btnText }}
                    </button>
                    <button class="btn-save" type="submit" (click)="cancel()">
                        Cancel
                    </button>
                </div>

            </form>

        </div>
    </div>
</div>