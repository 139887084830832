<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>

<div mat-dialog-actions [align]="'end'">
  <input
    type="email"
    [(ngModel)]="data.input"
    placeholder="Ex. pat@example.com"
  />
  <button mat-raised-button class="grey" [mat-dialog-close]="false">
    {{ data.cancelCaption }}
  </button>
  <button
    mat-raised-button
    class="orange"
    style="margin-left: 10px !important"
    [mat-dialog-close]="data.input"
  >
    {{ data.confirmCaption }}
  </button>
</div>
