<mat-accordion class="example-headers-align" multi *ngFor="let row of gridData">
  <mat-expansion-panel class="expand">
    <mat-expansion-panel-header>
      <mat-panel-title class="expand-header">
        {{ row.name | uppercase }}
      </mat-panel-title>
      <mat-panel-description>{{ row.status }} </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngFor="let farm of row.farms">

      <div>
        <div class="column3">
          <mat-divider></mat-divider>
          <div></div>&nbsp;&nbsp;&nbsp;
          <h5><b>Farm: </b>{{ farm.name }}</h5>
          <div></div>&nbsp;&nbsp;&nbsp;
        </div>
        <mat-divider></mat-divider>
        <div></div>&nbsp;&nbsp;&nbsp;
        <h5><b>Blocks: </b></h5>
        <div></div>&nbsp;&nbsp;&nbsp;
        <!-- <div class="columns-wrap">
          <div class="columns3">
            <div class="fieldwrap">
              <label>Farm Name</label>
              <input
                type="text"
                id="name"
                name="name"
                #name="ngModel"
                [(ngModel)]="farm.name"
              />
            </div>
          </div>
        </div> -->
        <mat-chip-list #c="matChipList" aria-label="block selection" selectable multiple selected="1">
          Select All<input type="checkbox" id="selectAll" (click)="selectAll($event, farm.blocks)" />
          <!-- <mat-chip (click)="selectAll(farm.blocks)">Select all</mat-chip> -->
          <div *ngFor="let block of farm.blocks">
            <div *ngIf="block.assigned_harvester">
              <mat-chip #c="matChip" (click)="
                  toggleSelection(
                    c,
                    block.block_number,
                    block.farm_id,
                    block.assigned_harvester.id
                  )
                " [selected]="isBlockselected || block.assigned_harvester">
                {{ block.block_number }} :
                <b>{{ block.assigned_harvester.model_name }}</b>
              </mat-chip>
            </div>
            <div *ngIf="!block.assigned_harvester">
              <mat-chip #c="matChip" (click)="toggleSelection(c, block.block_number, block.farm_id)"
                [selected]="isBlockselected">
                {{ block.block_number }} :
              </mat-chip>
            </div>
          </div>
        </mat-chip-list>
      </div>
      <div></div>&nbsp;&nbsp;&nbsp;
      <div>
        <div class="columns-wrap">
          <div class="columns3">
            <div class="fieldwrap">
              <div class="column3">
              </div>
              <!-- <label>Blocks</label> -->
              <!-- <input
                type="text"
                id="name"
                name="name"
                #name="ngModel"
                [(ngModel)]="block.block_id"
              /> -->
              <div class="columns-wrap" class="expansion-panel-body">
                <div class="columns12">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Block Number</th>
                        <th scope="col">Row Spacing</th>
                        <th scope="col">Avg. Row Length</th>
                        <th scope="col">Avg. Infield Haulout Distance</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let block of farm.blocks" (click)="onUpdate(farm.id, block)" style="cursor: pointer">
                        <td>{{ block.block_number }}</td>
                        <td>{{ block.row_spacing }}</td>
                        <td>{{ block.average_row_length }}</td>
                        <td>{{ block.average_infield_haulout_distance }}</td>
                        <!-- <td>
                          <a
                            class="btn-small"
                            routerLink="/block-details"
                            routerLinkActive="active"
                            [queryParams]="{
                              id: row.id,
                              block_number: row.block_number,
                              farm_id: farm.ext_id
                            }"
                            >Details</a
                          >
                        </td>
                        <td>
                          <a
                            class="btn-small"
                            routerLink="/block-details"
                            routerLinkActive="active"
                            [queryParams]="{
                              id: row.id,
                              block_number: row.block_number,
                              farm_id: farm.ext_id,
                              iscopy: 'true'
                            }"
                            >Copy</a
                          >
                        </td>  -->
                        <!-- <td>
                          <button
                            class="btn-small btn-left-space"
                            type="button"
                            (click)="deleteBlock(row.block_number)"
                          >
                            Delete
                          </button>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <button class="btn-save" type="submit" (click)="resetAllCalculationsDialog(gridData)">
      Reset All Calculations (All blocks in group)
    </button>
  </mat-expansion-panel>
</mat-accordion>

<div class="fieldwrap label-inline">
  <div class="fieldwrap fieldwrap-type-submit align-right">
    <label></label>
    <select *ngIf="isBlockselected" [ngModel]="selectedHarvester" (ngModelChange)="onChangeHarvester($event)">
      <option *ngFor="let h of harvesterData" value="{{ h.id }}">
        {{ h.model_name }}
      </option>
    </select>
    <button *ngIf="readyToAssign" class="btn-save" type="submit" (click)="assignHarvester()">
      Assign Harvester
    </button>
    <button *ngIf="readyToRemove && selectedFarm" class="btn__secondary" (click)="assignHarvester(true)">
      Remove from Blocks
    </button>
  </div>
</div>




<div id="show_results" [ngClass]="{ 'overlay open': dialog_open }">
  <div class="modal-box">
    <button class="close" type="button" (click)="dialog_open = false"></button>
    <form>
      <div class="modal-header">
        <h2>Details</h2>
      </div>
      <fieldset>
        <div class="fieldwrap label-inline">
          <label>Block No.</label>
          <input type="text" id="blockName" name="blockName" #block_number="ngModel"
            [(ngModel)]="currentBlock.block_number" readonly />
        </div>
        <div class="fieldwrap label-inline">
          <label>Area</label>
          <app-number-input [dataPost]="dataPost.ha" [(numberInput)]="currentBlock.area">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>No. of Haulouts Utilised</label>
          <app-number-input [dataPost]="dataPost.num" [(numberInput)]="currentBlock.number_haulouts_utilised">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>Row Spacing</label>
          <app-number-input [dataPost]="dataPost.m" [(numberInput)]="currentBlock.row_spacing">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>Average Row Length</label>
          <app-number-input [dataPost]="dataPost.m" [(numberInput)]="currentBlock.average_row_length">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>Average Infield Haulout Distance (one-way)</label>
          <app-number-input [dataPost]="dataPost.km" [(numberInput)]="currentBlock.average_infield_haulout_distance">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>Average Siding Bin Mass</label>
          <app-number-input [dataPost]="dataPost.t" [(numberInput)]="currentBlock.average_bin_mass">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>Average Harvester turnaround time</label>
          <app-number-input [dataPost]="dataPost.sec" [(numberInput)]="currentBlock.average_harvester_turnaround_time">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>Allowance Received (for block / representative area)</label>
          <app-number-input [dataPost]="dataPost.dollar_t"
            [(numberInput)]="currentBlock.proportion_total_allowances_paid_drivers">
          </app-number-input>
        </div>

        <div class="fieldwrap label-inline">
          <label>Variety</label>
          <input type="text" id="variety_id" name="variety_id" #variety_id="ngModel"
            [(ngModel)]="currentCrop.variety_id" readonly />
          <!-- <select
            id="variety_id"
            name="variety_id"
            #variety_id="ngModel"
            [(ngModel)]="currentCrop.variety_id"
            readonly
          >
            <option *ngFor="let e of varietyList" value="{{ e.id }}">
              {{ e.name }}
            </option>
          </select> -->
        </div>
        <div class="fieldwrap label-inline">
          <label>Crop Class</label>
          <select id="crop_class" name="crop_class" #crop_class="ngModel" [(ngModel)]="currentCrop.crop_class">
            <option *ngFor="let e of CropList" value="{{ e }}">
              {{ e }}
            </option>
          </select>
        </div>
        <div class="fieldwrap label-inline">
          <label>Presentation</label>
          <select id="presentation" name="presentation" #presentation="ngModel" [(ngModel)]="currentCrop.presentation">
            <option *ngFor="let e of presentationList" value="{{ e }}">
              {{ e }}
            </option>
          </select>
        </div>
        <div class="fieldwrap label-inline">
          <label>Topping</label>
          <select id="topping" name="topping" #topping="ngModel" [(ngModel)]="currentCrop.topping">
            <option *ngFor="let e of toppingList" value="{{ e }}">
              {{ e }}
            </option>
          </select>
        </div>
        <div class="fieldwrap label-inline">
          <label>Field Moisture</label>
          <select id="field_moisture" name="field_moisture" #field_moisture="ngModel"
            [(ngModel)]="currentCrop.field_moisture">
            <option *ngFor="let e of fieldMoistureList" value="{{ e }}">
              {{ e }}
            </option>
          </select>
        </div>

        <div class="fieldwrap label-inline">
          <label>Estimated CCS</label>
          <app-number-input [dataPost]="dataPost.num" [(numberInput)]="currentCrop.default_ccs" [min]="6"
            [default]="12">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>Estimated Yield</label>
          <app-number-input [dataPost]="dataPost.ha" [(numberInput)]="currentCrop.default_yield">
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label>Standard Ground Speed</label>
          <app-number-input [dataPost]="dataPost.kmh" [(numberInput)]="currentCrop.default_ground_speed" [min]="0">
          </app-number-input>
        </div>

        <div class="fieldwrap label-inline">
          <label>Standard Fan Speed</label>
          <app-number-input [dataPost]="dataPost.rpm" [(numberInput)]="currentCrop.default_fan_speed">
          </app-number-input>
        </div>
        <div class="columns4">
          <div class="fieldwrap fieldwrap-type-submit">
            <button class="btn-save" type="submit" (click)="dialog_open = false">
              Close
            </button>
            <button (click)="save(); dialog_open = false" class="btn__secondary" type="button">
              Save
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>