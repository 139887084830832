import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { IUser, CognitoService } from './services/cognito.service';
import { environment } from '../environments/environment';
import { globals } from './globals';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'SRA Portal';

  isAuthenticated: boolean;
  user: IUser;
  shortName: string;
  fullName: string;
  isAdmin: boolean;
  isGrower: boolean;
  isContrator: boolean;

  constructor(private router: Router, private cognitoService: CognitoService) {
    this.isAuthenticated = false;
    this.user = {} as IUser;
    this.shortName = '';
    this.fullName = '';
    this.isAdmin = false;
    this.isContrator = false;
    this.isGrower = false;

    router.events
      .pipe(filter((e) => e instanceof RoutesRecognized))
      .subscribe((s: any) => {

        this.cognitoService
          .isAuthenticated()
          .then((u: any) => {
            if (u) {

              this.isAuthenticated = true;
              this.cognitoService.getUser().then((user) => {
                this.isAdmin = user.attributes['custom:user_type'] == 'ADMIN';
                this.isContrator =
                  user.attributes['custom:user_type'] == 'CONTRACTOR';
                this.isGrower = user.attributes['custom:user_type'] == 'GROWER';
                if (this.isAdmin) {
                  localStorage.setItem('current_email', this.user.email);
                }
                this.user.givenName = user.attributes.given_name.toUpperCase();
                this.user.lastName = user.attributes.family_name.toUpperCase();
                this.fullName = this.user.givenName + ' ' + this.user.lastName;
                this.shortName =
                  this.user.givenName.substring(0, 1) +
                  this.user.lastName.substring(0, 1);
              });

              if (this.isAuthenticated) {
                // if(s.urlAfterRedirects == '/signIn') {
                //   this.router.navigate(['/home']);
                // } else {
                //   this.router.navigate([s.urlAfterRedirects]);
                // }
              } else {
                this.isAuthenticated = false;
                this.router.navigate(['/signIn']);
              }
            }
          })
          .catch((e) => {
            this.isAuthenticated = false;
            this.router.navigate(['/signIn']);
          });
      });
  }

  public signOut(): void {
    this.cognitoService.signOut().then(() => {
      localStorage.removeItem(environment.keys.accessKey);
      localStorage.removeItem(environment.keys.secretKey);
      localStorage.removeItem(environment.keys.sessionToken);

      this.isAuthenticated = false;
      this.router.navigate(['/signIn']);
    });
  }
}
