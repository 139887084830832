<div class="content-1920">
  <div class="content-padding margin-top-small">
    <div class="main">
      <h2>Harvester Details</h2>
      <fieldset>
        <fieldset>
          <div class="fieldwrap label-inline">
            <label>Harvester Model</label>
            <select
              id="model"
              name="model"
              #model="ngModel"
              [(ngModel)]="harvester.model_id"
              (change)="changeModel(model.value)"
            >
              <option *ngFor="let e of harvesterModels" value="{{ e.id }}">
                {{ e.model_name }}
              </option>
            </select>
          </div>
        </fieldset>
        <fieldset>
          <div class="fieldwrap label-inline">
            <label>Primary Extractor Fan</label>
            <select
              id="extractor_fan_config_id"
              name="extractor_fan_config_id"
              #model="ngModel"
              [(ngModel)]="harvester.extractor_fan_config_id"
            >
              <option *ngFor="let e of extractorFans" value="{{ e.id }}">
                {{ e.brand_name }} {{ e.name }}
              </option>
            </select>
          </div>
          <div class="fieldwrap label-inline">
            <label>Wheels/Tracks</label>
            <select
              id="wheels_tracks"
              name="wheels_tracks"
              #wheels_tracks="ngModel"
              [(ngModel)]="harvester.undercarriage"
            >
              <option *ngFor="let e of wheelTrackOptions" value="{{ e }}">
                {{ e | titlecase }}
              </option>
            </select>
          </div>
          <div class="fieldwrap label-inline">
            <label>Manufactured Year</label>
            <input type="text" minlength="4" maxlength="4" [(ngModel)]="current_manufactured_date"/>
            <!-- <select
              id="manufactured_date"
              name="manufactured_date"
              #model="ngModel"
              [(ngModel)]="current_manufactured_date"
            >
              <option *ngFor="let e of years">{{ e }}</option>
            </select> -->
          </div>
          <div class="fieldwrap label-inline">
            <label>Feedtrain Setup</label>
            <select
              id="feedtrain_setp"
              name="feedtrain_setp"
              #feedtrain_setp="ngModel"
              [(ngModel)]="harvester.feedtrain_setup"
            >
              <option *ngFor="let e of feedtrainOptions" value="{{ e }}">
                {{ e | titlecase }}
              </option>
            </select>
          </div>
        </fieldset>
      </fieldset>
      <div class="fieldwrap fieldwrap-type-submit align-right">
        <a class="btn__secondary btn-back" routerLink="/harvesters">Back</a>
        <button class="btn-save" type="submit" (click)="save()">Save</button>
        <!-- <button class="btn-continue" type="submit">Save & Continue</button> -->
      </div>
      <div *ngIf="id && harvester">
        <app-harvester-season
          [harvesterData]="harvester"
          [harvesterId]="id"
        ></app-harvester-season>
      </div>
    </div>
  </div>
</div>
