import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { AdminService } from 'src/app/services/admin.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-crop-management',
  templateUrl: './crop-management.component.html',
  styleUrls: ['./crop-management.component.css'],
  providers: [AdminService],
})
export class CropManagementComponent implements OnInit {
  varietyList: any = [];
  varietyListModelled: any = [];
  currentVariety: any = {};
  variety_id = 0;
  varietyText = 'Add variety';
  tabHeader: String = 'Varieties';
  varietyIsActiveToggle = false;

  billetDiameters = [{
    label: "Thin",
    value: "thin"
  }, {
    label: "Thin-Average",
    value: "thin-aver"
  }, {
    label: "Average",
    value: "aver"
  }, {
    label: "Average-Thick",
    value: "aver-thick"
  }, {
    label: "Thick",
    value: "thick"
  }]

  constructor(
    private dialog: DialogService,
    private toastr: ToastrService,
    private adminApi: AdminService,
    private logging: LoggingService
  ) { }

  deleteDialog(id: string, endpoint: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to delete?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        if (yes) {
          let data = {
            deleted_at: new Date(),
            status: 'DELETED',
          };
          this.updateData(
            endpoint + '/' + String(id),
            'variety',
            data,
            'deleted'
          );
        }
      });
  }
  loadData(type: string, data: any): any {
    this.adminApi.getAll(type).subscribe((l) => {
      if (l.body) {
        switch (type) {
          case 'variety':
            this.varietyList = (l.body as []).sort((a: any, b: any) => Intl.Collator('en', { numeric: true }).compare(a.name, b.name))

            this.varietyListModelled = l.body.filter(
              (obj: any) => obj.modelled_variety_id == null
            ).sort((a: any, b: any) => Intl.Collator('en', { numeric: true }).compare(a.name, b.name))
            break
          default:
            break;
        }
      }
    });
  }
  async updateData(
    endpoint: string,
    type: string,
    data: any,
    action: string
  ): Promise<any> {
    this.adminApi.update(endpoint, data).subscribe((h) => {
      if (h.body == 1) {
        this.toastr.success(type + ' ' + action + ' successfully.');
        this.loadData('variety', this.varietyList);
      } else {
        this.toastr.error(h.body.error);
      }
    });
  }
  update(type: string, id: number): void {
    this.variety_id = id;
    switch (type) {
      case 'variety':
        this.varietyText = 'Update Variety';
        this.currentVariety = this.varietyList.find(
          (obj: any) => obj.id == Number(id)
        );
        this.varietyIsActiveToggle = this.currentVariety.status === "ACTIVE";
        break;
      default:
        break;
    }
  }
  cancel(type: string): void {
    switch (type) {
      case 'variety':
        this.currentVariety = {};
        this.varietyText = 'Add Variety';
        break;
      default:
        break;
    }
  }
  async create(endpoint: string, type: string, data: any): Promise<any> {
    this.adminApi.create(endpoint, data).subscribe((h) => {
      if (h.body.id) {
        this.toastr.success(type + ' created successfully.');
        //this.loadData('varieties', this.varietyList);
      } else {
        this.toastr.error(h.body.error);
      }
    });
  }

  save(type: string) {
    delete this.currentVariety.modelled_variety

    if (this.currentVariety.modelled_variety_id === "") {
      this.currentVariety.modelled_variety_id = null;
    }

    let data = JSON.stringify(this.currentVariety);

    if (this.variety_id != 0) {
      //delete this.currentRegion['region_name']
      this.updateData('variety/' + String(this.variety_id), 'Variety', data, 'updated');
    } else {
      this.create(
        'variety',
        'Variety',
        JSON.stringify(this.currentVariety)
      );
    }
  }

  ngOnInit(): void {
    this.loadData('variety', this.varietyList);
  }


  toggleVarietyStatus() {
    this.currentVariety.status = this.varietyIsActiveToggle === true ? "ACTIVE" : "INACTIVE";
  }
}
