import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class GroupService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Bearer ' + localStorage.getItem(environment.keys.accessToken) || '',
    }),
  };

  constructor(private http: HttpClient) {

  }

  create(body: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + 'groups', body, this.httpOptions)
  }
  // HttpClient API update() method => Update harvest
  update(body: any, id: string): Observable<any> {
    return this.http
      .put<any>(environment.apiUrl + 'groups/' + id, body, this.httpOptions)
  }

  get(group: any): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `groups/${group}`, this.httpOptions)
      .pipe(retry(1));
  }

  getAll(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + 'groups', this.httpOptions)
      .pipe(retry(1));
  }

  list_growers_in_group(group_id: string): Observable<any> {
    return this.http
      .get<any>(
        environment.apiUrl + `group/${group_id}/growers`,
        this.httpOptions
      )
      .pipe(retry(1));
  }

  postInvitation(group: any, body: any): Observable<any> {
    return this.http
      .post<any>(
        environment.apiUrl + `/groups/${group}/invitations`,
        body,
        this.httpOptions
      )
  }

  getAllGroupBlocks(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + '/groups-blocks', this.httpOptions)
      .pipe(retry(1));
  }

  decline_invitation(group: any): Observable<any> {
    return this.http
      .post<any>(
        environment.apiUrl + `groups/${group}/invitations/declined`,
        null,
        this.httpOptions
      )
  }

  accept_invitation(group: any): Observable<any> {
    return this.http
      .post<any>(
        environment.apiUrl + `groups/${group}/invitations/accepted`,
        null,
        this.httpOptions
      )
  }

  getGroupInvitations(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + 'groups/invitations', this.httpOptions)
      .pipe(retry(1));
  }

  getAssignedBlocks(group: string, farm: string): Observable<any> {
    return this.http
      .get<any>(
        environment.apiUrl + `group/${group}/farms/${farm}/blocks`,
        this.httpOptions
      )
      .pipe(retry(1));
  }

  getListgrowersInGroup(group: any): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `group/${group}/growers`, this.httpOptions)
      .pipe(retry(1));
  }
  assignedBlocksToHarvester(group: string, body: any): Observable<any> {
    return this.http
      .post<any>(
        environment.apiUrl + `group/${group}/harvesters`,
        body,
        this.httpOptions
      )
      .pipe(retry(1));
  }

}
