import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { HarvestService } from 'src/app/services/harvest.service';

@Component({
  selector: 'app-harvester-management',
  templateUrl: './harvester-management.component.html',
  styleUrls: ['./harvester-management.component.css'],
  providers: [HarvestService],
})
export class HarvesterManagementComponent implements OnInit {
  brandsLists: any = [];
  modelLists: any = [];
  configLists: any = [];
  brandName: String = '';
  harvesterModelText: String = 'Add Harvester Model';
  harvesterConfigText: String = 'Add Harvester Configuration';
  harvesterBrandText: String = 'Add Harvester Brand';
  currentModel: any = {};
  currentConfig: any = {};
  currentBrand: any = {};
  newBrand: any = {};
  tabHeader: String = 'Harvester Models';
  constructor(
    private dialog: DialogService,
    private toastr: ToastrService,
    private harvesterApi: HarvestService
  ) {}

  yesNoDialog(id: string) {
    this.dialog.confirmDialog({
      title: 'Are you sure?',
      message: 'Are you sure you want to delete the harvester?',
      confirmCaption: 'Yes',
      cancelCaption: 'No',
    });
  }

  loadModels(): any {
    this.harvesterApi.getAll('harvester/models').subscribe((l) => {
      if (l.body) {
        this.modelLists = l.body;
      }
    });
  }

  loadBrands(): any {
    this.harvesterApi.getAll('harvester/brands').subscribe((l) => {
      if (l.body) {
        this.brandsLists = l.body;
      }
    });
  }

  loadConfigs(): any {

    this.harvesterApi.getAll('harvester/fan-configs').subscribe((l) => {
      if (l.body) {
        this.configLists = l.body;
      } else if (l.message) {
        this.toastr.error(l.body.message);
      } else if (l.error) {
        this.toastr.error(l.body.errorMessage);
      } else {
      }
    });
  }

  update(type: string, id: number): void {
    switch (type) {
      case 'model':
        this.harvesterModelText = 'Update Harvester Model';
        this.currentModel = this.modelLists.filter(
          (obj: any) => obj.id == Number(id)
        )[0];

        break;
      case 'brand':
        this.harvesterBrandText = 'Update Harvester Brand';
        this.newBrand = this.brandsLists.filter(
          (obj: any) => obj.id == Number(id)
        )[0];
        break;
      case 'config':
        this.harvesterConfigText = 'Update Harvester Fan Configuraion';
        this.currentConfig = this.configLists.filter(
          (obj: any) => obj.id == Number(id)
        )[0];
        break;
      default:
        break;
    }
  }

  cancel(type: string): void {
    switch (type) {
      case 'model':
        this.currentModel = {};
        this.harvesterModelText = 'Add Harvester Model';
        break;
      case 'config':
        this.currentConfig = {};
        this.harvesterConfigText = 'Add Harvester Fan Configuraion';
        break;
      default:
        break;
    }
  }

  async create(endpoint: string, type: string, data: any): Promise<any> {

    this.harvesterApi.create(endpoint, data).subscribe((h) => {
      if (h.body.id) {
        this.toastr.success(type + ' created successfully.');
        this.loadBrands();
        this.loadModels();
        this.loadConfigs();
      } else {
        this.toastr.error(h.body.error);
      }
    });
  }

  async updateData(endpoint: string, type: string, data: any): Promise<any> {
    const h = await this.harvesterApi.update(endpoint, data)
    if (h.body) {
      this.toastr.success(type + ' updated successfully.');
      this.loadBrands();
      this.loadModels();
      this.loadConfigs();
    } else {
      this.toastr.error(h.body.error);
    }
  }

  save(type: string, id?: number) {
    switch (type) {
      case 'model':
        if (id) {
          delete this.currentModel['brand_name'];

          let data = JSON.stringify(this.currentModel);
          this.updateData(
            'harvester/models/' + String(id),
            'Harvester Model',
            data
          );
        } else {
          this.create(
            'harvester/models',
            'Harvester Model',
            JSON.stringify(this.currentModel)
          );
        }
        break;
      case 'config':
        if (id) {
          delete this.currentConfig['brand_name'];
          let data = JSON.stringify(this.currentConfig);
          this.updateData(
            'harvester/fan-configs/' + String(id),
            'Harvester Fan Configuraion',
            data
          );
        } else {
          this.create(
            'harvester/fan-configs',
            'Harvester Fan Configuraion',
            JSON.stringify(this.currentConfig)
          );
        }
        break;
      case 'brand':
        this.create(
          'harvester/brands',
          'Harvester Brand',
          JSON.stringify(this.newBrand)
        );
        break;
      default:
        break;
    }
  }
  changeTabs(type: string) {
    switch (type) {
      case 'model':
        this.tabHeader = 'Harvester Models';
        break;
      case 'config':
        this.tabHeader = 'Harvester Fan Configuration';
        break;
      case 'brand':
        this.tabHeader = 'Harvester Brands';
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.loadBrands();
    this.loadModels();
    this.loadConfigs();
  }
}
