import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class BlockService {
  //base api url
  baseApiUrl = 'https://file.io';

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Bearer ' + localStorage.getItem(environment.keys.accessToken) || '',
    }),
  };

  constructor(private http: HttpClient) {}

  create(body: any, farm_id: string): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}farms/${farm_id}/blocks`,
        body,
        this.httpOptions
      )
  }

  // upload_file(file:any):Observable<any> {
  //     const formData = new FormData();
  //     formData.append("file", file, file.name);
  //     return this.http.post(this.baseApiUrl, formData)
  // }

  upload_mill_data(body: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}upload-mill-data`,
        body,
        this.httpOptions
      )
  }

  uploadfile_AWS_S3(
    { url, fields }: any,
    contenttype: any,
    file: File
  ): Observable<any> {
    let formData: FormData = new FormData();
    [
      'key',
      'AWSAccessKeyId',
      'x-amz-security-token',
      'policy',
      'signature',
    ].forEach((key) => {
      formData.append(key, fields[key]);
    });


    formData.append('file', file);

    return this.http
      .post<any>(url, formData)
  }

  create_crop(body: any, season_year: string): Observable<any> {

    return this.http
      .post<any>(
        `${environment.apiUrl}crop/seasons/${season_year}`,
        body,
        this.httpOptions
      )
  }

  update(block: any, farm: string, id: string): Observable<any> {
    return this.http
      .put<any>(
        `${environment.apiUrl}farms/${farm}/blocks/${id}`,
        block,
        this.httpOptions
      )
  }

  update_crop(block: any, season: string, id: string): Observable<any> {
    return this.http
      .put<any>(
        `${environment.apiUrl}crop/seasons/${season}/block/${id}`,
        block,
        this.httpOptions
      )
  }

  get(farm_id: string, id: string): Observable<any> {
    return this.http
      .get<any>(
        environment.apiUrl + 'farms/' + farm_id + '/blocks/' + id,
        this.httpOptions
      )
      .pipe(retry(1));
  }

  get_crop(block_id: string, season: string, farm_id: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}crop/seasons/${season}/farm/${farm_id}/block/${block_id}`,
        this.httpOptions
      )
      .pipe(retry(1));
  }

  delete(farm_id: string, id: string): Observable<any> {
    return this.http
      .delete<any>(
        environment.apiUrl + 'farms/' + farm_id + '/blocks/' + id,
        this.httpOptions
      )
      .pipe(retry(1));
  }

  getAll(farm_id: string): Observable<any> {
    return this.http
      .get<any>(
        environment.apiUrl + 'farms/' + farm_id + '/blocks',
        this.httpOptions
      )
  }

  getAllCrops(block_number: string, farmId: string): Observable<any> {
    return this.http
      .get<any>(
        environment.apiUrl + '/farm/' + farmId + '/block/' + block_number,
        this.httpOptions
      )
  }

  getAllAvailableCrops(farm_id: string, season: string): Observable<any> {
    return this.http
      .get<any>(
        environment.apiUrl +
          'crop/seasons/' +
          season +
          '/farm/' +
          farm_id +
          '/nogroups',
        this.httpOptions
      )
  }

  assign(group_id: string, payload: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}group/${group_id}/blocks`,
        payload,
        this.httpOptions
      )
  }

  remove(group_id: string, payload: any): Observable<any> {
    let _httpOptions = {
      body: payload,
      headers: this.httpOptions.headers,
    };
    return this.http
      .request(
        'delete',
        `${environment.apiUrl}group/${group_id}/blocks`,
        _httpOptions
      )
  }

  calculateBlock(payload: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/calculations/block_results_input`,
        payload,
        this.httpOptions
      )
  }

  save_results(payload: any): Observable<any> {

    return this.http
      .put<any>(
        `${environment.apiUrl}/contract-negotiation`,
        payload,
        this.httpOptions
      )
  }
}
