<div class="body-cell">
  <div class="bg-dark bg-overlap">
    <div class="content-1920">
      <div class="content-padding margin-bottom-none">
        <h2 class="welcome">Select a category to get started</h2>
      </div>
    </div>
  </div>
  <div class="content-1920">
    <div class="content-padding margin-top-none">
      <div class="columns2">
        <div>
          <a class="category-card cat-infield" href="/farms">
            <div class="cat-image"></div>
            <span class="cat-title"><span class="orange">In-field</span><br />
              <strong>Logistics</strong></span>
          </a>
          <h5 class="hidden-small">
            To be used on the day of harvesting to determine if there is
            economic benefit in changing harvester settings.
          </h5>
        </div>
        <div>
          <a class="category-card cat-groups" href="/grower-groups">
            <div class="cat-image"></div>
            <span class="cat-title"><span class="orange">Group</span><br />
              & <strong> Operational</strong><br />
              Setup</span>
          </a>
          <h5 class="hidden-small">
            This is done pre-season, but can be adjusted in-season if required.
          </h5>
        </div>
        <div>
          <a class="category-card cat-contract" href="/contract-negotiation">
            <div class="cat-image"></div>
            <span class="cat-title"><span class="orange">Comparison</span><br />
              <strong>Scenarios</strong></span>
          </a>
          <h5 class="hidden-small">
            Tool to assist growers and contractors explore comparison scenarios.
          </h5>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<div id="show_disclaimer" *ngIf="show_disclaimer" [ngClass]="{ 'overlay open': show_disclaimer }">
  <div class="modal-box">
    <button class="close" type="button" (click)="show_disclaimer = false"></button>
    <h2>Disclaimer</h2>
    <mat-card class="example-card">
      <mat-card-content>
        <p>
          In this disclaimer a reference to “we”, “us” or “our” means Sugar Research Australia Limited and our
          directors, officers,
          agents and employees.
        </p>
        <p>
          Although we do our best to present information that is correct and accurate, we make no warranties, guarantees
          or representations
          about the suitability, reliability, currency or accuracy of the information we present in Harvest Mate for any
          purposes.
        </p>
        <p>
          Subject to any terms implied by law and which cannot be excluded, we accept no responsibility for any loss,
          damage, cost or expense
          incurred by you as a result of the use of, or reliance on, any materials and information appearing in this
          tool.
        </p>
        <p>
          You, the user, accept sole responsibility and risk associated with the use and results of the information
          appearing in this report
          and you agree that we will not be liable for any loss or damage whatsoever (including through negligence)
          arising out of, or in
          connection with the use of this tool.
        </p>
      </mat-card-content>
    </mat-card>
    <br>
    <br>
    <br>
    <h2 style="color: rgb(255, 0, 0) !important">
      Warning
    </h2>
    <mat-card class="example-card">
      <mat-card-content>
        <p>
          Results of Harvest Mate may not account for all field variability and therefore results may vary within
          blocks.
          This may be for many reasons including (for example) SRA being unaware of other matters relevant to individual
          crops,
          the analysis of unrepresentative samples or the influence of environmental, managerial or other factors on
          production.
          Harvest Mate does not account for impacts on ratoonability
        </p>
      </mat-card-content>
    </mat-card>


    <div class="fieldwrap align-right">
      <a class="btn__secondary btn-login" (click)="decline_disclaimer()">Decline</a>
      <button class="btn-save" type="submit" (click)="accept_disclaimer()">
        Accept
      </button>
    </div>
  </div>
</div>