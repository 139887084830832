<div id="login">
  <div class="login-inner text-white scrollbar">
    <div class="content-padding">
      <div class="alerts">
        <div class="alert hidden alert-error">Error Message</div>
      </div>

      <div class="login-header">
        <img src="images/harvest_mate_logo_white.png" />
      </div>

      <div class="login-box" style="height: fit-content !important">
        <div id="form-login" class="login-form form-open">
          <form>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Email</label>
              <input id="email" name="email" #email="ngModel" [(ngModel)]="user.email" type="email" tabindex="1"
                autofocus="true" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Password</label>
              <a class="btn-forgot btn-login" href="#form-forgot" title="Forgot Password?"
                routerLink="/forgotPassword">?</a>
              <input type="password" id="password" name="password" #password="ngModel" [(ngModel)]="user.password"
                tabindex="2" />
            </div>
            <div class="fieldwrap fieldwrap_type_submit">
              <button type="submit" (click)="signIn()">Login</button>
              <button class="btn__secondary btn-login" routerLink="/signUp">
                Signup
              </button>
            </div>
          </form>
        </div>

        <div id="form-signup" class="login-form">
          <h2 class="h4">Create an Account</h2>
          <form>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">First Name</label>
              <input type="text" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Last Name</label>
              <input type="text" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Email</label>
              <input type="text" />
            </div>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Password</label>
              <input type="password" />
            </div>
            <div class="fieldwrap fieldwrap_type_submit">
              <button type="submit">Signup</button>
              <a class="btn__secondary btn-login" href="#form-login">Back</a>
            </div>
          </form>
        </div>

        <div id="form-forgot" class="login-form">
          <h2 class="h4">Reset Password</h2>
          <form>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Email</label>
              <input type="text" />
            </div>
            <div class="fieldwrap fieldwrap_type_submit">
              <button type="submit">Reset Password</button>
              <a class="btn__secondary btn-login" href="#form-login">Back</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>