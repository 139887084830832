import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signIn',
    pathMatch: 'full',
  },
  {
    canActivate: [AdminGuard],
    path: 'admin',
    children: [
      {
        path: 'resetAdmPassword',
        component: AdminResetPwdComponent,
      },
      {
        path: 'userManagement',
        component: UserManagementComponent,
      },
      {
        path: 'harvesterManagement',
        component: HarvesterManagementComponent,
      },
      {
        path: 'regionManagement',
        component: RegionManagementComponent,
      },
      {
        path: 'cropManagement',
        component: CropManagementComponent,
      },
      {
        path: 'repairMaintenance',
        component: RepairAndMaintenanceComponent,
      },
      {
        path: 'seasonManagement',
        component: SeasonManagementComponent,
      },
    ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'signIn',
    component: SignInComponent,
  },
  {
    path: 'admSignin',
    component: AdminSignInComponent,
  },

  {
    path: 'signUp',
    component: SignUpComponent,
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'confirmSignUp',
    component: ConfirmSignUpComponent,
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'mill-season-detail',
    component: MillCopySeasonComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'harvesters',
    component: HarvestListComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'harvester',
    component: HarvestDetailsComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'harvester-season',
    component: HarvesterSeasonComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'harvester-copy-season',
    component: HarvesterCopySeasonComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'farms',
    component: FarmListComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'farm',
    component: FarmDetailsComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'add-bulk-block',
    component: BlockBulkDetailsComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'block-details',
    component: BlockDetailsComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'crop-details',
    component: CropDetailsComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'contractor-groups',
    component: ContractorGroupsListComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'contract-negotiation',
    component: ContractNegotiationComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'contractor-group',
    component: ContractorGroupDetailComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'fixed-cost',
    component: FixedCostComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'fixed-cost',
    component: FixedCostComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'group-invitations',
    component: GrowerInvitationsListComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'grower-groups',
    component: GrowerGroupsListComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'link-group-block',
    component: GroupBlocksLinkComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'contract-negotiation-grower',
    component: ContractNegotiationGrowerComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'group-grower-block',
    component: GroupGrowerBlockComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [AuthGuardGuard],
  },
  {
    path: '**',
    redirectTo: 'signIn',
  },
];

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about/about.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmSignUpComponent } from './confirm-sign-up/confirm-sign-up.component';

import { AdminResetPwdComponent } from './admin-components/admin-reset-pwd/admin-reset-pwd.component';
import { AdminSignInComponent } from './admin-components/admin-sign-in/admin-sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserManagementComponent } from './admin-components/user-management/user-management.component';
import { HarvesterManagementComponent } from './admin-components/harvester-management/harvester-management.component';
import { MillCopySeasonComponent } from './admin-components/mill-copy-season/mill-copy-season.component'

import { HarvestListComponent } from './contractor-components/harvest-list/harvest-list.component';
import { HarvestDetailsComponent } from './contractor-components/harvest-details/harvest-details.component';

import { FarmListComponent } from './grower-components/farm-list/farm-list.component';
import { FarmDetailsComponent } from './grower-components/farm-details/farm-details.component';
import { BlockBulkDetailsComponent } from './grower-components/block-bulk-details/block-bulk-details.component';

import { BlockDetailsComponent } from './grower-components/block-details/block-details.component';
import { CropDetailsComponent } from './grower-components/crop-details/crop-details.component';

import { ContractorGroupsListComponent } from './contractor-components/contractor-groups-list/contractor-groups-list.component';
import { ContractNegotiationComponent } from './contractor-components/contract-negotiation/contract-negotiation.component';
import { ContractNegotiationGrowerComponent } from './contractor-components/contract-negotiation-grower/contract-negotiation-grower.component';
import { GroupGrowerBlockComponent } from './grower-components/group-grower-block/group-grower-block.component';

import { ContractorGroupDetailComponent } from './contractor-components/contractor-group-detail/contractor-group-detail.component';

import { GrowerInvitationsListComponent } from './grower-components/grower-invitations-list/grower-invitations-list.component';

import { GrowerGroupsListComponent } from './grower-components/grower-groups-list/grower-groups-list.component';
import { GroupBlocksLinkComponent } from './grower-components/group-blocks-link/group-blocks-link.component';
import { CognitoService } from './services/cognito.service';
import { AuthGuardGuard } from './auth/auth-guard.guard';
import { FixedCostComponent } from './contractor-components/fixed-cost/fixed-cost.component';
import { RegionManagementComponent } from './admin-components/region-management/region-management.component';
import { CropManagementComponent } from './admin-components/crop-management/crop-management.component';
import { RepairAndMaintenanceComponent } from './admin-components/repair-and-maintenance/repair-and-maintenance.component';

import { HarvesterSeasonComponent } from './contractor-components/harvester-season/harvester-season.component';
import { HarvesterCopySeasonComponent } from './contractor-components/harvester-copy-season/harvester-copy-season.component';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { SeasonManagementComponent } from './admin-components/season-management/season-management.component';
import { AdminGuard } from './guards/admin.guard';

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CognitoService],
})
export class AppRoutingModule { }
