<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>My Farms</h2>
    <button class="btn-add" type="submit" routerLink="/farm">Add Farm</button>
    <div class="columns-wrap">
      <div class="columns12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Farm Name</th>
              <th scope="col">Region</th>
              <th scope="col">Mill</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of gridData">
              <td>{{ row.name }}</td>
              <td>{{ row.mill_details.region_details.name }}</td>
              <td>{{ row.mill_details.name }}</td>
              <td>
                <a class="btn-small" routerLink="/farm" routerLinkActive="active"
                  [queryParams]="{ id: row.ext_id }">Details</a>
                <button class="btn-small btn-left-space" type="button" (click)="yesNoDialog(row.ext_id)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>