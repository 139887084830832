
<div class="content-1920">
    <div class="content-padding margin-top-small">
        <h2>harvester season details</h2>
        
        <div class="columns-wrap">
            <fieldset>
                <div class="columns2">
                    <div class="fieldwrap label-inline">
                        <label>Season</label>
                        <select
                          id="harvesterSeason"
                          name="harvesterSeason"
                          #harvesterSeason="ngModel"
                          [(ngModel)]="selectedHarvesterSeason.season"
                          (change)="onHarvesterSeasonChange(harvesterSeason.value)"
                        >
                        <option *ngFor="let e of seasonList" value="{{ e }}">
                            {{ e }}
                        </option>
                        </select>
                    </div>
                    <div class="fieldwrap label-inline">
                        <label>Chopper Drum Blades (total)</label>
                        <select
                          id="chopper_drum_blades_total"
                          name="chopper_drum_blades_total"
                          #chopper_drum_blades_total="ngModel"
                          [(ngModel)]="selectedHarvesterSeason.chopper_drum_blades_total"
                        >
                        <option *ngFor="let e of chopperBladesOptions" value="{{ e }}">
                            {{ e }}
                        </option>
                        </select>
                    </div>
                </div>
            </fieldset>



            <h2>Haulout</h2>
            <fieldset>
              <div class="columns-wrap">
                <div class="columns2">
                  <div class="fieldwrap label-inline">
                    <label>No. of Haulouts available (max 1 spare)</label>
                    <app-number-input
                      [dataPost]="dataPost.unit"
                      [(numberInput)]="selectedHarvesterSeason.haulouts_available"
                      [default]="2"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label>Average Haulout Bin Weight</label>
                    <app-number-input
                      [dataPost]="dataPost.t"
                      [(numberInput)]="selectedHarvesterSeason.average_bin_capacity"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label>Haulout Speed Loaded</label>
                    <app-number-input
                      [dataPost]="dataPost.kmh"
                      [(numberInput)]="selectedHarvesterSeason.haulout_speed_loaded"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label>Haulout Fuel Use loaded</label>
                    <app-number-input
                      [dataPost]="dataPost.ltrh"
                      [(numberInput)]="selectedHarvesterSeason.haulout_fuel_use_loaded"            
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label>Haulout Speed Empty</label>
                    <app-number-input
                      [dataPost]="dataPost.kmh"
                      [(numberInput)]="selectedHarvesterSeason.haulout_speed_empty"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label>Haulout Fuel Use Empty</label>
                    <app-number-input
                      [dataPost]="dataPost.ltrh"
                      [(numberInput)]="selectedHarvesterSeason.haulout_fuel_use_empty"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label>Time required to empty haulout bin</label>
                    <app-number-input
                      [dataPost]="dataPost.sec"
                      [(numberInput)]="selectedHarvesterSeason.time_required_to_empty_haulout_bin "
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <div class="toggle">
                      <input
                        id="haulouts_includes_spare"
                        type="checkbox"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="selectedHarvesterSeason.haulouts_includes_spare"
                      />
                      <label for="haulouts_includes_spare"
                        >Do the ({{ selectedHarvesterSeason.haulouts_available }})
                        haulouts available include a spare?</label
                      >
                    </div>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label
                      >Estimated average waiting time<span class="small"
                        >(min per day)</span
                      ></label
                    >
                    <app-number-input
                      [dataPost]="dataPost.min"
                      [(numberInput)]="selectedHarvesterSeason.average_siding_waiting_time"
                      [default]="0"
                      [min]="0"                  
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label
                      >Estimated average harvester moving time<span class="small"
                        >(min per day)</span
                      ></label
                    >
                    <app-number-input
                      [dataPost]="dataPost.min"
                      [(numberInput)]="selectedHarvesterSeason.average_moving_time"
                      [default]="0"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label>Estimated average service/maintenance time</label>
                    <app-number-input
                      [dataPost]="dataPost.min"
                      [(numberInput)]="selectedHarvesterSeason.average_servicing_time"
                      [default]="0"
                    >
                    </app-number-input>
                  </div>
                </div>
              </div>
            </fieldset>
      
            <h2>Labour Costs</h2>
            <fieldset>
              <div class="fieldwrap label-inline">
                <label
                  >Cost of harvester driver<span class="small"
                    >(ordinary wage, $ per tonne or per hour)</span
                  ></label
                >
                <app-number-input
                  [dataPre]="dataPre.dollar"
                  [(numberInput)]="selectedHarvesterSeason.harvester_driver_wage"
                  [default]="0"
                >
                </app-number-input>
                <select
                  id="harvester_driver_compensation_type"
                  name="harvester_driver_compensation_type"
                  #harvester_driver_compensation_type="ngModel"
                  [(ngModel)]="selectedHarvesterSeason.harvester_driver_compensation_type"
                >
                  <option
                    *ngFor="let e of compensation_types"
                    value="{{ e.value }}"
                  >
                    {{ e.text }}
                  </option>
                </select>
              </div>
    
              <div class="fieldwrap label-inline">
                <label
                  >Cost of haulout driver<span class="small"
                    >(ordinary wage, $ per tonne or per hour)</span
                  ></label
                >
                <app-number-input
                  [dataPre]="dataPre.dollar"
                  [(numberInput)]="selectedHarvesterSeason.haulout_driver_wage"
                  [default]="0"
                >
                </app-number-input>
                <select
                  id="haulout_drivers_compensation_type"
                  name="haulout_drivers_compensation_type"
                  #haulout_drivers_compensation_type="ngModel"
                  [(ngModel)]="selectedHarvesterSeason.haulout_drivers_compensation_type"
                >
                  <option
                    *ngFor="let e of compensation_types"
                    value="{{ e.value }}"
                  >
                    {{ e.text }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <div class="toggle">
                  <input
                    id="wages_include_super"
                    name="wages_include_super"
                    type="checkbox"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedHarvesterSeason.wages_include_super"
                  />
                  <label for="wages_include_super"
                    >Superannuation<span class="small"
                      >(select if not included in ordinary wage)</span
                    ></label
                  >
                </div>
              </div>
              <div class="fieldwrap label-inline">
                <div class="toggle">
                  <input
                    id="wages_include_annual_leave_loading"
                    name="wages_include_annual_leave_loading"
                    type="checkbox"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="selectedHarvesterSeason.wages_include_annual_leave_loading"
                  />
                  <label for="wages_include_annual_leave_loading"
                    >Annual leave Loading<span class="small"
                      >(select if not included in ordinary wage)</span
                    ></label
                  >
                </div>
              </div>
              <label
                ><small
                  >The following penalty rates exclude mill allowances paid to
                  staff</small
                ></label
              >
              <div class="columns-wrap">
                <div class="columns2">
                  <div class="fieldwrap label-inline">
                    <label
                      >Rate for Saturdays worked<span class="small"
                        >(% of ordinary wage)</span
                      ></label
                    >
                    <app-number-input
                      [dataPost]="dataPost.percent"
                      [(numberInput)]="selectedHarvesterSeason.saturday_rate"
                      [default]="100"
                      [min]="0"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label
                      >Rate for Sundays worked<span class="small"
                        >(% of ordinary wage)</span
                      ></label
                    >
                    <app-number-input
                      [dataPost]="dataPost.percent"
                      [(numberInput)]="selectedHarvesterSeason.sunday_rate"
                      [default]="100"
                      [min]="0"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label
                      >Rate for Holidays worked<span class="small"
                        >(% of ordinary wage)</span
                      ></label
                    >
                    <app-number-input
                      [dataPost]="dataPost.percent"
                      [(numberInput)]="selectedHarvesterSeason.holiday_rate"
                      [default]="100"
                      [min]="0"
                    >
                    </app-number-input>
                  </div>
    
                  <div class="fieldwrap label-inline">
                    <label
                      >Rate for RDOs worked<span class="small"
                        >(% of ordinary wage)</span
                      ></label
                    >
                    <app-number-input
                      [dataPost]="dataPost.percent"
                      [(numberInput)]="selectedHarvesterSeason.rdo_rate"
                      [default]="100"
                      [min]="0"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label
                      >Rate for Extra Time<span class="small"
                        >(% of ordinary wage)</span
                      ></label
                    >
                    <app-number-input
                      [dataPost]="dataPost.percent"
                      [(numberInput)]="selectedHarvesterSeason.extra_time_rate"
                      [default]="100"
                      [min]="0"
                    >
                    </app-number-input>
                  </div>
                  <div class="fieldwrap label-inline">
                    <label
                      >Pre-season Labour/Maintenance Cost<span class="small"
                        >($/hour)</span
                      ></label
                    >
                    <app-number-input
                      [dataPre]="dataPre.dollar"
                      [(numberInput)]="selectedHarvesterSeason.preseason_labour_and_maintenance_costs"
                      [default]="0"
                      [min]="0"
                    >
                    </app-number-input>
                  </div>
                </div>
              </div>
            </fieldset>


            <h2>Harvester Depreciation</h2>
            <fieldset>
              <div class="columns2">
                <div class="fieldwrap label-inline">
                  <label>Harvester Purchase Price</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.harvester_current_new_value"
                    
                    [min]="0"
                  >
                </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label
                    >Harvester Life<span class="small"
                      >(total hours, from purchase to sale)</span
                    ></label
                  >
                  <app-number-input
                    [dataPost]="dataPost.hrs"
                    [(numberInput)]="selectedHarvesterSeason.harvester_life"
                    
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Harvester Salvage Value</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.harvester_current_salvage_value"
                    
                  >
                  </app-number-input>
                </div>
              </div>
            </fieldset>


            <h2>Haulout Depreciation</h2>
            <fieldset>
              <div class="columns2">
                <div class="fieldwrap label-inline">
                  <label>Average Haulout Tractor Purchase Price</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.haulout_tractor_current_new_value"             
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Average Haulout Trailer Purchase Price</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.haulout_trailer_current_new_value"              
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label
                    >Average Life of Haulout Tractors<span class="small"
                      >(total hours, from purchase to sale)</span
                    ></label
                  >
                  <app-number-input
                    [dataPost]="dataPost.hrs"
                    [(numberInput)]="selectedHarvesterSeason.haulout_tractor_life"                  
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label
                    >Average Life of Haulout Trailers
                    <span class="small"
                      >(total hours, from purchase to sale)</span
                    ></label
                  >
                  <app-number-input
                    [dataPost]="dataPost.hrs"
                    [(numberInput)]="selectedHarvesterSeason.haulout_trailer_life"                   
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Average Salvage Value of Haulout Tractors</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.haulout_tractor_current_salvage_value"                 
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Average Salvage Value of Haulout Trailers</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.haulout_trailer_current_salvage_value"            
                  >
                  </app-number-input>
                </div>
              </div>
            </fieldset>
      
            
            <h2>Spare Haulout Depreciation</h2>
            <fieldset>
              <div class="fieldwrap label-inline">
                <div class="toggle">
                  <input
                    id="asabove"
                    data-reveal="hauloutdep2"
                    type="checkbox"
                    [(ngModel)]="asabove"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <label for="asabove">Same As Above</label>
                </div>
              </div>
              <div id="hauloutdep2">
                <div class="fieldwrap label-inline">
                  <label>Average Haulout Tractor Purchase Price</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.spare_haulout_tractor_current_new_value"             
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label
                    >Average Life of Haulout Tractors<span class="small"
                      >(total hours, from purchase to sale)</span
                    ></label
                  >
                  <app-number-input
                    [dataPost]="dataPost.hrs"
                    [(numberInput)]="selectedHarvesterSeason.spare_haulout_tractor_life"              
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Average Salvage Value of Haulout Tractors</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.spare_haulout_tractor_current_salvage_value"
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Average Haulout Trailer Purchase Price</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.spare_haulout_trailer_current_new_value"       
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label
                    >Average Life of Haulout Trailers
                    <span class="small"
                      >(total hours, from purchase to sale)</span
                    ></label
                  >
                  <app-number-input
                    [dataPost]="dataPost.hrs"
                    [(numberInput)]="selectedHarvesterSeason.spare_haulout_trailer_life"              
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Average Salvage Value of Haulout Trailers</label>
                  <app-number-input
                    [dataPre]="dataPre.dollar"
                    [(numberInput)]="selectedHarvesterSeason.spare_haulout_trailer_current_salvage_value"
                  >
                  </app-number-input>
                </div>
              </div>
            </fieldset>

            
            <h2>Interest</h2>
            <fieldset>
              <div class="fieldwrap label-inline cost-counter">
                <label>Average Interest Paid on Harvester/Haulouts</label>
                <div class="reset" data-pre="%">
                  <input
                    type="number"
                    data-pre="$"
                    [(ngModel)]="selectedHarvesterSeason.average_interest_paid"
                    [placeholder]="0"
                  />
                </div>
                </div> 
            </fieldset>

            
            <h2>Additional Costs</h2>
            <fieldset>
              <div class="fieldwrap label-inline cost-counter">
                <label
                  >Repairs & Maintenance Cost Counter
                  <span class="small"
                    >Current Repairs & Maintenance Cost Counter (adjust if
                    required)</span
                  ></label
                >
                <div class="reset" data-pre="$">
                  <input
                    type="number"
                    id="repair_maintenance_cost"
                    name="repair_maintenance_cost"
                    #repair_maintenance_cost="ngModel"
                    [(ngModel)]="selectedHarvesterSeason.repair_maintenance_cost"
                  />                  
                </div>
              </div>
              <div class="fieldwrap label-inline cost-counter">
                <label
                  >Fixed Cost Per Harvester<span class="small"
                    >Update with specific value if required </span
                  ></label
                >
                <div class="reset" data-pre="$">
                  <input
                    type="number"
                    id="fixedCost"
                    name="fixedCost"
                    #fixedCost="ngModel"
                    [(ngModel)]="selectedHarvesterSeason.fixed_costs_per_harvester"
                  />
                  <button type="button" class="btn-reset" (click)="selectedHarvesterSeason.fixed_costs_per_harvester = this.fixedCosts"></button>
                </div>
              </div>
              <div class="fieldwrap label-inline cost-counter">
                <label
                  >Fuel Price excl. GST
                  <span class="small">(average $/litre before rebate)</span></label
                >
                <select
                  id="selectedMillFuelPrice"
                  name="selectedMillFuelPrice"
                  #selectedMillFuelPrice
                  (change)="this.selectedHarvesterSeason.fuel_price = selectedMillFuelPrice.value"
                >
                  <option
                    *ngFor="let e of millSeasonList;let i = index"
                    [attr.data-index]="i"
                    value="{{ e.fuel_price }}"
                  >
                    <div *ngIf="!i">
                      {{ e.fuel_price }} 
                    </div>
                    <div *ngIf="i > 0">
                      {{ e.mill_details.name }}: ({{ e.season }}) ${{ e.fuel_price }}
                    </div>
                  </option>
                </select>
                <div class="reset" data-pre="$">
                  <input
                    type="number"
                    id="fuelPrice"
                    name="fuelPrice"
                    #fuelPrice="ngModel"
                    [(ngModel)]="selectedHarvesterSeason.fuel_price"
                  />                  
                </div>
              </div>
            </fieldset>


            <div class="fieldwrap fieldwrap-type-submit">
                <a
                  class="btn__secondary btn-back"
                  routerLink="/harvester"
                  routerLinkActive="active"
                  [queryParams]="{ id: harvester_id }"
                  >Back</a
                >

                <button 
                  class="btn-save" 
                  type="submit"
                  (click)="save(selectedHarvesterSeason.harvester_id)"
                >
                  Save
                </button>

                <a 
                  class="btn__secondary" 
                  routerLink="/fixed-cost"
                  *ngIf="selectedHarvesterSeason.harvester_id"
                  (click)="saveTemporarily()"
                  [queryParams]="{ 
                    id: harvester_id,
                    season: season,
                    route_link: 'harvester-copy-season'
                  }"
                >Fixed Cost Calculator
                </a>
            </div>
      </div>

</div>
