<form>
  <div class="content-1920">
    <div class="content-padding margin-top-small">
      <div class="main">
        <h2 id="group_blocks_title"></h2>

        <div class="fieldwrap">
          <label>Farms</label>
          <select
            id="farm_name"
            name="farm_name"
            required
            (change)="onSelectFarm($event.target)"
          >
            <option>Select Farm</option>
            <option *ngFor="let f of availableFarms" value="{{ f.id }}">
              {{ f.name }}
            </option>
          </select>
        </div>

        <div cdkDropListGroup class="fieldwrap columns3">
          <div class="farm_blocks">
            <label>Farm Blocks</label>
            <div
              cdkDropList
              id="availableBlocksList"
              cdkDropListSortingDisabled
              [cdkDropListData]="availableBlocks"
              class="available-blocks-list"
              (cdkDropListDropped)="drop($event)"
            >
              <div
                class="blocks-box"
                *ngFor="let item of availableBlocks"
                cdkDrag
              >
                {{ item.block_number }}
              </div>
            </div>
          </div>
          <div class="selected_blocks">
            <label>Selected Blocks</label>
            <div
              cdkDropList
              id="assignedBlocksList"
              cdkDropListSortingDisabled
              [cdkDropListData]="assignedBlocks"
              class="assigned-blocks-list"
              (cdkDropListDropped)="drop($event)"
            >
              <div
                class="blocks-box"
                *ngFor="let item of assignedBlocks"
                cdkDrag
              >
                <table style="width: 100%">
                  <tr>
                    <td *ngIf="item.selected_result && !item.selected_result.standard">
                      ({{rankings[item.selected_result.ranking]}})
                    </td>
                    <td *ngIf="item.selected_result && item.selected_result.standard">
                      Standard Practice
                    </td>
                    
                    <td>Block No: {{ item.block_number }}</td>
                    <td>Area: {{ item.area }} (ha)</td>
                    <!-- <td>
                      Representative Area (ha):
                      {{ item.block_representative_area | number : "1.2-2" }}
                      <a
                        class="btn__secondary btn-calculator"
                        (click)="showAllowance(item.block_number)"
                        >Allowance Calculator</a
                      >
                    </td> -->
                  </tr>

                  <tr *ngIf="item.selected_result">
                    <td colspan="3">
                      <table style="width: 100%">
                        <div *ngIf="item.selected_result.standard">
                          <tr>
                            <td>
                              Yield (t/ha):
                              {{
                                item.selected_result.block_agronomic_results.yield_std
                                  | number : "1.2-2"
                              }}
                            </td>
                              <td>
                                Fan Speed (RPM):
                                {{
                                  item.selected_result.block_agronomic_results
                                    .fan_speed_std | number : "1.0-0"
                                }}
                              </td>
                              <td>
                                Ground Speed (km/hr):
                                {{
                                  item.selected_result.block_agronomic_results
                                    .ground_speed_std | number : "1.1-1"
                                }}
                              </td>
                              <td>
                                Flow Rate (t/hr):
                                {{
                                  item.selected_result.block_agronomic_results
                                    .flow_rate_std | number : "1.1-1"
                                }}
                              </td>
                              <td>
                                Hours per day (total):
                                {{
                                  item.selected_result.block_agronomic_results
                                    .hours_per_day_total_std | number : "1.0-0"
                                }}
                              </td>
                              <td *ngIf="item.results">
                                <button 
                                    class="btn__tertiary" 
                                    (click)="
                                    block = item.block_number;
                                    block_results = item.results; 
                                    dialog_open = true
                                  ">
                                  Show More
                                </button>
                              </td>
                            </tr>
                        </div>
                        <div *ngIf="!item.selected_result.standard" >
                          <tr>
                            <td>Yield (t/ha): {{item.selected_result.block_agronomic_results.pred_yield}}</td>
                            <td>Fan Speed(RPM): {{item.selected_result.block_agronomic_results.fan_speed_pred}}</td>
                            <td>Ground Speed (km/hr): {{item.selected_result.block_agronomic_results.ground_speed_pred}} </td>
                            <td>Flow Rate (t/hr): {{item.selected_result.block_agronomic_results.flow_rate_pred}} </td>
                            <td>Hours per day (total): {{item.selected_result.block_agronomic_results.hours_per_day_total_pred}}</td>
                          </tr>
                          <tr>
                            <td>Cost Change ($/t): {{item.selected_result.block_economic_results_grower.cost_change_t}}</td>
                            <td>Cost Change ($/ha): {{item.selected_result.block_economic_results_grower.cost_change_ha}} </td>
                            <td>Cost Change ($/hr): {{item.selected_result.block_economic_results_grower.cost_change_hr}}</td>
                            <td>Grower Net Benefit ($/ha): {{item.selected_result.block_economic_results_grower.net_benefit}}</td>
                            <td *ngIf="item.results">
                              <button 
                              class="btn__tertiary" 
                              (click)="
                              block = item.block_number;
                              block_results = item.results; 
                              dialog_open = true
                            ">
                            Show More
                          </button>
                            </td>
                          </tr>
                        </div>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <label *ngIf="!is_processing_calculations"
              >Total Representative Area:
              {{ total_representative_area | number : "1.2-2" }} (ha)</label
            >
            <label *ngIf="is_processing_calculations"
              >Running calculations...</label
            >
          </div>
          <div class="calculate_average">
            <label>Average Calculations</label>
            <a class="btn__secondary" (click)="calculate_average()"
              >Calculate Average</a
            >
          </div>
        </div>
        <div class="fieldwrap fieldwrap-type-submit align-right">
          <a
            class="btn__secondary btn-back"
            routerLink="/contract-negotiation-grower"
            [queryParams]="{ id: group_id, name: group }"
            >Back</a
          >
        </div>
      </div>
      <div id="calculator" [ngClass]="{ 'overlay open': allowance_open }">
        <div class="modal-box" style="width: 50%;">
          <button
            class="close"
            type="button"
            (click)="allowance_open = false"
          ></button>
          <div class="modal-header">
            <h2>Allowance <strong>Calculator</strong></h2>
            <div class="fieldwrap label-inline">
              This is only used if representative block allowances have not been added to the Infield Logistics Section.
            </div>
            <p class="h5">(Harvester / Haulage / Night / Sunday)</p>
          </div>
          <form>
            <fieldset>
              <div class="fieldwrap label-inline">
                <label
                  >Total hectares considered for representive block/area</label
                >
                <app-number-input
                  [(numberInput)]="block_allowance.total_repesentative_area"
                  (numberInputChange)="
                    countAllowanceChangedHandler(
                      $event,
                      'total_repesentative_area'
                    )
                  "
                >
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Total tonnage (based on block standard yield)</label>
                <app-number-input
                  [numberInput]="block_allowance.delivered_yield_estimate"
                  (numberInputChange)="
                    countAllowanceChangedHandler(
                      $event,
                      'delivered_yield_estimate'
                    )
                  "
                >
                </app-number-input>
              </div>

              <div class="field-group">
                <div class="fieldwrap label-inline">
                  <label>$/t Harvest Allowance (not paid to drivers)</label>
                  <app-number-input
                    [numberInput]="block_allowance.harvest_allowance"
                    (numberInputChange)="
                      countAllowanceChangedHandler($event, 'harvest_allowance')
                    "
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Harvest Allowance - Tonnes on which it is paid</label>
                  <app-number-input
                    [dataPost]="dataPost.t"
                    [numberInput]="block_allowance.harvest_allowance_tonnes"
                    (numberInputChange)="
                      countAllowanceChangedHandler(
                        $event,
                        'harvest_allowance_tonnes'
                      )
                    "
                  >
                  </app-number-input>
                </div>

                <div class="fieldwrap label-inline">
                  <label>$/t Haulage Allowance (not paid to drivers)</label>
                  <app-number-input
                    [numberInput]="block_allowance.haulage_allowance"
                    (numberInputChange)="
                      countAllowanceChangedHandler($event, 'haulage_allowance')
                    "
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Haulage Allowance - Tonnes on which it is paid</label>
                  <app-number-input
                    [numberInput]="block_allowance.haulage_allowance_tonnes"
                    (numberInputChange)="
                      countAllowanceChangedHandler($event, 'haulage_allowance_tonnes')
                    "
                  >
                  </app-number-input>
                </div>

                <div class="fieldwrap label-inline">
                  <label>$/t Night Allowance (not paid to drivers)</label>
                  <app-number-input
                    [numberInput]="block_allowance.night_allowance"
                    (numberInputChange)="
                      countAllowanceChangedHandler($event, 'night_allowance')
                    "
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Night Allowance - Tonnes on which it is paid</label>
                  <app-number-input
                    [numberInput]="block_allowance.night_allowance_tonnes"
                    (numberInputChange)="
                      countAllowanceChangedHandler(
                        $event,
                        'night_allowance_tonnes'
                      )
                    "
                  >
                  </app-number-input>
                </div>

                <div class="fieldwrap label-inline">
                  <label>$/t Sunday Allowance (not paid to drivers)</label>
                  <app-number-input
                    [numberInput]="block_allowance.sunday_allowance"
                    (numberInputChange)="
                      countAllowanceChangedHandler($event, 'sunday_allowance')
                    "
                  >
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline">
                  <label>Sunday Allowance - Tonnes on which it is paid</label>
                  <app-number-input
                    [numberInput]="block_allowance.sunday_allowance_tonnes"
                    (numberInputChange)="
                      countAllowanceChangedHandler($event, 'sunday_allowance_tonnes')
                    "
                  >
                  </app-number-input>
                </div>
              </div>

              <div class="total-wrap h6">
                <span
                  >Average allowance per tonne for representative
                  block/area</span
                >
                <div class="total">
                  <span>{{
                    block_allowance.total_average_allowance_per_tone
                      | number : "1.2-2"
                  }}</span>
                </div>
              </div>

              <div class="fieldwrap fieldwrap-type-submit">
                <button
                  class="btn__secondary close-modal"
                  type="button"
                  (click)="allowance_open = false"
                >
                  Close
                </button>
                <button
                  class="btn-save"
                  type="submit"
                  (click)="saveBlockAllowance()"
                >
                  Save
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div id="calcularions" [ngClass]="{ 'overlay open': dialog_open }">
        <div class="modal-box" style="width: 80%">
          <button
            class="close"
            type="button"
            (click)="dialog_open = false"
          ></button>
          <div class="modal-header">
            <h2>Block Calculation Results</h2>
            <h4>Select A Practice </h4>
          </div>
          <form>
            <fieldset>
              <div class="flex-container">
                  <div class="frozen-box">
                      <table>
                          <tr>
                              <td colspan="2"><strong>Measurements</strong></td>
                          </tr>
                          <tr>
                              <td> <strong>Grower Net benefit: ($/ha)</strong></td>
                          </tr>
                          <tr>
                              <td>Yield (t/ha):</td>
                          </tr>
                          <tr>
                              <td>Yield Change (t/ha):</td>
                          </tr>
                          <tr>
                              <td>CCS:</td>
                          </tr>
                          <tr>
                              <td>Topping:</td>
                          </tr>
                          <tr>
                              <td>Ground Speed (km/hr):</td>
                          </tr>
                          <tr>
                              <td>Fan Speed (RPM):</td>
                          </tr>
                          <tr>
                              <td>Hours per day (total):</td>
                          </tr>
                          <tr>
                              <td>Hours per day (cutting):</td>
                          </tr>
                          <tr>
                              <td colspan="2">Total area harvested (ha):</td>
                          </tr>
                          <tr>
                              <td>Flow Rate (t/hr):</td>
                          </tr>
                          <tr>
                              <td>Bin Weight (t/bin):</td>
                          </tr>
                          <tr>
                            <td>Cost Change ($/t):</td>
                          </tr>
                          <tr>
                              <td>Cost Change ($/hr):</td>
                          </tr>
                          <tr>
                              <td>Cost Change ($/ha):</td>
                          </tr>
                      </table>
                  </div>
                  <div class="frozen-box"  *ngIf="block_results[0]">
                      <table 
                      (click)="selectThisResult(block_results[0], 0)"
                      style="cursor: pointer"
                      >
                          <tr>
                              <td colspan="2"><strong>Standard Practice</strong></td>
                          </tr>
                          <tr><td>N/A</td></tr>
                          <tr>
                              <td >{{block_results[0].block_agronomic_results.yield_std
                                  | number : "1.1-1"}}
                              </td>
                          </tr>
                          <tr><td>N/A</td></tr>
                          <tr>
                              <td>{{
                                  block_results[0].block_agronomic_results.ccs_std*100
                                  | number : "1.2-2"
                                  }}</td>
                          </tr>
                          <tr>
                              <td> {{ payload.std_topping }}</td>
                          <tr>
                              <td> {{
                                  block_results[0].block_agronomic_results.ground_speed_std
                                  | number : "1.1-1"
                                  }}</td>
                          </tr>
                          <tr>
                              <td>{{
                                  block_results[0].block_agronomic_results.fan_speed_std
                                  | number : "1.0-0"
                                  }}</td>
                          </tr>
                          <tr>
                              <td>{{
                                  block_results[0].block_agronomic_results.hours_per_day_total_std
                                  | number : "1.1-1"
                                  }}</td>
                          </tr>
                          <tr>
                              <td>{{block_results[0].block_agronomic_results.hours_per_day_cutting_std
                                  | number : "1.1-1"}}</td>
                          </tr>
                          <tr>
                              <td>{{
                                  block_results[0].block_agronomic_results.total_area_harvested
                                  | number : "1.0-0"
                                  }}</td>
                          </tr>
                          <tr>
                              <td>{{
                                  block_results[0].block_agronomic_results.flow_rate_std
                                  | number : "1.1-1"
                                  }}</td>
                          </tr>
                          <tr>
                              <td> {{
                                  block_results[0].block_agronomic_results.bin_weight_std
                                  | number : "1.1-1"
                                  }}</td>
                          </tr>
                          <tr><td>N/A</td></tr>
                          <tr><td>N/A</td></tr>
                          <tr><td>N/A</td></tr>
                      </table>
                  </div>

                  <div *ngIf="block_results.length == 1" style="padding: 50px;">

                          <div>There are no predicated practices better than the standard practice.</div>
                  </div>

                  <div *ngIf="block_results.length > 1" class="scrolling-box" >
                      <div class="scrolling-content" *ngFor="let result of block_results; index as i">
                          <table  *ngIf="!result.standard"
                          (click)="selectThisResult(result, i)"
                          style="cursor: pointer"
                          >
                              <div>
                                  <tr>
                                      <td *ngIf="result.standard" colspan="2"><strong>Standard</strong></td>
                                      <td *ngIf="!result.standard" colspan="2"><strong>({{rankings[i]}}) Predicted</strong>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <strong>
                                            {{
                                              result.block_economic_results_grower
                                                .net_benefit | number : "1.0-0"
                                            }}</strong>
                                        </td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.yield_pred
                                          | number : "1.1-1"}}</td>
                                  </tr>
                                  <tr>
                                      <td>
                                          {{
                                            result.block_agronomic_results.yield_change_pred
                                              | number : "1.1-1"
                                          }}
                                        </td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.ccs_pred*100
                                          | number : "1.2-2"}}</td>
                                  </tr>
                                  <tr>
                                      <td *ngIf="result.standard">{{ payload.std_topping }}</td>
                                      <td *ngIf="!result.standard">{{block_results[i].block_agronomic_results.pred_topping }}
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.ground_speed_pred
                                          | number : "1.1-1"}}</td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.fan_speed_pred
                                          | number : "1.0-0"}}</td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.hours_per_day_total_pred
                                          | number : "1.1-1"}}</td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.hours_per_day_cutting_pred
                                          | number : "1.1-1"}}</td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.total_area_harvested
                                          | number : "1.0-0"}}</td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.flow_rate_pred
                                          | number : "1.1-1"}}</td>
                                  </tr>
                                  <tr>
                                      <td>{{block_results[i].block_agronomic_results.bin_weight_pred
                                          | number : "1.1-1"}}</td>
                                  </tr>
                                  <tr><td>
                                    {{ result.block_economic_results_grower.cost_change_t }}
                                  </td></tr>
                                  <tr><td>
                                          {{ result.block_economic_results_grower.cost_change_hr }}
                                  </td></tr>
                                  <tr><td>
                                      {{ result.block_economic_results_grower.cost_change_ha }}
                                  </td></tr>
                              </div>
                          </table>
                      </div>
                  </div>
              </div>
              <div class="fieldwrap fieldwrap-type-submit">
                <button
                  class="btn__secondary close-modal"
                  type="button"
                  (click)="dialog_open = false"
                >
                  Close
                </button>
              </div>
          </fieldset>
          </form>
        </div>
      </div>
      <div
        id="calculate_average"
        [ngClass]="{ 'overlay open': average_dialog_open }"
      >
        <div class="modal-box">
          <button
            class="close"
            type="button"
            (click)="average_dialog_open = false"
          ></button>
          <div class="modal-header">
            <h2>Block Calculation Results (Avg)</h2>
          </div>
          <form>
            <fieldset>
              <table style="width: 100%" style="cursor: pointer">
                <tr>
                  <td>
                    Yield (t/ha):
                    {{
                      avg_yield_pred / total_selected_blocks | number : "1.1-1"
                    }}
                  </td>
                  <td>
                    Fan Speed(RPM):
                    {{
                      avg_fan_speed_pred / total_selected_blocks
                        | number : "1.0-0"
                    }}
                  </td>
                  <td>
                    Ground Speed (km/hr):
                    {{
                      avg_ground_speed_pred / total_selected_blocks
                      | number : "1.1-1"
                    }}
                  </td>
                </tr>
                <tr>  
                  <td>
                    Flow Rate (t/hr):
                    {{
                      flow_rate_pred / total_selected_blocks | number : "1.1-1"
                    }}
                  </td>
                  <td>
                    Hours per day (total):
                    {{
                      hours_per_day_total_pred / total_selected_blocks
                        | number : "1.1-1"
                    }}
                  </td>
                  <td>
                    Average Bin Weight (t):
                    {{
                      bin_weight_pred / total_selected_blocks | number : "1.1-1"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Cost Change ($/t): {{total_cost_change_t / total_selected_blocks
                    | number : "1.2-2"}}</td>
                    <td>Cost Change ($/ha): {{total_cost_change_ha / total_selected_blocks
                      | number : "1.0-0"}} </td>
                      <td>Cost Change ($/hr): {{total_cost_change_hr / total_selected_blocks
                        | number : "1.0-0"}}</td>
                </tr>
                <tr>
                  <td>
                    Grower Net benefit: $
                      {{
                        avg_net_benefit / total_selected_blocks
                          | number : "1.0-0"
                      }}
                  </td>
                </tr>    
              </table>

              <div class="fieldwrap fieldwrap-type-submit">
                <button
                  class="btn__secondary close-modal"
                  type="button"
                  (click)="average_dialog_open = false"
                >
                  Close
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</form>
