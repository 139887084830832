import {
  Component,
  Input,
  OnInit,
  isDevMode,
  enableProdMode,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { AdminService } from 'src/app/services/admin.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { InputPostData } from 'src/app/models/InputData';
import { IDatafields } from '../../models/DataFields';
import { ChangeDetectorRef } from '@angular/core';
import { ContractorSelectBlockComponent } from '../contractor-select-block/contractor-select-block.component';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { enableDebugTools } from '@angular/platform-browser';
import { type } from 'os';
import { DecimalPipe, formatNumber, formatPercent } from '@angular/common';
import { MatCardHeader } from '@angular/material/card';
import { ThisReceiver } from '@angular/compiler';
import { ConsoleLogger } from '@aws-amplify/core';

@Component({
  selector: 'app-harvester-season',
  templateUrl: './harvester-season.component.html',
  styleUrls: ['./harvester-season.component.css'],
  providers: [AdminService, HarvestService, InputPostData],
})
export class HarvesterSeasonComponent implements OnInit {
  @Input() harvesterData: any = {};
  @Input() harvesterId: any = {};
  harvesterList: any = [];
  harvesterBrands: any = [];
  harvesterSeasonList: Array<any> = [];
  millSeasonList: any = [];
  selectedMillFuelPrice: number = 0;
  selectedHarvesterSeason: any = {};
  selectedYear: number = 2022;
  seasonList: any = [];
  compensation_types: any = [
    { text: 'per tonne', value: 'tonnes' },
    { text: 'per hour', value: 'hours' },
  ];
  repair_cost_counter: any;
  dataPost: any = {};
  dataPre: any;
  tabHeader: string = 'Harvester Season';
  saveButtonText = 'Add Harvester Season';
  asabove?: boolean;
  selectedHarvester_ext_id: string = '';
  chopperBladesOptions: any = [6, 8, 10, 12];
  percentageFields = [
    'saturday_rate',
    'sunday_rate',
    'holiday_rate',
    'rdo_rate',
    'extra_time_rate',
    'average_interest_paid',
  ];
  dialog_open: boolean = false;
  harvester_inputs: any = {};
  harvester_brand: any;
  show_message_dialog: boolean = false;
  rnm_items: any[] = [];
  rnm_items_selected_harvester_season: any[] = [];
  isHarvesterSelected: boolean = false;
  fixedCosts: any = 0;
  showAddSeason: boolean = true;
  isUpdateHavesterSeason: boolean = false;
  month_date: any;

  fieldNames: IDatafields[] = [
    { value: 'season', desc: 'Season' },
    { value: 'harvester_driver_wage', desc: 'Cost of harvester driver' },
    { value: 'haulout_driver_wage', desc: 'Cost of haulout driver' },
    { value: 'saturday_rate', desc: 'Rate for Saturdays worked' },
    { value: 'sunday_rate', desc: 'Rate for Sundays worked' },
    { value: 'holiday_rate', desc: 'Rate for Holidays worked' },
    { value: 'rdo_rate', desc: 'Rate for RDOs worked' },
    { value: 'extra_time_rate', desc: 'Rate for Extra Time' },
    {
      value: 'preseason_labour_and_maintenance_costs',
      desc: 'Pre-season Labour/Maintenance Cost',
    },
    { value: 'average_interest_paid', desc: 'Average Interest Paid' },
    { value: 'chopper_drum_blades_total', desc: 'Chopper Drum Blades' },

    { value: 'haulouts_available', desc: 'No. of Haulouts available' },
    { value: 'average_bin_capacity', desc: 'Average Haulout Bin Weight' },
    { value: 'haulout_speed_loaded', desc: 'Haulout Speed Loaded' },
    { value: 'haulout_fuel_use_loaded', desc: 'Haulout Fuel Use loaded' },
    { value: 'haulout_speed_empty', desc: 'Haulout Speed Empty' },
    { value: 'haulout_fuel_use_empty', desc: 'Haulout Fuel Use Empty' },
    {
      value: 'time_required_to_empty_haulout_bin',
      desc: 'Time required to empty haulout bin',
    },
    {
      value: 'average_servicing_time',
      desc: 'Estimated average servicing time',
    },
    {
      value: 'average_moving_time',
      desc: 'Estimated average harvester moving time',
    },

    { value: 'harvester_current_new_value', desc: 'Harvester Purchace Price' },
    { value: 'harvester_life', desc: 'Harvester Life' },
    {
      value: 'harvester_current_salvage_value',
      desc: 'Harvester Salvage Value',
    },
    {
      value: 'haulout_tractor_current_new_value',
      desc: 'Haulout Tractor Purchase Price',
    },
    { value: 'haulout_tractor_life', desc: 'Average Life of Haulout Tractors' },
    {
      value: 'haulout_tractor_current_salvage_value',
      desc: 'Salvage Value of Haulout Tractors',
    },
    {
      value: 'haulout_trailer_current_new_value',
      desc: 'Haulout Trailer Purchase Price',
    },
    { value: 'haulout_trailer_life', desc: 'Life of Haulout Trailers' },
    {
      value: 'haulout_trailer_current_salvage_value',
      desc: 'Salvage Value of Haulout Trailers',
    },
    { value: 'repair_maintenance_cost', desc: 'R&M COST COUNTER' },
  ];

  //dinamic form
  selectable = {
    selectables: [{ selectableName: '' }],
  };
  form: FormGroup = this.formBuilder.group({
    selectables: this.buildContacts(this.selectable.selectables),
  });
  items_options: any = [];
  selected_items_options_ids: any = [];
  items_names: any = [];

  //dinamic form haulouts
  selectable_haulouts = {
    selectables_haulouts: [{ selectableName_haulouts: '' }],
  };
  form_haulouts: FormGroup = this.formBuilder.group({
    selectables_haulouts: this.buildContacts_haulouts(
      this.selectable_haulouts.selectables_haulouts
    ),
  });
  items_options_haulouts: any = [];
  selected_items_haulouts_options: any = []
  selected_items_haulouts_options_ids: any = [];
  items_names_haulouts: any = [];

  //dinamic form other
  selectable_other = {
    selectables_other: [{ selectableName_other: '' }],
  };
  form_other: FormGroup = this.formBuilder.group({
    selectables_other: this.buildContacts_other(
      this.selectable_other.selectables_other
    ),
  });
  items_options_other: any = [];
  selected_items_other_options_ids: any = [];
  items_names_other: any = [];

  harvester_items: any[] = [];
  haulout_items: any[] = [];
  other_items: any[] = [];
  selectedSeasonMills: any[] = [];

  constructor(
    private adminApi: AdminService,
    private harversterApi: HarvestService,
    private dialog: DialogService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private inputData: InputPostData,
    private ref: ChangeDetectorRef
  ) {
    this.dialog_open = false;
  }

  // transform(input: number): number {
  //   return input * 100;
  // }

  // setPercent(input: number): number {

  //   return input / 100;
  // }


  //dinamic form
  get selectables(): FormArray {
    return this.form.get('selectables') as FormArray;
  }
  buildContacts(selectables: { selectableName: string }[] = []) {
    return this.formBuilder.array(
      selectables.map((selectable) => this.formBuilder.group(selectable))
    );
  }
  addContactField() {
    this.selectables.push(this.formBuilder.group({ selectableName: '' }));
  }
  removeContactField(): void {
    this.selectables.controls.pop();
  }

  displayPercent(input: number | null): string {

    return formatNumber((input ?? 0) * 100, 'en-AU', "0.0-2");
  }

  //dinamic form haulout
  get selectables_haulouts(): FormArray {
    return this.form_haulouts.get('selectables_haulouts') as FormArray;
  }
  buildContacts_haulouts(
    selectables_haulouts: { selectableName_haulouts: string }[] = []
  ) {
    return this.formBuilder.array(
      selectables_haulouts.map((selectable_haulouts) =>
        this.formBuilder.group(selectable_haulouts)
      )
    );
  }
  addContactField_haulouts() {
    this.selectables_haulouts.push(
      this.formBuilder.group({ selectableName_haulouts: '' })
    );
  }
  removeContactField_haulouts(): void {
    this.selectables_haulouts.controls.pop();
  }

  //dinamic form other
  get selectables_other(): FormArray {
    return this.form_other.get('selectables_other') as FormArray;
  }

  buildContacts_other(
    selectables_other: { selectableName_other: string }[] = []
  ) {
    return this.formBuilder.array(
      selectables_other.map((selectable_other) =>
        this.formBuilder.group(selectable_other)
      )
    );
  }
  addContactField_other() {
    this.selectables_other.push(
      this.formBuilder.group({ selectableName_other: '' })
    );
  }
  removeContactField_other(): void {
    this.selectables_other.controls.pop();
  }

  numberCheck(args: any) {
    args.key.toLocaleString();
  }

  deleteHarvesterSeason(ext_id: string, season: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to delete the harvester season?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        let entity = 'harvester_season';
        let parameters = `?entity=${entity}&ext_id=${ext_id}&season=${season}`;
        if (yes) {

          this.adminApi.delete(parameters).subscribe(
            (s) => {

              if (s.body.includes('successfully')) {
                this.toastr.success(s.body);
                this.ngOnInit();
              } else if (s.body.errorMessage) {
                this.toastr.error(
                  `Error deleting the harvester. Error: ${s.errorMessage}`
                );
              } else {
                this.toastr.error(
                  `Error deleting the harvester. Error: ${s.body}`
                );
              }
            },
            (error) => this.toastr.error(error.error.errorMessage)
          );
        }
      });
  }
  loadHarvesterSeason(): any {
    this.harversterApi.get(this.harvesterId).subscribe((harvester) => {
      this.harvesterSeasonList = (harvester.seasons ?? []).filter(
        (obj: any) => obj.status == 'ACTIVE'
      );

      this.harvesterSeasonList.sort((a, b) => b.season - a.season);
    });
    this.selectedHarvesterSeason.harvester_id = this.harvesterData.id;
  }

  loadSeasons(): any {
    this.adminApi.getSeasons().subscribe((l) => {

      if (l.body) {
        this.seasonList = l.body;
      }
    });
  }

  loadHarvester(): any {
    this.harversterApi.getAll('/harvester').subscribe((l) => {

      if (l.body) {
        this.harvesterList = l.body.filter(
          (obj: any) => obj.status == 'ACTIVE'
        );
      }
    });
  }

  loadBrands(): any {
    this.harversterApi.getAll('harvester/brands').subscribe((l) => {
      if (l.body) {

        this.harvesterBrands = l.body.filter(
          (obj: any) => obj.status == 'ACTIVE'
        );
      }
    });
  }
  onChangeSeason(): void {
    this.selectedSeasonMills = this.millSeasonList.filter(
      (obj: any) => obj.season == this.selectedHarvesterSeason.season || obj.season === undefined
    );
  }

  loadMillSeason(): any {
    this.adminApi.getAll('/mills/season').subscribe((l) => {

      if (l.body) {
        this.millSeasonList = l.body.filter(
          (obj: any) => obj.status == 'ACTIVE'
        );
        this.millSeasonList = [{ 'fuel_price': '' }].concat(this.millSeasonList) // add empty row for dropdown menu 
        this.onChangeSeason();
      }
    });
  }
  loadFixedCost(year: number): any {
    this.harversterApi.getFixedCost(year.toString()).subscribe((l) => {
      if (l.body) {
        this.fixedCosts = Math.trunc(l.body.total_cost_per_harvester) ?? 0;
        if (this.isHarvesterSelected && !this.isUpdateHavesterSeason) {
          this.selectedHarvesterSeason.fixed_costs_per_harvester = this.fixedCosts;
        }
      }
    });
  }

  setDefaultValue(): void {

    this.selectedHarvester_ext_id = this.harvesterData.ext_id;


  }

  onHarvesterSeasonChange(season: any) {
    this.loadFixedCost(Number(season));
    this.onChangeSeason()
  }

  async updateData(
    endpoint: string,
    type: string,
    data: any,
    action: string
  ): Promise<any> {

    const h = await this.harversterApi.update(endpoint, data)
    // .subscribe(
    //   (h) => {
    //   },
    //   (error) => this.toastr.error(error.error.errorMessage)
    // );
    if (h.body == 'Successfully Updated') {
      this.toastr.success(type + ' ' + action + ' successfully.');
    } else {
      this.toastr.error(h.body.error);
    }
  }

  addSeason() {
    this.setDefaultValue();
    this.selectedHarvesterSeason.harvester_id = this.harvesterData.ext_id;
    this.isHarvesterSelected = true;
    this.showAddSeason = false;
    this.isUpdateHavesterSeason = false;

  }

  // Turn 100% into 1, keep extra 2 precision so that 49.5% doesnt round to .5
  percentToNumber(input: number, precision: number = 2): number {
    return Number((input / 100).toFixed(precision + 2));
  }
  numberToPercent(input: number, precision: number = 2): number {
    return Number((input * 100).toFixed(precision + 2))
  }

  edit(season: number): void {
    this.loadHarvesterSeason();
    this.loadFixedCost(season);
    this.isUpdateHavesterSeason = true;
    this.isHarvesterSelected = true;
    this.showAddSeason = false;
    this.rnm_items = [];
    this.harvester_items = [];
    this.other_items = [];
    this.haulout_items = [];


    const restore = sessionStorage.getItem("harvester")
    if (restore) {
      this.selectedHarvesterSeason = JSON.parse(restore)
      sessionStorage.removeItem("harvester");
    } else {
      this.selectedHarvesterSeason = this.harvesterSeasonList.find(
        (obj: any) => obj.season == season
      );
      // It needs to convert if loading from API
      this.selectedHarvesterSeason.saturday_rate = this.numberToPercent(this.selectedHarvesterSeason.saturday_rate);
      this.selectedHarvesterSeason.sunday_rate = this.numberToPercent(this.selectedHarvesterSeason.sunday_rate);
      this.selectedHarvesterSeason.rdo_rate = this.numberToPercent(this.selectedHarvesterSeason.rdo_rate);
      this.selectedHarvesterSeason.holiday_rate = this.numberToPercent(this.selectedHarvesterSeason.holiday_rate);
      this.selectedHarvesterSeason.extra_time_rate = this.numberToPercent(this.selectedHarvesterSeason.extra_time_rate);
      this.selectedHarvesterSeason.average_interest_paid = this.numberToPercent(this.selectedHarvesterSeason.average_interest_paid);
    }

    this.repair_cost_counter =
      this.selectedHarvesterSeason.repair_maintenance_cost;
    this.onChangeSeason();
    this.saveButtonText = 'Update Harvester Season';
  }
  cancel(type: string): void {
    this.isHarvesterSelected = false;
    this.showAddSeason = true;
    this.selectedHarvesterSeason = {};
    this.saveButtonText = 'Add Harvester Season';
  }
  async create(endpoint: string, type: string, data: any): Promise<any> {
    this.harversterApi.create(endpoint, data).subscribe((h) => {
      if (h.body.status == 'ACTIVE') {
        this.toastr.success(type + ' created successfully.');
        this.ngOnInit();
      } else {
        this.toastr.error(h.body.error);
      }
    });
  }
  async validateFields(): Promise<any> {
    let is_valid = true;
    // this.fieldNames.forEach((e) => {
    //   if (
    //     !this.selectedHarvesterSeason[e.value] ||
    //     this.selectedHarvesterSeason[e.value] == ''
    //   ) {
    //     this.toastr.warning(`Please enter the ${e.desc}!`);
    //     is_valid = false;
    //   }
    // });

    return is_valid;
  }

  async save(_: number) {
    this.isHarvesterSelected = true;
    this.selectedHarvesterSeason.repair_and_maintenance_item_selections = this.rnm_items;




    let data = { ...this.selectedHarvesterSeason };
    this.isHarvesterSelected = true;
    delete data.repair_and_maintenance_items

    data.repair_and_maintenance_item_selections = this.rnm_items;
    data.saturday_rate = this.percentToNumber(data.saturday_rate)
    data.sunday_rate = this.percentToNumber(data.sunday_rate)
    data.rdo_rate = this.percentToNumber(data.rdo_rate)
    data.holiday_rate = this.percentToNumber(data.holiday_rate)
    data.extra_time_rate = this.percentToNumber(data.extra_time_rate)
    data.average_interest_paid = this.percentToNumber(data.average_interest_paid)


    if (this.asabove) {
      data.spare_haulout_tractor_current_new_value = data.haulout_tractor_current_new_value
      data.spare_haulout_tractor_life = data.haulout_tractor_life
      data.spare_haulout_tractor_current_salvage_value = data.haulout_tractor_current_salvage_value
      data.spare_haulout_trailer_current_new_value = data.haulout_trailer_current_new_value
      data.spare_haulout_trailer_life = data.haulout_trailer_life
      data.spare_haulout_trailer_current_salvage_value = data.haulout_trailer_current_salvage_value
    }


    if (!await this.validateFields()) return;


    if (this.isUpdateHavesterSeason) {
      const endpoint = `harvester/${this.harvesterId}/season/${data.season}`
      this.updateData(
        endpoint,
        'Harvester Season',
        JSON.stringify(data),
        'updated'
      );
    } else {
      delete data.harvester_id;
      const endpoint = `harvester/${this.harvesterId}/seasons/${data.season}`;

      this.create(
        endpoint,
        'Harvester Season',
        JSON.stringify(data)
      ).then(
        (res) => {
          this.isUpdateHavesterSeason = true;
        }
      );
    }

    // await this.delay(1000);
    // window.location.reload();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  showDialog(): void {

    this.rnm_items = [];
    this.selectables.controls.length = 0; //set length reactive form to 0
    this.selectables_haulouts.controls.length = 0; //set length reactive form haulout to 0
    this.selectables_other.clear(); //set length reactive form other to 0
    this.show_message_dialog = false; // reset showmessage flag
    this.harvester_items = [];
    this.other_items = [];
    this.haulout_items = [];

    //chose right harvester brand for specific harvester
    let selected_harvester_brand_id = this.harvesterData.model.brand_id;
    this.harvester_brand = this.harvesterBrands.find(
      (obj: any) => obj.id == selected_harvester_brand_id
    );

    let r_and_m_items = (
      this.harvester_brand.repair_and_maintenance_items as []
    ).filter((item: any) => {
      return item.season_coverage_start <= this.selectedHarvesterSeason.season && this.selectedHarvesterSeason.season <= item.season_coverage_end
    });

    // Sort fields by sort_priority
    r_and_m_items.sort((a: any, b: any) => a.sort_priority - b.sort_priority);

    if (!r_and_m_items.length) {
      this.show_message_dialog = true;
      // return;
    }

    //for each RnM HAULOUT item in harvester_brand, push item (name & options) to dialog
    r_and_m_items.forEach((item: any) => {


      switch (item.type) {
        case 'harvester':
          this.harvester_items.push(item);
          break;
        case 'haulouts':
          this.haulout_items.push(item);
          break;
        case 'other':
          this.other_items.push(item);
          break;
      }
    });

    //#region sort items by priorty
    this.harvester_items.sort((a, b) => a.sort_priority - b.sort_priority);
    this.haulout_items.sort((a, b) => a.sort_priority - b.sort_priority);
    this.other_items.sort((a, b) => a.sort_priority - b.sort_priority);
    //#endregion

    for (let i = 0; i < this.selectedHarvesterSeason.repair_and_maintenance_item_selections.length; i++) {
      let harv_selected_option = this.selectedHarvesterSeason.repair_and_maintenance_item_selections[i];
      if (harv_selected_option["id"]) {
        harv_selected_option["option_id"] = harv_selected_option["id"]
      }

      if (harv_selected_option["repair_and_maintenance_item_id"]) {
        harv_selected_option["item_id"] = harv_selected_option["repair_and_maintenance_item_id"]
      }


      for (let m = 0; m < this.harvester_items.length; m++) {
        let harv_item = this.harvester_items[m];

        if (harv_item["id"] == harv_selected_option["item_id"]) {
          harv_item["selected_option"] = harv_selected_option["option_id"];
          this.selected_items_options_ids[harv_item["id"]] = harv_selected_option["option_id"];
          break;
        }
      }

      for (let m = 0; m < this.haulout_items.length; m++) {
        let haulout_item = this.haulout_items[m];

        if (haulout_item["id"] == harv_selected_option["item_id"]) {
          haulout_item["selected_option"] = harv_selected_option["option_id"];
          this.selected_items_haulouts_options_ids[haulout_item["id"]] = harv_selected_option["option_id"];
          break;
        }
      }

      for (let m = 0; m < this.other_items.length; m++) {
        let other_item = this.other_items[m];

        if (other_item["id"] == harv_selected_option["item_id"]) {
          other_item["selected_option"] = harv_selected_option["option_id"];
          this.selected_items_other_options_ids[other_item["id"]] = harv_selected_option["option_id"];
          break;
        }
      }
    }



    this.harvester_items.forEach((item: any, index) => {
      this.items_names.push(item.name);
      this.selectables.push(this.formBuilder.group({ selectableName: '' }));
      this.items_options[item.id] = item.options.map((option: any) => ({
        name: option.name,
        id: option.id,
        selected: option.id == item["selected_option"] ? true : false
      }));
    });


    this.haulout_items.forEach((item: any, index) => {
      this.items_names_haulouts.push(item.name);
      this.selectables_haulouts.push(
        this.formBuilder.group({ selectableName_haulouts: '' })
      );
      this.items_options_haulouts[item.id] = item.options.map((option: any) => ({
        name: option.name,
        id: option.id,
      }));
    });


    this.other_items.forEach((item: any, index) => {
      this.items_names_other.push(item.name);
      this.selectables_other.push(
        this.formBuilder.group({ selectableName_other: '' })
      );
      this.items_options_other[item.id] = item.options.map((option: any) => ({
        name: option.name,
        id: option.id,
      }));
    });
    this.ref.detectChanges();
    this.ref.markForCheck();
    this.dialog_open = true;
  }

  saveDialog() {

    //prepare rnm_items obj for backend rnm_items

    if (this.harvester_brand.repair_and_maintenance_items.length == 0) {
      this.rnm_items = [];
    } else {


      for (let itemIndex = 0; itemIndex < this.harvester_brand.repair_and_maintenance_items.length; itemIndex++) {
        let item_id = this.harvester_brand.repair_and_maintenance_items[itemIndex].id;

        if (this.selected_items_haulouts_options_ids[item_id] != undefined) {
          this.rnm_items.push({ 'item_id': item_id, 'option_id': Number(this.selected_items_haulouts_options_ids[item_id]) })
        }
        if (this.selected_items_other_options_ids[item_id] != undefined) {
          this.rnm_items.push({ 'item_id': item_id, 'option_id': Number(this.selected_items_other_options_ids[item_id]) })
        }
        if (this.selected_items_options_ids[item_id] != undefined) {
          this.rnm_items.push({ 'item_id': item_id, 'option_id': Number(this.selected_items_options_ids[item_id]) })
        }
      }
    }



    this.dialog_open = false;
    this.save(this.selectedHarvesterSeason.harvester_id)
  }

  ngOnInit(): void {

    this.selectables.controls.length = 0; //set length reactive form to 0
    this.selectables_haulouts.controls.length = 0; //set length reactive form haulout to 0
    this.selectables_other.controls.length = 0; //set length reactive form other to 0
    this.loadSeasons();
    this.dataPost = this.inputData.dataType;
    this.dataPre = this.inputData.dataType;

    const restore = sessionStorage.getItem("harvester")
    if (restore) {
      let data = JSON.parse(restore)
      this.edit(data.season);
    } else {
      this.loadHarvesterSeason();
    }

    this.loadHarvester();
    this.loadMillSeason();
    this.loadBrands();
  }

  saveTemporarily(): void {
    sessionStorage.setItem("harvester", JSON.stringify(this.selectedHarvesterSeason));
  }
}
