import { Component, OnInit, isDevMode } from '@angular/core';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockService } from '../../services/block.service';
import { ToastrService } from 'ngx-toastr';
import { InputPostData } from '../../models/InputData';
import { IDatafields } from '../../models/DataFields';
import { FarmService } from '../../services/farm.service';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService } from '../../services/dialog.service';

@Pipe({
  name: 'sum',
})
export class SumPipe implements PipeTransform {
  transform(items: any[], attr: string): any {

    return items.reduce((a, b) => a + b[attr], 0);
  }
}

@NgModule({
  declarations: [SumPipe],
  exports: [SumPipe],
})
export class SumPipeModule {}

@Component({
  selector: 'app-block-details',
  templateUrl: './block-details.component.html',
  styleUrls: ['./block-details.component.css'],
  providers: [FarmService, BlockService, InputPostData, AdminService],
})
export class BlockDetailsComponent implements OnInit {
  farm_id: string = '';
  block_id: string = '';
  block_number: string = '';
  allowance_open: boolean = false;
  iscopy: string = '';
  selectedYear: number = 2022;
  cropData: any = [];
  block: any = {};
  dataPost: any = {};
  fieldNames: IDatafields[] = [
    { value: 'block_number', desc: 'Block Number' },
    { value: 'number_haulouts_utilised', desc: 'Number of Haulouts Utilised' },
    { value: 'row_spacing', desc: 'Row Spacing' },
    { value: 'average_row_length', desc: 'Average Row Length' },
    {
      value: 'average_infield_haulout_distance',
      desc: 'Average Infield Haulout Distance',
    },
    { value: 'average_bin_mass', desc: 'Average Bin Mass' },
    { value: 'area', desc: 'Area' },
  ];
  constructor(
    private router: Router,
    private dialog: DialogService,
    private farmApi: FarmService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private blocksapi: BlockService,
    private inputData: InputPostData,
    private adminApi: AdminService
  ) {
    this.allowance_open = false;
  }

  deleteCrops(block_number: string, season: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to delete the crop ?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        let entity = 'crop';
        let farm_ext_id = this.farm_id;
        let parameters = `?entity=${entity}&farm_ext_id=${farm_ext_id}&block_number=${block_number}&season=${season}`;
        if (yes) {

          this.adminApi.delete(parameters).subscribe(
            (s) => {

              if (s.body.includes('successfully')) {
                this.toastr.success(s.body);
                this.ngOnInit();
              }
            },
            (error) => this.toastr.error(error.error.errorMessage)
          );
        }
      });
  }
  showAllowance(): void {

    this.allowance_open = true;
  }

  async copyCrops(blockId: string, crops: any): Promise<void> {

  }

  ngOnInit(): void {
    let block_id: any;
    this.dataPost = this.inputData.dataType;
    this.route.queryParams.subscribe((params) => {
      this.block_id = params['id'];
      this.block_number = params['block_number'];
      this.farm_id = params['farm_id'];
      this.iscopy = params['iscopy'];
      if (this.block_id) {

        this.blocksapi.get(this.farm_id, this.block_number).subscribe((r) => {

          if (r.body) {
            this.block = r.body;
            block_id = r.body.id;
            this.block_number = r.body.block_number;
            if (params['iscopy'] == 'true') {

              delete this.block.id;
              delete this.block.block_number;
              delete this.block.farm_id;
              delete this.block.owner_id;
            }
          }
        });
        this.loadCrop(params['block_number']);
      }
    });
  }

  loadCrop(block_number: string): any {
    this.farmApi
      .getAllCrops(block_number.toString(), this.farm_id.toString())
      .subscribe((l) => {

        if (l.body) {
          this.cropData = l.body.filter((obj: any) => obj.status == 'ACTIVE');
        }
      });
  }
  validateFields(): any {
    let is_valid = true;
    this.fieldNames.forEach((e) => {
      if (!this.block[e.value] || this.block[e.value] == '') {
        this.toastr.warning(`Please enter the ${e.desc}!`);
        is_valid = false;
      }
    });
    return is_valid;
  }

  save(): any {


    if (this.validateFields()) {
      if (this.block.id) {
        delete this.block.crops;
        this.blocksapi
          .update(this.block, this.farm_id, this.block_number)
          .subscribe(
            (s) => {
              s.body
                ? this.toastr.success('Block updated successfully.')
                : s.error
                ? this.toastr.error(
                    `Error updating block. Error: ${s.error.errorMessage}`
                  )
                : this.toastr.error(
                    `Error updating block. Error: ${s.errorMessage}`
                  );
            },
            (error) => this.toastr.error(error.error.errorMessage)
          );
      } else {
        // let tempCrops = this.block.crops
        // const tempCrops = JSON.parse(JSON.stringify(this.block.crops));
        this.blocksapi.create(this.block, this.farm_id).subscribe(
          async (s) => {
            if (s.body.status == 'ACTIVE') {
              this.toastr.success('Block created successfully.');
              if (this.iscopy) {
                this.block.crops.forEach((e: any) => {
                  e.block_id = s.body.id;
                  delete e.created_at;
                  delete e.updated_at;
                  delete e.deleted_at;
                  this.blocksapi.create_crop(e, e.season).subscribe((s) => {
                    if (s.body.status == 'ACTIVE') {
                    } else {
                      this.toastr.error(s.errorMessage);
                    }
                  });
                });
              } else {
                location.href = `/block-details?id=${s.body.id}&block_number=${s.body.block_number}&farm_id=${this.farm_id}`;
              }
            } else {
              this.toastr.error(s.errorMessage);
            }
          },
          (error) => this.toastr.error(error.error.errorMessage)
        );
      }
    }
  }
}
