<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>User Management</h2>
    <h5>Current Total Users: {{users.length}}</h5>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Phone</th>
          <th scope="col">Address</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users; index as i">
          <td>
            <ngb-highlight [result]="user.fullname"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.email"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.phone"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.address"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="user.status"></ngb-highlight>
          </td>
          <td *ngIf="user.status == 'Disabled'">
            <button class="btn-save" type="submit" (click)="enableUser(user.username)">
              Enable
            </button>
          </td>
          <td *ngIf="user.status == 'Enabled'">
            <button class="btn-save" type="submit" (click)="disableUser(user.username)">
              Disable
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="fieldwrap fieldwrap-type-submit align-right">
      <a class="btn__secondary btn-back" routerLink="/home">Back</a>
    </div>
  </div>
</div>