import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Amplify, { Auth } from 'aws-amplify';

import { environment } from '../../environments/environment';

import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
  GetIdCommand,
  GetIdCommandInput,
  GetCredentialsForIdentityCommandInput,
} from '@aws-sdk/client-cognito-identity';

import {
  AdminInitiateAuthCommand,
  AdminInitiateAuthCommandInput,
  CognitoIdentityProviderClient,
  AdminDisableUserCommandInput,
  AdminEnableUserCommand,
  AdminEnableUserCommandInput,
  AdminDisableUserCommand,
  ChangePasswordCommand,
  ChangePasswordCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';

import { CognitoUser } from 'amazon-cognito-identity-js';
import { env } from 'process';

export interface IUser {
  email: string;
  givenName: string;
  lastName: string;
  zoneInfo: string;
  phoneNumber: string;
  userType: string;
  address: string;
  password: string;
  newPassword: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdminCognitoService {
  private authenticationSubject: BehaviorSubject<any>;

  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
      Cache: false,
    });
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  public async adminSignIn(user: IUser): Promise<any> {
    return Auth.signIn(user.email, user.password).then((u) => {
      if (u.challengeName && u.challengeName == 'NEW_PASSWORD_REQUIRED') {
        localStorage.setItem(
          'reset_pwd_user_access_key',
          u.storage.reset_pwd_user_access_key
        );
        localStorage.setItem(
          'reset_pwd_user_secret_key',
          u.storage.reset_pwd_user_secret_key
        );
        localStorage.setItem(
          'reset_pwd_user_session_token',
          u.storage.reset_pwd_user_session_token
        );
        localStorage.setItem('reset_access_token', u.Session);
        throw 'NEW_PASSWORD_REQUIRED';
      }

      var jwtToken = u.signInUserSession.idToken.jwtToken;
      var client = new CognitoIdentityClient({
        region: environment.aws.region,
      });

      //get identity id value
      const paramsId = {} as GetIdCommandInput;
      paramsId.IdentityPoolId =
        environment.aws.region + ':' + environment.aws.identityPoolId;
      paramsId.Logins = {
        [`cognito-idp.${environment.aws.region}.amazonaws.com/${u.pool.userPoolId}`]:
          jwtToken,
      };
      const commandPoolId = new GetIdCommand(paramsId);

      client.send(commandPoolId).then(
        (dataPoolId) => {
          //get access credentials for current user
          const params = {} as GetCredentialsForIdentityCommandInput;
          params.Logins = {
            [`cognito-idp.${environment.aws.region}.amazonaws.com/${u.pool.userPoolId}`]:
              jwtToken,
          };
          params.IdentityId = dataPoolId.IdentityId;
          const command = new GetCredentialsForIdentityCommand(params);

          client.send(command).then(
            (data) => {
              localStorage.setItem(
                environment.keys.accessKey,
                data.Credentials?.AccessKeyId || ''
              );
              localStorage.setItem(
                environment.keys.secretKey,
                data.Credentials?.SecretKey || ''
              );
              localStorage.setItem(
                environment.keys.sessionToken,
                data.Credentials?.SessionToken || ''
              );

              this.authenticationSubject.next(true);
            },
            (error) => {
              throw error;
            }
          );
        },
        (error) => {
          throw error;
        }
      );
    });
  }

  public disableUser(username: string): Promise<any> {
    var client = new CognitoIdentityProviderClient({
      region: environment.aws.region,
      credentials: {
        accessKeyId: localStorage.getItem(environment.keys.accessKey) || '',
        secretAccessKey: localStorage.getItem(environment.keys.secretKey) || '',
        sessionToken: localStorage.getItem(environment.keys.sessionToken) || '',
      },
    });
    const params = {} as AdminDisableUserCommandInput;
    params.UserPoolId = environment.cognito.userPoolId;
    params.Username = username;

    const command = new AdminDisableUserCommand(params);

    return client.send(command);
  }

  public enableUser(username: string): Promise<any> {
    var client = new CognitoIdentityProviderClient({
      region: environment.aws.region,
      credentials: {
        accessKeyId: localStorage.getItem(environment.keys.accessKey) || '',
        secretAccessKey: localStorage.getItem(environment.keys.secretKey) || '',
        sessionToken: localStorage.getItem(environment.keys.sessionToken) || '',
      },
    });
    const params = {} as AdminEnableUserCommandInput;
    params.UserPoolId = environment.cognito.userPoolId;
    params.Username = username;

    const command = new AdminEnableUserCommand(params);

    return client.send(command);
  }

  public changePasswordUser(user: IUser): Promise<any> {
    var client = new CognitoIdentityProviderClient({
      region: environment.aws.region,
    });

    const params = {} as ChangePasswordCommandInput;
    params.AccessToken = localStorage.getItem('reset_access_token') || '';
    params.PreviousPassword = user.password;
    params.ProposedPassword = user.newPassword;

    const command = new ChangePasswordCommand(params);

    return client.send(command);


    // let cgUser = JSON.parse(localStorage.getItem(environment.keys.currentUser) || "") as CognitoUser;


    // return Auth.changePassword(cgUser, user.password, user.newPassword);
  }

  public signOut(): Promise<any> {
    return Auth.signOut().then(() => {
      this.authenticationSubject.next(false);
    });
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  }

  public async isAdmin(): Promise<boolean> {
    let userInfo = await this.getUser();
    return userInfo.attributes["custom:user_type"] === "ADMIN"
  }

  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  public updateUser(user: IUser): Promise<any> {
    return Auth.currentUserPoolUser().then((cognitoUser: any) => {
      return Auth.updateUserAttributes(cognitoUser, user);
    });
  }

  async isLoggedIn(): Promise<boolean> {
    let isAuth = false;
    if (await Auth.currentAuthenticatedUser()) {
      isAuth = true;
    }
    return isAuth;
  }
}
