import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockService } from '../../services/block.service';
import { ToastrService } from 'ngx-toastr';
import { InputPostData } from '../../models/InputData';
import { IDatafields } from '../../models/DataFields';
import { AdminService } from 'src/app/services/admin.service';
import { Location } from '@angular/common';

// body format for create crop
// https://martian-station-268814.postman.co/workspace/SRA~b9d20890-7ec9-4893-97c9-827d8fcd74e2/request/18095885-62f620c0-acbc-482a-90df-c22067595830

@Component({
  selector: 'app-crop-details',
  templateUrl: './crop-details.component.html',
  styleUrls: ['./crop-details.component.css'],
  providers: [BlockService, InputPostData, AdminService],
})
export class CropDetailsComponent implements OnInit {
  farm_id: string = '';
  iscopy: string = '';
  block_number: string = '';
  block_id: string = '';
  crop: any = {};
  dataPost: any = {};
  blockList: any = [];
  seasonList: any = {};
  currentMillSeason: any = {};
  season: string = '';

  toppingList: any = ['moderate', 'light', 'none'];
  currentTopping: any = {};

  varietyList: any = [];

  CropList: any = [
    'plant',
    'first ratoon',
    'second ratoon',
    'third ratoon',
    'older ratoon',
  ];
  currentCrop: any = {};

  presentationList: any = ['erect', 'sprawled', 'lodged'];
  currentPresentation: any = {};

  fieldMoistureList: any = ['dry', 'damp'];
  currentFieldMoisture: any = {};

  fieldNames: IDatafields[] = [
    { value: 'season', desc: 'Season' },
    { value: 'topping', desc: 'Topping' },
    { value: 'variety_id', desc: 'Variety' },
    { value: 'crop_class', desc: 'Crop Class' },
    { value: 'presentation', desc: 'Presentation' },
    { value: 'field_moisture', desc: 'Field Moisture' },
    { value: 'default_ccs', desc: 'Default CCS' },
    { value: 'default_ground_speed', desc: 'Standard Ground Speed' },
    { value: 'default_fan_speed', desc: 'Standard Fan Speed' },
    { value: 'default_yield', desc: 'Estimated Yield' },
  ];

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private blocksapi: BlockService,
    private inputData: InputPostData,
    private adminApi: AdminService
  ) { }

  loadSeasons(): any {
    this.adminApi.getSeasons().subscribe((l) => {

      if (l.body) {
        this.seasonList = l.body;
      }
    });
  }

  percentToNumber(input: number, precision: number = 2): number {
    return Number((input / 100).toFixed(precision + 2));
  }

  // Convert a number to percent with given precision
  numberToPercent(input: number, precision: number = 2): number {
    return Number((input * 100).toFixed(precision + 2)) // precision +  2 to keep precision after 100
  }

  loadData(type: string, data: any): any {
    this.adminApi.getAll(type).subscribe((l) => {
      if (l.body) {
        switch (type) {
          case 'variety':
            this.varietyList = l.body.filter(
              (obj: any) => obj.status === 'ACTIVE' || obj.id === this.crop.variety_id
            ).sort((a: any, b: any) => Intl.Collator('en', { numeric: true }).compare(a.name, b.name));
            break;
          default:
            break;
        }
      }
    });
  }
  loadBlocks(farmId: string) {
    this.blocksapi.getAll(farmId).subscribe((f: any) => {
      if (f.body) {
        this.blockList = f.body.blocks;
      }
    });
  }

  ngOnInit(): void {
    this.loadSeasons();
    this.dataPost = this.inputData.dataType;
    this.route.queryParams.subscribe((params) => {
      this.block_id = params['block_id'];
      this.block_number = params['block_number'];
      this.season = params['season'];
      this.farm_id = params['farm_id'];
      this.iscopy = params['iscopy'];
      this.loadBlocks(params['farm_id']);
      if (this.block_id) {
        this.blocksapi
          .get_crop(this.block_number, this.season, this.farm_id)
          .subscribe((r) => {
            if (r.body) {
              this.crop = r.body;
              this.crop.default_ccs = this.numberToPercent(this.crop.default_ccs);
              if (params['iscopy'] == 'true') {
                this.crop.season = null;
                delete this.crop.id;
                delete this.crop.created_at;
                delete this.crop.updated_at;
                delete this.crop.variety_details;
                delete this.crop.block_details;
              }
              this.loadData('variety', this.varietyList);
            }


          });
      } else {
        this.blocksapi.get(this.farm_id, this.block_number).subscribe((r) => {
          if (r.body) {
            this.crop.block_id = r.body.id;
            this.loadData('variety', this.varietyList);
          }
        });       
      }
    });
  }

  validateFields(): any {
    let is_valid = false;
    this.fieldNames.forEach((e) => {
      if (!this.crop[e.value]) {
        this.toastr.warning(`Please select the ${e.desc}!`);
        is_valid = false;
      } else {
        is_valid = true;
      }
    });
    return is_valid;
  }

  save(): any {
    const data = { ...this.crop };
    data.default_ccs = this.percentToNumber(data.default_ccs);

    if (this.validateFields()) {
      if (this.block_id && this.iscopy != 'true') {
        this.blocksapi
          .update_crop(data, this.season, this.block_id)
          .subscribe({
            next: (s) => {
              if (s.body == 'Successfully Updated') {
                this.toastr.success('Crop updated successfully.');
              } else {
                this.toastr.error(s.errorMessage);
              }
            },
            error: (error) => this.toastr.error(error.error.errorMessage)
          });
      } else {
        this.blocksapi.create_crop(data, this.crop.season).subscribe(
          (s) => {
            if (s.body.status == 'ACTIVE') {
              this.toastr.success('Crop created successfully.');
              this.back();
            } else {
              this.toastr.error(s.errorMessage);
            }
          },
          (error) => this.toastr.error(error.error.errorMessage)
        );
      }
    }
  }

  back() {
    this.location.back(); // <-- go back to previous location on cancel
  }
}
