import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AdminService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization:
        'Bearer ' + localStorage.getItem(environment.keys.accessToken) || '',
    }),
  };

  constructor(private http: HttpClient) {

  }

  // HttpClient API getOptions() method => Fetch data for dropdownlists
  getOptions(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + 'options/harvester', this.httpOptions)
      .pipe(retry(1));
  }

  // HttpClient API create() method => Create harvest
  create(endPoint: string, body: any): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + endPoint, body, this.httpOptions)

  }

  // HttpClient API delete() method => Delete RnM item
  //`${environment.apiUrl}farms/${farm}/blocks/${id}`
  delete_rnm_item(item_id: number): Observable<any> {
    return this.http
      .delete<any>(
        `${environment.apiUrl}/rnm/items/${item_id}`,
        this.httpOptions
      )
  }

  // HttpClient API update() method => Update harvest
  update(endPoint: string, body: any): Observable<any> {
    return this.http
      .put<any>(environment.apiUrl + endPoint, body, this.httpOptions)
  }

  // HttpClient API put() method => Get harvest
  get(id: string): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + 'harvesters/' + id, this.httpOptions)
      .pipe(retry(1));
  }
  getSeasons(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + '/seasons', this.httpOptions)
      .pipe(retry(1));
  }

  // HttpClient API delete() method => Delete harvest
  delete(parameters: string): Observable<any> {
    return this.http
      .delete<any>(
        environment.apiUrl + '/delete' + parameters,
        this.httpOptions
      )
      .pipe(retry(1));
  }

  // HttpClient API put() method => Get all harvest
  getAll(endPoint: string): Observable<any> {

    return this.http
      .get<any>(environment.apiUrl + endPoint, this.httpOptions)
      .pipe(retry(1));
  }
}
