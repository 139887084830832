<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>{{ tabHeader }}</h2>
    <div class="columns-wrap">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Variety Name</th>
            <th scope="col">Em chamber Coefficient</th>
            <th scope="col">Billet Diameter</th>
            <th scope="col">Modelled Variety</th>
            <th scope="col">Active</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of varietyList; index as i">
            <td>{{ i + 1 }}</td>
            <td>{{ row.name }}<sup class="pbr" *ngIf="row.has_pbr">(PBR)</sup></td>
            <td>{{ row.em_chamber_coefficient }}</td>
            <td>{{ row.billet_diameter }}</td>
            <td>
              <span *ngIf="row.modelled_variety">{{ row.modelled_variety.name }}<sup class="pbr"
                  *ngIf="row.modelled_variety.has_pbr">(PBR)</sup></span>
            </td>
            <td>{{row.status === "ACTIVE" ? "yes": "no"}}</td>
            <td>
              <button class="btn-small btn-left-space" type="button" (click)="update('variety', row.id)">
                Edit
              </button>
              <!-- <button class="btn-small btn-left-space" type="button"
                                (click)="deleteDialog(row.id, 'variety')">Delete</button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <fieldset>
        <div class="columns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Variety Name: </label>
                <input type="text" id="varietyName" name="varietyName" #varietyName="ngModel"
                  [(ngModel)]="currentVariety.name" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Em chamber Coefficient: </label>
                <input type="number" id="varietyECC" name="varietyECC" #varietyECC="ngModel"
                  [(ngModel)]="currentVariety.em_chamber_coefficient" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Billet Diameter: </label>
                <select id="varietyBD" name="varietyBD" #varietyBD="ngModel"
                  [(ngModel)]="currentVariety.billet_diameter">
                  <option *ngFor="let e of billetDiameters " value="{{e.value}}">{{e.label}}</option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Modelled Variety</label>
                <select id="varietyListModelled" name="varietyListModelled" #variety_id="ngModel"
                  [(ngModel)]="currentVariety.modelled_variety_id">
                  <option value="">No Modelled Variety</option>
                  <option *ngFor="let e of varietyListModelled" value="{{ e.id }}">
                    {{ e.name }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <div class="toggle">
                  <input id="varietyPBR" type="checkbox" #varietyPBR="ngModel" [(ngModel)]="currentVariety.has_pbr"
                    [ngModelOptions]="{ standalone: true }" />
                  <label for="varietyPBR">Has PBR</label>
                </div>
              </div>
              <div class="fieldwrap label-inline">
                <div class="toggle">
                  <input id="status" type="checkbox" #status="ngModel" [(ngModel)]="varietyIsActiveToggle"
                    (change)="toggleVarietyStatus()" [ngModelOptions]="{ standalone: true }" />
                  <label for="status">Active</label>
                </div>
              </div>
              <div class="fieldwrap label-inline">
                <button class="btn-save" type="submit" (click)="save('variety')">
                  {{ varietyText }}
                </button>
                <button class="btn-save" type="submit" (click)="cancel('variety')">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
  </div>
</div>