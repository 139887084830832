import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import {
  CognitoIdentityProvider,
  ListUsersCommand,
  ListUsersCommandInput,
  StartUserImportJobRequest,
} from '@aws-sdk/client-cognito-identity-provider';
import { CognitoService } from '../../services/cognito.service';

interface IUserTable {
  fullname: string;
  email: string;
  address: string;
  phone: string;
  status: string;
  username: string;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit {
  loading: boolean;
  users: IUserTable[] = [];
  myEmail: string;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private cognitoService: CognitoService
  ) {
    this.loading = false;
    this.myEmail = '';
  }

  ngOnInit(): void {
    this.myEmail = localStorage.getItem('current_email') || '';
    this.loadAllUsers();
  }

  public disableUser(username: string): void {
    this.cognitoService.disableUser(username).then(
      (data) => {
        this.toastr.success('User was disabled with success');
        this.loadAllUsers();
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  public enableUser(username: string): void {
    this.cognitoService.enableUser(username).then(
      (data) => {
        this.toastr.success('User was enabled with success');
        this.loadAllUsers();
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  listUsers(client:CognitoIdentityProvider, params:ListUsersCommandInput, paginationToken:any): void{
    params.PaginationToken = paginationToken;
    const command = new ListUsersCommand(params);

    client.send(command).then(
      (data) => {
        data.Users?.forEach((user) => {
          let fullName: string = user.Username || '';
          let email: string = '';
          let address: string = '';
          let phone_numer: string = '';
          let status: string = 'Active';

          if (
            user.Attributes?.filter((f) => f.Name == 'given_name')[0] !=
            undefined
          ) {
            fullName =
              user.Attributes?.filter((f) => f.Name == 'given_name')[0].Value ||
              '';
            fullName +=
              ' ' +
                user.Attributes?.filter((f) => f.Name == 'family_name')[0]
                  .Value || '';
          }
          if (
            user.Attributes?.filter((f) => f.Name == 'email')[0] != undefined
          ) {
            email =
              user.Attributes?.filter((f) => f.Name == 'email')[0].Value || '';
          }
          if (
            user.Attributes?.filter((f) => f.Name == 'phone_number')[0] !=
            undefined
          ) {
            phone_numer =
              user.Attributes?.filter((f) => f.Name == 'phone_number')[0]
                .Value || '';
          }
          if (
            user.Attributes?.filter((f) => f.Name == 'address')[0] != undefined
          ) {
            address =
              user.Attributes?.filter((f) => f.Name == 'address')[0].Value ||
              '';
          }
          status = user.Enabled ? 'Enabled' : 'Disabled';

          if (email != this.myEmail) {
            this.users.push({
              fullname: fullName,
              email: email,
              status: status,
              address: address,
              phone: phone_numer,
              username: user.Username,
            } as IUserTable);
          }

          //call backend api
        });

        if (data.PaginationToken)
          this.listUsers(client, params, data.PaginationToken)
      },
      (error) => {
        let message = error.message as string;

        if (
          message.includes(
            'The security token included in the request is expired'
          )
        ) {
          this.cognitoService.signOut().then(() => {
            this.router.navigate(['/signIn']);
          });
        } else {
          this.toastr.error(error);
        }
      }
    );
  }

  public loadAllUsers(): void {
    this.users = [];
    const client = new CognitoIdentityProvider({
      region: environment.aws.region,
      credentials: {
        accessKeyId: localStorage.getItem(environment.keys.accessKey) || '',
        secretAccessKey: localStorage.getItem(environment.keys.secretKey) || '',
        sessionToken: localStorage.getItem(environment.keys.sessionToken) || '',
      },
    });
  
    this.loading = true;

    var params = {} as ListUsersCommandInput;

    params.Limit = 60;
    params.UserPoolId = environment.cognito.userPoolId;
    params.Filter;

    this.listUsers(client, params, params.PaginationToken);

  }
}
