import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  IUser,
  AdminCognitoService,
} from '../../services/admin-cognito.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-sign-in',
  templateUrl: './admin-sign-in.component.html',
  styleUrls: ['./admin-sign-in.component.css'],
})
export class AdminSignInComponent {
  loading: boolean;
  user: IUser;

  constructor(
    private router: Router,
    private cognitoService: AdminCognitoService,
    private toastr: ToastrService
  ) {
    this.loading = false;
    this.user = {} as IUser;
  }

  public signIn(): void {
    this.loading = true;
    this.cognitoService
      .adminSignIn(this.user)
      .then((u) => {
        this.router.navigate(['/userManagement']);
      })
      .catch((e) => {
        if (e == 'NEW_PASSWORD_REQUIRED') {
          this.toastr.success('You need to change tour password before login.');
          this.router.navigate(['/resetAdmPassword']);
        } else {
          this.toastr.error(e.message);
        }

        this.loading = false;
      });
  }
}
