<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>My Groups</h2>
    <button class="btn-add" type="submit" routerLink="/contractor-group">
      Add Group
    </button>
    <div class="columns-wrap">
      <div class="columns12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Group Id</th>
              <th scope="col">Season</th>
              <th scope="col">Name</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of gridData">
              <td>{{ row.id }}</td>
              <td>{{ row.season }}</td>
              <td>{{ row.name }}</td>
              <td></td>
              <td>
                <a
                  class="btn-small"
                  routerLink="/contractor-group"
                  routerLinkActive="active"
                  [queryParams]="{ id: row.ext_id }"
                  >Details</a
                >
              </td>
              <td>
                <a
                  class="btn-small"
                  routerLink="/link-group-block"
                  routerLinkActive="active"
                  [queryParams]="{ id: row.ext_id, name: row.name, season: row.season }"
                  >Add Blocks</a
                >
              </td>
              <td>
                <button
                  class="btn-small btn-left-space"
                  type="button"
                  (click)="yesNoDialog(row.ext_id)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
