import { AfterContentInit, Component, OnInit } from '@angular/core';

import { GroupService } from '../../services/group.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../../services/dialog.service';
import { IGroup } from '../../models/Group';
import { BlockScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-grower-groups-list',
  templateUrl: './grower-groups-list.component.html',
  styleUrls: ['./grower-groups-list.component.css'],
  providers: [GroupService],
})
export class GrowerGroupsListComponent implements AfterContentInit {
  gridData: any = [];
  group: IGroup;

  constructor(
    private groupApi: GroupService,
    private dialog: DialogService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.group = {} as IGroup;
  }

  yesNoDialog(id: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to decline this invitation?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        if (yes) {
          this.groupApi.decline_invitation(id).subscribe((s) => {

            if (s.body == 'ACCEPTED') {
              this.toastr.success('Invitation declined.');
              this.loadItems();
            } else {
              if (s.error) {
                this.toastr.error(
                  'Error updating invitation status. Error: ' +
                    s.error.errorMessage
                );
              } else {
                this.toastr.error(
                  'Error updating invitation status. Error: ' + s.errorMessage
                );
              }
            }
          });
        }
      });
  }

  addBlocks(id: string) {}

  loadItems(): any {
    this.gridData = [];
    this.groupApi.getGroupInvitations().subscribe((l) => {

      if (l.body) {
        l.body.forEach((i: any) => {

          if (i.invite.status == 'CONFIRMED') {
            let invited_by_email = '';
            for (let j = 0; j < i.owner.Attributes.length; j++) {
              if (i.owner.Attributes[j].Name == 'email') {
                invited_by_email = i.owner.Attributes[j].Value;
              }
            }
            this.gridData.push({
              group_id: i.group.id,
              ext_id: i.group.ext_id,
              group: i.group.name,
              season: i.group.season,
              blocks: '',
              status: i.invite.status,
              invited_by: invited_by_email,
              invited_at: i.invite.created_at,
            });
          }
        });
      }
    });
  }
  //this.availableBlocks = l.body
  ngAfterContentInit(): void {
    this.loadItems();
  }
}
