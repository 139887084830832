import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { ToastrService } from 'ngx-toastr';
import { IDatafields } from '../../models/DataFields';
import { InputPostData } from 'src/app/models/InputData'


@Component({
  selector: 'app-harvester-copy-season',
  templateUrl: './harvester-copy-season.component.html',
  styleUrls: ['./harvester-copy-season.component.css'],
  providers: [AdminService, HarvestService, InputPostData]
})
export class HarvesterCopySeasonComponent implements OnInit {
  @Input() harvesterData: any = {};
  @Input() harvesterId: any = {};
  harvester_id: string = '';
  season: string = '';
  iscopy: string = '';
  harvesterSeasonList: Array<any> = [];
  selectedHarvesterSeason: any = {};
  seasonList: any = [];
  millSeasonList: any = [];
  chopperBladesOptions: any = [6, 8, 10, 12];
  asabove?: boolean;
  saveButtonText = 'Add Harvester Season';
  showAddSeason: boolean = true;
  fixedCosts: any = 0;
  isHarvesterSelected: boolean = false;
  isUpdateHavesterSeason: boolean = false;
  dataPost: any = {};
  dataPre: any;
  selectedHarvester_ext_id: string = '';


  compensation_types: any = [
    { text: 'per tonne', value: 'tonnes' },
    { text: 'per hour', value: 'hours' },
  ];

  fieldNames: IDatafields[] = [
    { value: 'season', desc: 'Season' },
  ]

  constructor(
    private adminApi: AdminService,
    private harversterApi: HarvestService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private inputData: InputPostData,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.harvester_id = params['id'];
      this.season = params['season'];
      this.iscopy = params['iscopy'];
    })

    this.loadSeasons();
    this.loadMillSeason();
    this.dataPost = this.inputData.dataType;
    this.dataPre = this.inputData.dataType;
    this.loadHarvesterSeason();
  }


  loadHarvesterSeason(): any {
    this.harversterApi.get(this.harvester_id).subscribe((harvester) => {

      this.harvesterSeasonList = (harvester.seasons ?? []).filter(
        (obj: any) => obj.status == 'ACTIVE'
      );

      this.selectedHarvesterSeason.harvester_id = this.harvesterData.id;

      const restore = sessionStorage.getItem("harvester")
      if (restore) {
        this.selectedHarvesterSeason = JSON.parse(restore)
        sessionStorage.removeItem("harvester");
        
      }else{
        this.selectedHarvesterSeason = (harvester.seasons ?? []).find(
          (obj: any) => obj.season == this.season
        );
      }  

      this.selectedHarvesterSeason.saturday_rate = this.numberToPercent(this.selectedHarvesterSeason.saturday_rate);
      this.selectedHarvesterSeason.sunday_rate = this.numberToPercent(this.selectedHarvesterSeason.sunday_rate);
      this.selectedHarvesterSeason.rdo_rate = this.numberToPercent(this.selectedHarvesterSeason.rdo_rate);
      this.selectedHarvesterSeason.holiday_rate = this.numberToPercent(this.selectedHarvesterSeason.holiday_rate);
      this.selectedHarvesterSeason.extra_time_rate = this.numberToPercent(this.selectedHarvesterSeason.extra_time_rate);
      this.selectedHarvesterSeason.average_interest_paid = this.numberToPercent(this.selectedHarvesterSeason.average_interest_paid);


      this.selectedHarvesterSeason.created_at = null;
      this.selectedHarvesterSeason.updated_at = null;
      this.selectedHarvesterSeason.season = null;

      this.saveButtonText = 'Copied Harvester Season';
    });
  }

  loadSeasons(): any {
    this.adminApi.getSeasons().subscribe((l) => {

      if (l.body) {
        this.seasonList = l.body;
      }
    });
  }

  loadFixedCost(year: number): any {
    this.harversterApi.getFixedCost(year.toString()).subscribe((l) => {
      if (l.body) {
        this.fixedCosts = Math.trunc(l.body.total_cost_per_harvester) ?? 0;
        if (this.isHarvesterSelected && !this.isUpdateHavesterSeason) {
          this.selectedHarvesterSeason.fixed_costs_per_harvester = this.fixedCosts;
        }
      }
    });
  }

  onHarvesterSeasonChange(season: any) {
    this.loadFixedCost(Number(season))
  }

  validateFields(): any {
    let is_valid = true;
    this.fieldNames.forEach((e) => {
      if (!this.selectedHarvesterSeason[e.value] || this.selectedHarvesterSeason[e.value] == '') {
        this.toastr.warning(`Please enter the ${e.desc}!`);
        is_valid = false;
      }
    });
    return is_valid;
  }

  async create(endpoint: string, type: string, data: any): Promise<any> {
    this.harversterApi.create(endpoint, data).subscribe(
      data => {
        this.toastr.success(type + ' created successfully.');
        //location.href = `/harvester?id=${this.harvester_id}`;
      },
      error => {
        this.toastr.error(error.error.errorMessage)
      }
    );
  }


  async save(_: number) {
    let data = { ...this.selectedHarvesterSeason };

    data.season = parseInt(data.season, 10);
    delete data.harvester_id;
    delete data.owner_id;

    data.saturday_rate = this.percentToNumber(data.saturday_rate)
    data.sunday_rate = this.percentToNumber(data.sunday_rate)
    data.rdo_rate = this.percentToNumber(data.rdo_rate)
    data.holiday_rate = this.percentToNumber(data.holiday_rate)
    data.extra_time_rate = this.percentToNumber(data.extra_time_rate)
    data.average_interest_paid = this.percentToNumber(data.average_interest_paid)

    if (this.validateFields()) {
      if (this.selectedHarvesterSeason.season) {

        const endpoint = `harvester/${this.harvester_id}/seasons/${data.season}`;

        this.create(
          endpoint,
          'Harvester Season',
          JSON.stringify(data)
        ).then(
          (res) => {
            this.isUpdateHavesterSeason = true;
          }
        );

      }
    }
  }

  percentToNumber(input: number, precision: number = 2): number {
    return Number((input / 100).toFixed(precision + 2));
  }
  numberToPercent(input: number, precision: number = 2): number {
    return Number((input * 100).toFixed(precision + 2))
  }

  onChangeSeason(): void {
    this.millSeasonList = this.millSeasonList.filter(
      (obj: any) => obj.season == this.selectedHarvesterSeason.season
    );
  }

  loadMillSeason(): any {
    this.adminApi.getAll('/mills/season').subscribe((l) => {

      if (l.body) {
        this.millSeasonList = l.body.filter(
          (obj: any) => obj.status == 'ACTIVE'
        );
        this.millSeasonList = [{ 'fuel_price': '' }].concat(this.millSeasonList) // add empty row for dropdown menu 
      }
    });
  }

   saveTemporarily(): void{
    sessionStorage.setItem("harvester",JSON.stringify(this.selectedHarvesterSeason));
  }

}
