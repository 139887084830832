import { Component, OnInit, isDevMode } from '@angular/core';
import { HarvestService } from 'src/app/services/harvest.service';
import { IHarvester } from '../../models/Harvester';
import { IDatafields } from '../../models/DataFields';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { InputPostData } from 'src/app/models/InputData';

@Component({
  selector: 'app-harvest-details',
  templateUrl: './harvest-details.component.html',
  styleUrls: ['./harvest-details.component.css'],
  providers: [HarvestService, InputPostData],
})
export class HarvestDetailsComponent implements OnInit {
  id: string = '';
  harvester: any = {};
  harvesterModels: any = [];
  wheelTrackOptions: any = [];
  feedtrainOptions: any = [];
  extractorFans: any = [];
  allFans: any = [];
  Counter: number = 0;
  dataPost: any = {};
  current_manufactured_date: any = ""; 
  seasons = [2020, 2021, 2022, 2023, 2024, 2025, 2026];

  yearsList = new Date().getFullYear();
  years = [
    this.yearsList - 6,
    this.yearsList - 5,
    this.yearsList - 4,
    this.yearsList - 3,
    this.yearsList - 2,
    this.yearsList - 1,
    this.yearsList,
    this.yearsList + 1,
    this.yearsList + 2,
    this.yearsList + 3,
    this.yearsList + 4,
    this.yearsList + 5,
    this.yearsList + 6,
  ]; 

  fieldNames: IDatafields[] = [
    { value: 'model_name', desc: 'Harvester' },
    { value: 'feedtrain_setup', desc: 'Feedtrain Setup' },
    { value: 'undercarriage', desc: 'Wheels/Tracks' },
    { value: 'extractor_fan_config_id', desc: 'Primary Extractor Fan' },
  ];



  constructor(
    private harverstApi: HarvestService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private inputData: InputPostData
  ) {
    this.harvesterModels = [];
    this.wheelTrackOptions = ['wheels', 'tracks'];
    this.feedtrainOptions = ['slow', 'intermediate', 'standard', 'optimised'];
    this.extractorFans = [];
    this.harvester = {} as IHarvester;
  }

   loadModels(): any{
    this.harverstApi.getAll('harvester/models').subscribe((l)=>{
      if (l.body){
        this.harvesterModels = l.body;
      }
    });

  }
  loadConfigs(): any {
    this.harverstApi.getAll('/harvester/fan-configs').subscribe((l) => {

      if (l.body){
        this.allFans = l.body
        if (this.extractorFans.length == 0){
          this.extractorFans = this.allFans;
        }      
      } else if (l.message) {
        this.toastr.error(l.body.errorMessage);
      } else if (l.error) {
        this.toastr.error(l.body.errorMessage);
      } else {
      }
    });
  }

  countChangedHandler(event: number, variableName: string) {
    this.harvester[variableName] = event;
  }

  changeModel(model_id: number) {

    const selectedModel = this.harvesterModels.find(
      (obj: any) => obj.id == Number(model_id)
    );

    this.extractorFans = this.allFans.filter(
      (obj: any) => obj.brand_id == selectedModel.brand_id
    );

    // Set extractor if if length is > 0
    this.harvester.extractor_fan_config_id =
      this.extractorFans.length && this.extractorFans[0].id;
  }

  async ngOnInit() {
    await this.loadModels();
    await this.loadConfigs();
    this.dataPost = this.inputData.dataType;
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        //load details
        this.harverstApi.get(this.id).subscribe((harvester) => {
          this.harvester = {
            ext_id: this.id,
            model_name: harvester.model_name,
            model_id: harvester.model_id,
            feedtrain_setup: harvester.feedtrain_setup,
            extractor_fan_config_id: harvester.extractor_fan_config_id,
            undercarriage: harvester.undercarriage,
            model: harvester.model
          } as IHarvester;
          this.current_manufactured_date = new Date(harvester.manufactured_date).getFullYear();
          this.extractorFans = this.allFans.filter(
            (obj: any) => obj.brand_id === harvester.model.brand_id
          );
        });
      }
    });
  }

  async save(): Promise<any> {
    let is_valid = false;
    if (this.harvester.model_id) {
      var selectedModelObject = this.harvesterModels.find(
        (obj: any) => obj.id == Number(this.harvester.model_id)
      );
      this.harvester.model_name = selectedModelObject.model_name;
    }

    this.fieldNames.forEach((e) => {
      if (!this.harvester[e.value]) {
        this.toastr.warning(`Please select the ${e.desc}!`);
        return (is_valid = false);
      } else {
        return (is_valid = true);
      }
    });
    if (!this.harvester.extractor_fan_config_id) {
      this.toastr.error('Please select a Primary Extractor Fan.');
      is_valid = false;
    }
    if (!is_valid) {
      return;
    }
    let selectedFanObject = this.extractorFans.find(
      (obj: any) => obj.id == Number(this.harvester.extractor_fan_config_id)
    );
    if (selectedFanObject == undefined || selectedFanObject.name == undefined) {
      this.harvester.extractor_fan_config_name = "";
    } else {
      this.harvester.extractor_fan_config_name = selectedFanObject.name;
    }

    this.harvester.manufactured_date = new Date(this.current_manufactured_date, 1, 1)
    // this.harvester.manufactured_date += '-01-01'; // add days to month date format
    // this.harvester.manufactured_date = this.harvester.manufactured_date.slice(
    //   0,
    //   -3
    // ); //cut last 3 char (days) so it fits month date format
    if (!this.harvester.ext_id) {
      delete this.harvester.model

      this.harverstApi
        .create('harvester', JSON.stringify(this.harvester))
        .subscribe((h) => {
          if (h.body.id) {
            this.toastr.success('Harvester created successfully.');
            this.harvester = {};
          } else {
            this.toastr.error(h.body.errorMessage);
          }
        });
    } else {
      delete this.harvester.model

      const h = await this.harverstApi
        .update(
          'harvester/' + this.harvester.ext_id,
          JSON.stringify(this.harvester)
        )
      if (h.body) {
        this.toastr.success('Harvester update successfully.');
      } else {
        this.toastr.error(h.body.errorMessage);
      }
      };
  }
}
