import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser, CognitoService } from '../services/cognito.service';
import { ToastrService } from 'ngx-toastr';
import { GroupService } from '../services/group.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [GroupService],
})
export class HomeComponent implements OnInit {
  loading: boolean;
  user: IUser;
  isAdmin: boolean;
  isGrower: boolean;
  isContrator: boolean;
  groupLink: string;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private toastr: ToastrService,
    private groupApi: GroupService
  ) {
    this.loading = false;
    this.user = {} as IUser;
    this.isAdmin = false;
    this.isContrator = false;
    this.isGrower = false;
    this.groupLink = '';
  }

  ngOnInit(): void {
    //check if access token is more than 24h old, if it is log out and then go to log in
    const now = new Date();
    if (
      localStorage.getItem(environment.keys.date) != now.toLocaleDateString()
    ) {
      this.cognitoService.signOut().then(() => {
        this.router.navigate(['/signIn']);
      });
    }

    this.cognitoService.getUser().then((user: any) => {
      //if no user was found sent to login again
      if (user == null) {
        this.router.navigate(['/signIn']);
      } else {
        this.isContrator = user.attributes['custom:user_type'] == 'CONTRACTOR';
        this.isGrower = user.attributes['custom:user_type'] == 'GROWER';
        this.isAdmin = user.attributes['custom:user_type'] == 'ADMIN';
        if (this.isContrator) {
          this.groupLink = '/contractor-groups';
        } else {
          this.groupLink = '/grower-groups';
        }
        this.user = user.attributes;
        if (this.isGrower) {
          this.alertPendingInvitations();
        }
      }
    });
  }

  public update(): void {
    this.loading = true;

    this.cognitoService
      .updateUser(this.user)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  public alertPendingInvitations(): void {
    this.groupApi.getGroupInvitations().subscribe((list) => {
      let r = list.body.filter(
        (inv: any) => inv.status == 'PENDING' && inv.role == 'receiver'
      );
      if (r.length) {
        this.toastr
          .warning(
            'You have pending invitations click here to accept/decline',
            ''
          )
          .onTap.subscribe(() => this.router.navigate(['/group-invitations']));
      }
    });
  }
}
