import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IUser, CognitoService } from '../services/cognito.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent {
  loading: boolean;
  isConfirm: boolean;
  user: IUser;
  confirmPassword: string = '';
  acceptTermsOfUse = false;
  acceptPrivacyPolicy = false;


  // signupForm: FormGroup;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private toastr: ToastrService
  ) {
    this.loading = false;
    this.isConfirm = false;
    this.user = {} as IUser;
    // this.createForm();
  }

  public async signUp(): Promise<any> {
    let is_valid = true;
    let psw_valid = true;

    if (!this.acceptPrivacyPolicy) {
      this.toastr.error("You must accept the privacy policy to sign up.")
      is_valid = false;
    }

    if (!this.acceptTermsOfUse) {
      this.toastr.error("You must accept the terms of use to sign up");
      is_valid = false;
    }

    if (!this.user.givenName) {
      this.toastr.warning('Please enter Given Name');
      is_valid = false;
    }
    if (!this.user.lastName) {
      this.toastr.warning('Please enter Last Name');
      is_valid = false;
    }
    if (!this.user.email) {
      this.toastr.warning('Please enter Email');
      is_valid = false;
    }
    if (!this.user.phoneNumber) {
      this.toastr.warning('Please enter Phone number');
      is_valid = false;
    }
    if (!this.user.password) {
      this.toastr.warning('Please enter Password');
      is_valid = false;
    }
    if (!this.confirmPassword) {
      this.toastr.warning('Please Confirm Password');
      is_valid = false;
      psw_valid = false;
    }
    if (psw_valid == true) {
      if (this.confirmPassword != this.user.password) {
        this.toastr.warning('Two passwords must match');
        is_valid = false;
      }
    }

    if (is_valid) {
      this.loading = true;
      this.cognitoService
        .signUp(this.user)
        .then(() => {
          this.loading = false;
          this.isConfirm = true;
          this.router.navigate(['/confirmSignUp'], {
            queryParams: { email: this.user.email },
          });
          this.toastr.success('User created, please insert verification code');
        })
        .catch((e) => {
          this.toastr.error(e.message);
          this.loading = false;
        });
    }
  }
}
