<div id="login">
  <div class="login-inner text-white scrollbar">
    <div class="content-padding">
      <div class="login-header">
        <!-- <h1 class="login-title"><span><strong>GGG <br/>Mate</strong></span></h1> -->
        <img src="images/harvest_mate_logo_white.png" />
      </div>

      <div class="login-box">
        <div id="form-signup" class="login-form form-open">
          <h2 class="h4">Forgot Password</h2>
          <form>
            <div class="fieldwrap fieldwrap_type_input">
              <label class="label-ontop">Email</label>
              <input type="email" id="email" name="email" #email="ngModel" [(ngModel)]="user.email" />
            </div>
            <div class="fieldwrap fieldwrap_type_submit">
              <button type="submit" (click)="sendPasswordCode()">
                Send reset password code
              </button>
              <a class="btn__secondary btn-login" routerLink="/signIn">Back</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>