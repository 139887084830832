<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>Machinery & Labour Setup</h2>
    <button class="btn-add" type="submit" routerLink="/harvester">
      Add Harvester
    </button>
    <div class="columns-wrap">
      <div class="columns12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Harvester</th>
              <th scope="col">Age (years)</th>
              <th scope="col">Wheels/Tracks</th>
              <th scope="col">Feedtrain Setup</th>
              <th scope="col">Extractor Fan</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody *ngFor="let row of gridData">
            <tr>
              <td>{{ row.harvester }}</td>
              <td>{{ row.age }}</td>
              <td>{{ row.tracks }}</td>
              <td>{{ row.feedtrain }}</td>
              <td>{{ row.extractor_config }}</td>
              <td>
                <a
                  class="btn-small"
                  routerLink="/harvester"
                  routerLinkActive="active"
                  [queryParams]="{ id: row.ext_id }"
                  >Details</a
                >
                <button
                  class="btn-small btn-left-space"
                  type="button"
                  (click)="yesNoDialog(row.ext_id)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
