<div class="content-1920">
  <div class="content-padding margin-top-small">
    <h2>{{ tabHeader }}</h2>
    <div class="columns-wrap" *ngIf="tabHeader == 'Repair And Maintenance Items'">
      <div class="columns12">
        <fieldset>
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline align-left">
                <label>Selected Season(s)</label>
                <select id="Type" name="Type" #type="ngModel" [(ngModel)]="selectedSeason" (change)="currentSeason()">
                  <option *ngFor="let e of seasonList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Season Start</th>
            <th scope="col">Season End</th>
            <th scope="col">Type</th>
            <th scope="col">Frequency Type</th>
            <th scope="col">Escalation Criteria</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of itemsList">
            <td>{{ row.name }}</td>
            <td>{{ row.season_coverage_start }}</td>
            <td>{{ row.season_coverage_end }}</td>
            <td>{{ row.type }}</td>
            <td>{{ row.cost_frequency_type }}</td>
            <td>{{ row.escalation_criteria }}</td>
            <td>
              <button class="btn-small btn-left-space" type="button" (click)="edit(row.id, row.name)">
                Edit
              </button>
            </td>
            <td>
              <button class="btn-small btn-left-space" type="button" s (click)="duplicate(row.id, row.name)">
                Duplicate
              </button>
            </td>
            <td>
              <button class="btn-small btn-left-space" type="button" (click)="deleteDialog(row.id)"
                style="color: rgb(255, 0, 0)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <a class="btn__secondary" (click)="changeTabs('Items')">Items</a>
      <a class="btn__secondary" (click)="changeTabs('Brands')">Brands</a>
      <fieldset>
        <div class="columns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Name</label>
                <input type="string" id="Name" name="Name" #Name="ngModel" [(ngModel)]="repairMaintenance.name" />
              </div>
              <div class="fieldwrap label-inline" *ngIf="flag1">
                <label>Type</label>
                <select id="Type" name="Type" #type="ngModel" [(ngModel)]="repairMaintenance.type">
                  <option *ngFor="let e of typesList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Season Coverage Start</label>
                <input type="number" id="season_coverage_start" name="season_coverage_start"
                  #season_coverage_start="ngModel" [(ngModel)]="repairMaintenance.season_coverage_start" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Season Coverage End</label>
                <input type="number" id="season_coverage_end" name="season_coverage_end" #season_coverage_end="ngModel"
                  [(ngModel)]="repairMaintenance.season_coverage_end" />
              </div>
              <div class="fieldwrap label-inline">
                <label>Cost And Frequency Type</label>
                <select id="cost_frequency_type" name="cost_frequency_type" #cost_frequency_type="ngModel"
                  [(ngModel)]="repairMaintenance.cost_frequency_type">
                  <option *ngFor="let e of costAndFrequencyTypeList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Escalation Criteria</label>
                <select id="escalation_criteria" name="escalation_criteria" #escalation_criteria="ngModel"
                  [(ngModel)]="repairMaintenance.escalation_criteria">
                  <option *ngFor="let e of escalationCriteriaList" value="{{ e.value }}">
                    {{ e.label }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Escalation Impact</label>
                <app-number-input [dataPost]="dataPost.percent" [(numberInput)]="repairMaintenance.escalation_impact">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <label>Practice Effect</label>
                <select id="practice_affect" name="practice_affect" #practice_affect="ngModel"
                  [(ngModel)]="repairMaintenance.practice_affect">
                  <option *ngFor="let e of practiceAffectList" value="{{ e.value }}">
                    {{ e.label }}
                  </option>
                </select>
              </div>
              <!-- <div class="fieldwrap label-inline"></div> -->
              <div class="fieldwrap label-inline">
                <div class="toggle">
                  <input id="age_related" type="checkbox" [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="repairMaintenance.age_related" />
                  <label for="age_related">Do you wish to add age related options?</label>
                </div>
              </div>
              <div class="fieldwrap label-inline"></div>
              <div class="fieldwrap label-inline" *ngIf="repairMaintenance.age_related == true">
                <label>New Machine Age Factor</label>
                <app-number-input [dataPost]="dataPost.number" [numberInput]="repairMaintenance.new_machine_age_factor"
                  (numberInputChange)="
                    countChangedHandler($event, 'new_machine_age_factor')
                  ">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline" *ngIf="repairMaintenance.age_related == true">
                <label>Old Machine Age Factor</label>
                <app-number-input [dataPost]="dataPost.number" [numberInput]="repairMaintenance.old_machine_age_factor"
                  (numberInputChange)="
                    countChangedHandler($event, 'old_machine_age_factor')
                  ">
                </app-number-input>
              </div>
              <div class="fieldwrap label-inline">
                <div class="toggle">
                  <input id="selectable" type="checkbox" [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="repairMaintenance.selectable" />
                  <label for="selectable">Do you wish to add selectables options?</label>
                </div>
              </div>
              <div class="fieldwrap label-inline"></div>
              <div *ngIf="repairMaintenance.selectable == true">
                <div class="fieldwrap label-inline">
                  <label>Sorting Priority</label>
                  <app-number-input [dataPost]="dataPost.priority" [numberInput]="repairMaintenance.sort_priority"
                    (numberInputChange)="
                      countChangedHandler($event, 'sort_priority')
                    ">
                  </app-number-input>
                </div>
                <div class="fieldwrap label-inline"></div>
                <fieldset>
                  <ng-container [formGroup]="form">
                    <ng-container formArrayName="selectables" *ngFor="
                        let selectable of selectables.controls;
                        index as i
                      ">
                      <ng-container [formGroupName]="i">
                        <div class="fieldwrap label-inline">
                          <label for="phone">Name:</label>
                          <select id="selectableName" name="selectableName" formControlName="selectableName"
                            [(ngModel)]="names[i]">
                            <option *ngFor="let e of periodList" value="{{ e }}">
                              {{ e }}
                            </option>
                          </select>
                          <label for="email">Affect:</label>
                          <input type="string" id="Affect" name="Affect" formControlName="Affect"
                            [(ngModel)]="affect[i]" />
                          <br /><br />
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </fieldset>
              </div>
            </div>
            <div class="fieldwrap label-inline">
              <button class="btn-save" type="submit" (click)="save()">
                {{ itemsText }}
              </button>
              <button class="btn-save" type="submit" (click)="cancel()">
                Reset
              </button>
              <button class="btn-remove" type="submit" (click)="addContactField()"
                *ngIf="repairMaintenance.selectable == true">
                Add Selectables
              </button>
              <button class="btn-remove" type="submit" (click)="removeContactField()"
                *ngIf="repairMaintenance.selectable == true">
                X
              </button>
            </div>
          </form>
        </div>
      </fieldset>
    </div>

    <div class="columns-wrap" *ngIf="tabHeader == 'Repair And Maintenance Brands'">
      <div class="columns12">
        <fieldset>
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline align-left">
                <label>Selected Season(s)</label>
                <select id="Type" name="Type" #type="ngModel" [(ngModel)]="selectedSeason" (change)="currentSeason()">
                  <option *ngFor="let e of seasonList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Frequency Type</th>
            <th scope="col">Escalation Criteria</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of itemsList">
            <td>{{ row.name }}</td>
            <td>{{ row.type }}</td>
            <td>{{ row.cost_frequency_type }}</td>
            <td>{{ row.escalation_criteria }}</td>
            <td>
              <button class="btn-small btn-left-space" type="button" (click)="edit(row.id, row.name)">
                Edit
              </button>
            </td>
            <td>
              <button class="btn-small btn-left-space" type="button" s (click)="duplicate(row.id, row.name)">
                Duplicate
              </button>
            </td>
            <td>
              <button class="btn-small btn-left-space" type="button" (click)="deleteDialog(row.id)"
                style="color: rgb(255, 0, 0)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <a class="btn__secondary" (click)="changeTabs('Items')">Items</a>
      <a class="btn__secondary" (click)="changeTabs('Brands')">Brands</a>
      <fieldset>
        <div class="columns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <fieldset>
                  <ng-container [formGroup]="formBrands">
                    <ng-container formArrayName="brands" *ngFor="let brand of brands.controls; index as j">
                      <ng-container [formGroupName]="j">
                        <div class="fieldwrap label-inline">
                          <label for="brand">Brand:</label>
                          <select id="brandID" name="brandID" formControlName="brandID" [(ngModel)]="brandID[j]">
                            <option *ngFor="let e of brandsLists" value="{{ e.ext_id }}">
                              {{ e.name }}
                            </option>
                          </select>
                          <label for="cost">Cost:</label>
                          <input type="number" id="Cost" name="Cost" formControlName="Cost" [(ngModel)]="cost[j]" />
                          <label for="frequency">Frequency ({{
                            repairMaintenance.cost_frequency_type
                            }}):</label>
                          <input type="number" id="Frequency" name="Frequency" formControlName="Frequency"
                            [(ngModel)]="frequency[j]" />
                          <br /><br />
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </fieldset>
              </div>
            </div>
            <div class="fieldwrap label-inline">
              <button class="btn-save" type="submit" (click)="save()">
                {{ itemsText }}
              </button>
              <button class="btn-save" type="submit" (click)="cancel()">
                Reset
              </button>
              <button class="btn-remove" type="submit" (click)="addBrandField()">
                Add Brand
              </button>
              <button class="btn-remove" type="submit" (click)="removeBrandsField()">
                X
              </button>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
  </div>
</div>

<div id="show_results" [ngClass]="{ 'overlay open': show_results_dialog_open }">
  <div class="modal-box">
    <button class="close" type="button" (click)="show_results_dialog_open = false"></button>
    <div class="modal-header">
      <h2>Duplicate Repair And Maintenance Item</h2>
      <h4>{{repairMaintenance.name}}</h4>
      <h5>Covers seasons: {{repairMaintenance.season_coverage_start}} - {{repairMaintenance.season_coverage_end}}</h5>
    </div>
    <form>
      <fieldset>
        <div class="fieldwrap label-inline">
          <label>Escalation Impact</label>
          <input id="age_related" type="input" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="repairMaintenance.escalation_impact" />
        </div>
        <div class="fieldwrap label-inline">
          <label>Practice affect</label>
          <input id="age_related" type="input" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="repairMaintenance.practice_affect" readonly />
        </div>
        <div class="fieldwrap label-inline">
          <label>Season Start Year</label>
          <input type="number" id="selectStartYear" name="selectStartYear" #selectstartyear="ngModel"
            [(ngModel)]="selectStartYear">
        </div>
        <div class="fieldwrap label-inline">
          <label>Season End Year</label>
          <input type="number" id="selectEndYear" name="selectEndYear" #selectendyear="ngModel"
            [(ngModel)]="selectEndYear">
        </div>
        <p class="h3" *ngIf="repairMaintenance.age_related">
          Age Related Options
        </p>
        <div class="fieldwrap label-inline" *ngIf="repairMaintenance.age_related">
          <label>New Machine Age Factor</label>
          <input type="number" id="season" name="season" #season="ngModel"
            [(ngModel)]="repairMaintenance.new_machine_age_factor" />
          <!-- <app-number-input [dataPost]="dataPost.number"
                        [numberInput]="repairMaintenance.new_machine_age_factor"
                        (numberInputChange)="countChangedHandler($event, 'new_machine_age_factor')">
                    </app-number-input> -->
        </div>
        <div class="fieldwrap label-inline" *ngIf="repairMaintenance.age_related">
          <label>Old Machine Age Factor</label>
          <input type="number" id="season" name="season" #season="ngModel"
            [(ngModel)]="repairMaintenance.old_machine_age_factor" />
        </div>
        <p class="h3" *ngIf="repairMaintenance.selectable">
          Selectables Options
        </p>
        <ng-container [formGroup]="form">
          <ng-container formArrayName="selectables" *ngFor="let selectable of selectables.controls; index as i">
            <ng-container [formGroupName]="i">
              <div class="fieldwrap label-inline">
                <label for="phone">Name:</label>
                <select id="selectableName" name="selectableName" formControlName="selectableName"
                  [(ngModel)]="names[i]" [attr.disabled]="true">
                  <option *ngFor="let e of periodList" value="{{ e }}">
                    {{ e }}
                  </option>
                </select>
                <label for="email">Affect:</label>
                <input type="string" id="Affect" name="Affect" formControlName="Affect" [(ngModel)]="affect[i]" />
                <br /><br />
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <p class="h3" *ngIf="repairMaintenance.brands">Brands</p>
        <ng-container [formGroup]="formBrands">
          <ng-container formArrayName="brands" *ngFor="let brand of brands.controls; index as j">
            <ng-container [formGroupName]="j">
              <div class="fieldwrap label-inline">
                <label for="brand">Brand:</label>
                <select id="brandID" name="brandID" formControlName="brandID" [(ngModel)]="brandID[j]"
                  [attr.disabled]="true">
                  <option *ngFor="let e of brandsLists" value="{{ e.ext_id }}">
                    {{ e.name }}
                  </option>
                </select>
                <label for="cost">Cost:</label>
                <input type="number" id="Cost" name="Cost" formControlName="Cost" [(ngModel)]="cost[j]" />
                <br /><br />
              </div>
              <!-- <div class="fieldwrap label-inline">
                                      <label for="frequency">Frequency ({{repairMaintenance.cost_frequency_type}}):</label>
                                      <input
                                      type="number"
                                      id="Frequency"
                                      name="Frequency"
                                      formControlName="Frequency"
                                      [(ngModel)]="frequency[j]"
                                      />
                                      <br/><br/>   
                                  </div> -->
            </ng-container>
          </ng-container>
        </ng-container>
        <div class="columns4">
          <div class="fieldwrap fieldwrap-type-submit">
            <button class="btn-save" type="submit" (click)="show_results_dialog_open = false">
              Close
            </button>
            <button (click)="save(); show_results_dialog_open = false" class="btn__secondary" type="button">
              Create Season
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>