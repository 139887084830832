<div id="body">
  <div id="header" *ngIf="isAuthenticated">
    <div class="body-cell">
      <div class="content-1920">
        <div class="content-padding">
          <div class="header-buttons-left">
            <img src="images/harvest_mate_logo_colour.png" width="300" />
          </div>

          <div class="header-buttons">
            <div class="header-menu">
              <a class="btn__header btn-home" routerLink="/" title="Home"></a>
              <a *ngIf="isContrator" class="btn__header btn-machinery" routerLink="/harvesters"
                title="Machinery & Labour Setup"></a>
              <a *ngIf="isContrator" class="btn__header btn-annual" routerLink="/contractor-groups"
                title="Group & Operational Setup"></a>
              <a *ngIf="isContrator" class="btn__header btn-infield" href="/farms" title="Infield Logistics"></a>
              <a *ngIf="isGrower" class="btn__header btn-infield" href="/farms" title="Infield Logistics"></a>
              <a *ngIf="isGrower" class="btn__header btn-annual" routerLink="/grower-groups"
                title="Group & Operational Setup"></a>
              <a *ngIf="isGrower" class="btn__header btn-contract" routerLink="/contract-negotiation"
                title="Comparison Scenarios"></a>
              <a class="btn__header btn-info" routerLink="/about" title="About"></a>
            </div>
            <button class="btn__header btn-menu"></button>
            <a class="profile__icon modal-profile" href="javascript:void()" title="{{ fullName }}">{{ shortName }}</a>
          </div>

          <div class="profile__menu">
            <a class="profile__header" href="javascript:void(0)">
              <span class="profile__icon">{{ shortName }}</span>
              <span class="profile__name">{{ user.givenName }} {{ user.lastName }}</span>
              <span class="profile__view">View Profile</span>
            </a>
            <!-- <a *ngIf="isContrator" routerLink="/fixed-cost">Fixed Cost Calculator</a> -->
            <a *ngIf="isContrator" routerLink="/harvesters">My Harvesters</a>
            <a *ngIf="isGrower" routerLink="/farms">Farm Management</a>
            <a *ngIf="isGrower" routerLink="/group-invitations">Group Invitations</a>
            <a *ngIf="isAdmin" routerLink="/admin/userManagement">User Management</a>
            <a *ngIf="isAdmin" routerLink="/admin/harvesterManagement">Harvester Management</a>
            <a *ngIf="isAdmin" routerLink="/admin/regionManagement">Region Management</a>
            <a *ngIf="isAdmin" routerLink="/admin/cropManagement">Variety Management</a>
            <a *ngIf="isAdmin" routerLink="/admin/seasonManagement">Season Management</a>
            <a *ngIf="isAdmin" routerLink="/admin/repairMaintenance">Repair And Maintenance</a>
            <a href="javascript:void(0)" (click)="signOut()">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="content">
    <router-outlet></router-outlet>
  </div>
  <div id="footer">
    <div class="body-cell">
      <div class="content-1920">
        <div class="content-padding">
          <p class="copyright">
            &copy; 2022 Copyright 2022 by Sugar Research Australia Ltd. &ensp;
            <br>
            &ensp; This tool was developed by
            <strong>Sugar Research Australia</strong> and the
            <strong>Department of Agriculture & Fisheries.</strong>
            <br>
            <br>
            <strong>All rights reserved.</strong> No part of Harvest Mate may be reproduced, stored in a retrieval
            system,
            or transmitted <br>in any form or by any means, electronic, mechanical, photocopying, recording,
            or otherwise, without the prior <br> permission of Sugar Research Australia Ltd.
          </p>
          <div class="header-buttons">
            <a class=" image_footer" href="https://www.qld.gov.au/"><img src="images/queensland_government_white.png"
                width="170" /></a>
            <img class="image_footer" src="images/logo-sra.svg" width="170" />
          </div>
        </div>
      </div>
      <div style="padding: 10px 10px;">
        <a href="assets/HarvestMateTM Terms of Use.docx" style="padding: 10px;">Terms of Use</a>
        <a href="https://sugarresearch.com.au/about/policies/privacy-policy-3/" target="blank" style="padding: 10px;">Privacy Policy</a>
      </div>
    </div>
  </div>
</div>