import { Component, OnInit } from '@angular/core';
import {
  moveItemInArray,
  transferArrayItem,
  CdkDragStart,
} from '@angular/cdk/drag-drop';

import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FarmService } from '../../services/farm.service';
import { BlockService } from '../../services/block.service';
import { GroupService } from '../../services/group.service';
import { DialogService } from '../../services/dialog.service';

import {  cloneDeep } from 'lodash';
import { LoggingService } from 'src/app/services/logging.service';
import { CognitoService } from 'src/app/services/cognito.service';

@Component({
  selector: 'app-group-grower-block',
  templateUrl: './group-grower-block.component.html',
  styleUrls: ['./group-grower-block.component.css'],
  providers: [FarmService, BlockService, GroupService],
})
export class GroupGrowerBlockComponent implements OnInit {
  representative_areas: any = [];
  is_processing_calculations: boolean = false;
  group_id: string;
  cognito_id: string;
  allowance_open: boolean = false;
  group: string;
  farm: any;
  block: any = {};
  block_allowance: any = {};
  season: string;
  selectedFarm: string;
  growersData: any = [];
  blocksData: any = [];
  dataPost: any = {};
  weight_percentage: number = 0;


  isGrower?: boolean;
  
  availableFarms: any = [];
  availableBlocks: any = [];
  assignedBlocks: any = [];
  availableSeasons: any = [];
  dragging: boolean;
  dialog_open: boolean = false;
  average_dialog_open: boolean = false;
  block_results: any[] = [];
  total_representative_area: number = 0;
  total_selected_blocks: number = 0;

  avg_net_benefit: number = 0;
  avg_yield_change: number = 0;
  avg_yield_std: number = 0;
  avg_yield_pred: number = 0;
  avg_ccs_std: number = 0;
  avg_ccs_pred: number = 0;
  avg_fan_speed_std: number = 0;
  avg_fan_speed_pred: number = 0;
  avg_ground_speed_std: number = 0;
  avg_ground_speed_pred: number = 0;
  hours_per_day_cutting_std: number = 0;
  hours_per_day_cutting_pred: number = 0;
  hours_per_day_total_std: number = 0;
  hours_per_day_total_pred: number = 0;
  flow_rate_std: number = 0;
  flow_rate_pred: number = 0;
  bin_weight_std: number = 0;
  bin_weight_pred: number = 0;
  total_cost_change_t: number = 0;
  total_cost_change_hr: number = 0;
  total_cost_change_ha: number = 0;
  rankings: any[] = ["","⭐", ...[...Array(26)].map((_, i) => String.fromCharCode(i + 65))]

  payload: any = {};
  payload_save_results: any = {
    group_id: '',
    items: [],
  };

  constructor(
    private farmApi: FarmService,
    private blockApi: BlockService,
    private groupApi: GroupService,
    private dialog: DialogService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private logging: LoggingService,
    private auth: CognitoService
  ) {
    this.group_id = '';
    this.group = '';
    this.cognito_id = '';
    this.dragging = false;
    this.season = '2022';
    this.selectedFarm = '';
    this.allowance_open = false;

  }

  showAllowance(block_number: string): void {
    this.assignedBlocks.forEach((f: any) => {
      if (f.block_number == block_number) {
        this.block_allowance = f.allowance;
        this.block = block_number;
      }
    });
    this.allowance_open = true;
  }

  calculate_average(): void {
    this.average_dialog_open = true;
    this.avg_net_benefit = 0;
    this.avg_yield_change = 0;
    this.avg_yield_std = 0;
    this.avg_yield_pred = 0;
    this.avg_ccs_std = 0;
    this.avg_ccs_pred = 0;
    this.avg_fan_speed_std = 0;
    this.avg_fan_speed_pred = 0;
    this.avg_ground_speed_std = 0;
    this.avg_ground_speed_pred = 0;
    this.hours_per_day_cutting_std = 0;
    this.hours_per_day_cutting_pred = 0;
    this.hours_per_day_total_std = 0;
    this.hours_per_day_total_pred = 0;
    this.flow_rate_std = 0;
    this.flow_rate_pred = 0;
    this.bin_weight_std = 0;
    this.bin_weight_pred = 0;
    this.total_cost_change_ha = 0;
    this.total_cost_change_hr = 0;
    this.total_cost_change_t = 0;

    this.total_selected_blocks = 0;
    this.assignedBlocks.forEach((block: any) => {
      if (Number(block.block_representative_area) > 0 && block.selected_result) {
        this.avg_net_benefit +=
          block.selected_result.block_economic_results_grower.net_benefit
        this.avg_yield_pred +=
          block.selected_result.block_agronomic_results.yield_pred;
        this.avg_fan_speed_std +=
          block.selected_result.block_agronomic_results.fan_speed_std;
        this.avg_fan_speed_pred +=
          block.selected_result.block_agronomic_results.fan_speed_pred;
        this.avg_ground_speed_pred +=
          block.selected_result.block_agronomic_results.ground_speed_pred;
        this.hours_per_day_total_pred +=
          block.selected_result.block_agronomic_results.hours_per_day_total_pred;
        this.flow_rate_pred +=
          block.selected_result.block_agronomic_results.flow_rate_pred;
        this.bin_weight_pred +=
          block.selected_result.block_agronomic_results.bin_weight_pred;
        this.total_cost_change_ha += block.selected_result.block_economic_results_grower.cost_change_ha;
        this.total_cost_change_hr += block.selected_result.block_economic_results_grower.cost_change_hr;
        this.total_cost_change_t += block.selected_result.block_economic_results_grower.cost_change_t;
        this.total_selected_blocks++;
      }
    });
  }

  saveBlockAllowance() {
    this.total_representative_area = 0;


    this.assignedBlocks.forEach((block: any) => {

      if (block.block_number == this.block) {
        block.topping = this.payload.std_topping;
        block.allowance = this.block_allowance;
        block.block_representative_area =
          this.block_allowance.total_repesentative_area;

        if (block.allowance.total_average_allowance_per_tone > 0) {
          this.payload.allowance = block.allowance.total_average_allowance_per_tone;
        }

        if (this.payload) {

          this.is_processing_calculations = true;
          this.blockApi
            .calculateBlock(this.payload)
            .subscribe({
              next: (s) => {
                this.is_processing_calculations = false;
                let results: any[] = s.body;
                // [].sort()
                let std_result: any = cloneDeep(results[0]);
                const minNetBenefit = 30;

                this.block_results = results.filter(
                  // remove any items that have a net benefit less than min net benefit
                  (item) =>
                    item.block_economic_results_grower.net_benefit >=
                    minNetBenefit
                    // sort for optimal by net benefit
                ).sort(
                  (a: any, b: any) =>  b.block_economic_results_grower.net_benefit - a.block_economic_results_grower.net_benefit
                ).filter((value: any, index: number, arr) => {
                  // Remove all elements from array after the optimal at index 0 in array if they have a predicted flow rate less than the optimal
                  return index === 0 || (index !== 0 && value.block_agronomic_results.flow_rate_pred > arr[0].block_agronomic_results.flow_rate_pred) 
                });

                this.logging.info({block_results: this.block_results})
                // Replace pred results with std
                Object.entries(std_result).forEach(
                  ([key, value]: [string, any]) => {
                    Object.entries(value).forEach(([res_key, _]) => {
                      // If property has pred in name, then replace its value with the std version
                      if (res_key.includes('pred')) {
                        let std_key = res_key.replace('pred', 'std');
                        std_result[key][res_key] = std_result[key][std_key];
                      }
                      if (res_key.includes('change')) {
                        std_result[key][res_key] = 0;
                      }

                      if (res_key.includes('net_benefit')) {
                        std_result[key][res_key] = 0;
                      }
                    });
                  }
              );
       
              std_result.standard = true;

              block.results = this.block_results
              this.block_results = [std_result, ...this.block_results]
              //show dialog
              this.dialog_open = true;
              //update array for block result
              this.representative_areas.push({
                block_number: this.block,
                rep_area: block.block_representative_area,
                selected_result: {},
              });
            }, 
            error: ({ error }) => {
              this.toastr.error(error.errorMessage)
              this.is_processing_calculations = false;
            }
          })
        }
      }
    });

    this.assignedBlocks.forEach((f: any) => {
      this.total_representative_area += parseFloat(f.block_representative_area);
    });

    this.assignedBlocks.forEach((f: any) => {
      f.percent_area =
        f.block_representative_area / this.total_representative_area;
    });

    this.allowance_open = false;
    this.block_allowance = {};


  }

  //remove farm blocks from items
  save_results_remove(block_number: any): void {
    if (this.payload_save_results.items.length == undefined) {
      // theres just one block
      this.payload_save_results.items.splice(0, 1);
    } else {
      //loop through them
      let index = this.payload_save_results.items.findIndex(
        (f: any) => f.block_number == block_number
      );
      this.payload_save_results.items.splice(index, 1);
    }
    this.blockApi.save_results(this.payload_save_results).subscribe((s) => {

    });
  }

  //add new selected blocks calculation items
  save_results_add(result: any, block_number: any): void {
    this.payload_save_results.group_id = this.group_id;

    this.payload_save_results.items.push({
      farm_id: this.selectedFarm,
      block_number: block_number,
      result: result,
      representative_area: Number(this.total_representative_area),
    });

    this.blockApi.save_results(this.payload_save_results).subscribe((s) => {

    });
  }

  countChangedHandler(
    event: number,
    variableName: string,
    blockNumber: string
  ) {
    this.total_representative_area = 0;
    this.assignedBlocks.forEach((f: any) => {
      if (f.block_number == blockNumber) {
        f[variableName] = event;
      }
    });
    this.assignedBlocks.forEach((f: any) => {
      this.total_representative_area += f[variableName];
    });
  }

  countAllowanceChangedHandler(event: number, variableName: string) {
    this.block_allowance[variableName] = event;
    
    this.calcAllowanceAverage();
  }

  calcAllowanceAverage() {
    let std_yield = this.block_allowance.delivered_yield_estimate;
    if (std_yield > 0) {
      let total_harvest_allowance = (this.block_allowance.harvest_allowance * this.block_allowance.harvest_allowance_tonnes);
      let total_haulage_allowance = (this.block_allowance.haulage_allowance * this.block_allowance.haulage_allowance_tonnes);
      let total_night_allowance = (this.block_allowance.night_allowance * this.block_allowance.night_allowance_tonnes);
      let total_sunday_allowance = (this.block_allowance.sunday_allowance * this.block_allowance.sunday_allowance_tonnes);

      this.logging.info([total_harvest_allowance,total_haulage_allowance, total_night_allowance,total_sunday_allowance  ])
      this.block_allowance.total_average_allowance_per_tone =
          (total_harvest_allowance +
            total_haulage_allowance+
            total_night_allowance +
            total_sunday_allowance) /
            std_yield;
    } else {
      this.block_allowance.total_average_allowance_per_tone = 0;
    }
  }

  loadGrowers(): any {
    ``;
    this.growersData = [];
    this.groupApi.list_growers_in_group(this.group_id).subscribe((l) => {
      if (l.body) {
        this.growersData = l.body.growers;
        this.availableFarms = this.growersData.filter(
          (obj: any) => obj.cognito_id == this.cognito_id
        )[0].farms;
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.group = params['name'];
      this.group_id = params['group_id'];
      this.cognito_id = params['cognito_id'];
      let title = document.getElementById('group_blocks_title');
      if (title)
        title.textContent = `Comparison Scenarios for Grower: ${this.group}`;
      //this.loadFarms();
    });
    this.loadGrowers();
    this.auth.getUser().then((user: any) => {
      //if no user was found sent to login again
      this.isGrower = user.attributes['custom:user_type'] == 'GROWER';
    });
  }

  loadAssignedBlocks(group: string, farm: string) {
    this.groupApi.getAssignedBlocks(group, farm).subscribe((l) => {
      this.logging.info(l.body)
      if (l.body) {
        l.body.forEach((i: any) => {
          let farms = this.growersData.find(
            (obj: any) => obj.cognito_id == this.cognito_id
          ).farms;
          let farm_local = farms.filter((f: any) => f.id == farm)[0];
          let block = farm_local.blocks.find(
            (block: any) => block.block_id == i.id
          );

          
          if (block){
            this.availableBlocks.push({
              id: i.id,
              block_number: i.block_number,
              area: i.area,
              default_yield: block.default_yield,
              topping:block.topping
            });
          }

        });
      }
    });
  }

  onSelectFarm(farm: any) {
    this.assignedBlocks = [];
    this.availableBlocks = [];
    let farm_ext_id = farm.value;
    this.selectedFarm = farm.value;
    this.loadAssignedBlocks(this.group_id, farm_ext_id);
  }

  onSelectSeason(season: any) {
    this.season = season.value;
  }

  removeBlocks(block?: any) {
    let payload: any = { blocks: [], farm_id: this.selectedFarm };
    this.total_representative_area = 0;

    if (block != undefined) {
      payload.blocks.push(block.block_number);
      this.save_results_remove(block.block_number);

      this.assignedBlocks.forEach((b: any) => {

        if (b.block_number == block.block_number) {
          b.percent_area = 0;
          b.block_representative_area = 0;
        } else {

          //this.save_results(b)//update current selected blocks for save results API
        }
      });

      this.assignedBlocks.forEach((f: any) => {
        this.total_representative_area += parseFloat(
          f.block_representative_area
        );
      });

      this.assignedBlocks.forEach((f: any) => {
        f.percent_area =
          f.block_representative_area / this.total_representative_area;
      });
    } else {
      this.assignedBlocks.forEach((block: any) => {
        block.percent_area = 0;
        block.total_repesentative_area = 0;

        this.assignedBlocks.forEach((f: any) => {
          this.total_representative_area += parseFloat(
            f.block_representative_area
          );
        });

        this.assignedBlocks.forEach((f: any) => {
          f.percent_area =
            f.block_representative_area / this.total_representative_area;
        });

        payload.blocks.push(block.block_number);
      });
    }
  }

  selectThisResult(result: any, index: any) {
    this.save_results_add(result, this.block);
    
    
    this.representative_areas.forEach((f: any) => {
      if (f.block_number == this.block) {
        f.block_results = result;
      }
    });
    
    this.assignedBlocks.forEach((block: any) => {
      if (block.block_number == this.block) {
        result.ranking = index
        block.results = this.block_results;
        block.selected_result = result
      }
    });

    this.dialog_open = false;
  }

  drop(event: any) {
    if (event.previousContainer == event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (event.previousContainer.id == 'assignedBlocksList')
        this.removeBlocks(event.previousContainer.data[event.previousIndex]);

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      if (event.previousContainer.id == 'availableBlocksList') {

        this.payload = {
          group_id: this.group_id,
          farm_id: this.selectedFarm,
          block_number: event.container.data[event.currentIndex].block_number,
          moisture: event.container.data[event.currentIndex].moisture,
          // "std_yield": 100,
          std_ccs: event.container.data[event.currentIndex].ccs,
          std_topping: event.container.data[event.currentIndex].topping,
          std_ground_speed:
            event.container.data[event.currentIndex].default_ground_speed,
          std_fan_speed:
            event.container.data[event.currentIndex].default_fan_speed,
        };

        this.allowance_open = true;
        this.block = event.container.data[event.currentIndex].block_number;
      }
    }

    //set default representative area & yield estimate
    this.block_allowance.total_repesentative_area =
      event.container.data[event.currentIndex].area;
    this.block_allowance.delivered_yield_estimate =
      event.container.data[event.currentIndex].default_yield * this.block_allowance.total_repesentative_area;
  }
}
