import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, retry } from 'rxjs';
import { AdminCognitoService } from '../services/admin-cognito.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardGuard implements CanActivate {
  isAuthenticated: boolean;
  constructor(
    private router: Router,
    private cognitoService: AdminCognitoService
  ) {
    this.isAuthenticated = false;
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): boolean | Promise<boolean> {

  //     return this.cognitoService.isAuthenticated().then((u: any) => {
  //     this.isAuthenticated = true;
  //     if (!this.isAuthenticated) {
  //       this.router.navigate(['/signIn']);
  //     }
  //     return this.isAuthenticated;
  //   });

  // }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let isAuth = await this.cognitoService.isAuthenticated();
    if (!isAuth) {
      this.router.navigateByUrl('/signIn');
      return false;
    }
    return true;
  }
}
