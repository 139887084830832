<form>
  <div class="content-1920">
    <div class="content-padding margin-top-small">
      <div class="main">
        <h2 id="group_blocks_title"></h2>

        <div class="fieldwrap">
          <label>Farms</label>
          <select
            id="farm_name"
            name="farm_name"
            required
            (change)="onSelectFarm($event.target)"
          >
            <option>Select Farm</option>
            <option *ngFor="let f of availableFarms" value="{{ f.ext_id }}">
              {{ f.name }}
            </option>
          </select>
        </div>

        <div cdkDropListGroup class="fieldwrap columns3">
          <div>
            <label>Available Blocks</label>
            <div
              cdkDropList
              id="availableBlocksList"
              cdkDropListSortingDisabled
              [cdkDropListData]="availableBlocks"
              class="blocks-list"
              (cdkDropListDropped)="drop($event)"
              (mousedown)="onSelectAvailableBlock($event.target)"
            >
              <div
                class="blocks-box"
                *ngFor="let item of availableBlocks"
                cdkDrag
              >
                {{ item.block_number }}
              </div>
            </div>
          </div>
          <div class="columns12">
            <div class="fieldwrap">
              <div class="align-center">
                <br />
                <br />
                <button
                  mat-raised-button
                  type="button"
                  class="btn btn-outline-secondary"
                  title="Add all"
                  (click)="addAllBlocks()"
                >
                  >>
                </button>
                <div></div>
                <button
                  mat-raised-button
                  type="button"
                  class="btn btn-outline-secondary"
                  title="Remove all"
                  (click)="removeAllBlocks()"
                >
                  <<
                </button>
              </div>
            </div>
          </div>
          <div>
            <label>Blocks Assigned</label>
            <div
              cdkDropList
              id="assignedBlocksList"
              cdkDropListSortingDisabled
              [cdkDropListData]="assignedBlocks"
              class="blocks-list"
              (cdkDropListDropped)="drop($event)"
              (mousedown)="onSelectAssignedBlock($event.target)"
            >
              <div
                class="blocks-box"
                *ngFor="let item of assignedBlocks"
                cdkDrag
              >
                {{ item.block_number }}
              </div>
            </div>
          </div>
        </div>
        <div class="fieldwrap fieldwrap-type-submit align-right">
          <a class="btn__secondary btn-back" (click)="back()">Back</a>
        </div>
      </div>
    </div>
  </div>
</form>
