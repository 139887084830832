import {
  Component,
  Input,
  OnInit,
  Directive,
  Output,
  EventEmitter,
} from '@angular/core';
import { HarvestService } from 'src/app/services/harvest.service';
import { GroupService } from 'src/app/services/group.service';
import { BlockService } from 'src/app/services/block.service';
import { MatChip } from '@angular/material/chips';
import { ToastrService } from 'ngx-toastr';
import { InputPostData } from '../../models/InputData';
import { AdminService } from 'src/app/services/admin.service';
import { ThisReceiver } from '@angular/compiler';
import { facebookSignInButton } from 'aws-amplify';
import { runInThisContext } from 'vm';

import { DialogService } from 'src/app/services/dialog.service';



@Component({
  selector: 'app-contractor-select-block',
  templateUrl: './contractor-select-block.component.html',
  styleUrls: ['./contractor-select-block.component.css'],
  providers: [HarvestService, GroupService, BlockService, InputPostData],
})
export class ContractorSelectBlockComponent implements OnInit {
  
  @Input() gridData: any = [];
  @Input() groupId: string = '';
  @Output() reloadGroup: EventEmitter<any> = new EventEmitter();
  seasons: any = {};
  currentCrop: any = {};
  currentBlock: any = {};
  growers: any = {};
  harvesterData: any = [];
  selectedHarvester: any = '';
  block_id: any = ';'
  farm_id: any = ';'
  selectedBlocks = new Set();
  selectedFarm: any;
  readyToAssign: boolean = false;
  readyToRemove: boolean = false;
  isBlockselected: boolean = false;
  selectedAssignedHarvester: boolean = false;
  dialog_open: boolean = false;
  dataPost: any = {};

  varietyList: any = [];
  seasonList: any = {};
  CropList: any = [
    'plant',
    'first ratoon',
    'second ratoon',
    'third ratoon',
    'older ratoon',
  ];
  presentationList: any = ['erect', 'sprawled', 'lodged'];
  toppingList: any = ['moderate', 'light', 'none'];
  fieldMoistureList: any = ['dry', 'damp'];

  constructor(
    private harverstApi: HarvestService,
    private toastr: ToastrService,
    private groupApi: GroupService,
    private blockApi: BlockService,
    private inputData: InputPostData,
    private adminApi: AdminService,
    private dialog: DialogService
  ) { }

  loadHarvester(): any {
    var date1 = new Date();
    this.harverstApi.getAll('harvester').subscribe((l) => {
      if (l.body[0].status == 'ACTIVE') {
        this.harvesterData = l.body;

      }
    });
  }

  loadData(type: string, data: any): any {
    this.adminApi.getAll(type).subscribe((l) => {
      if (l.body) {
        switch (type) {
          case 'variety':
            this.varietyList = l.body.filter(
              (obj: any) => obj.status == 'ACTIVE'
            ).sort((a: any, b: any) => Intl.Collator('en', { numeric: true }).compare(a.name, b.name));

            break;
          default:
            break;
        }
      }
    });
  }

  loadSeasons(): any {
    this.adminApi.getSeasons().subscribe((l) => {

      if (l.body) {
        this.seasonList = l.body;
      }
    });
  }

  onChangeHarvester(h: any) {
    this.selectedHarvester = h;
    if (this.selectedBlocks.size > 0) {
      this.readyToAssign = true;
    }
  }

  onUpdate(farm_id: any, block: any): void {

    let varieties = this.varietyList.filter(
      (obj: any) => obj.name == block.variety.name
    ).sort((a: any, b: any) => Intl.Collator('en', { numeric: true }).compare(a.name, b.name));
    this.currentBlock.block_number = block.block_number
    this.currentBlock.area = block.area
    this.currentBlock.number_haulouts_utilised = block.number_haulouts_utilised
    this.currentBlock.row_spacing = block.row_spacing
    this.currentBlock.average_row_length = block.average_row_length
    this.currentBlock.average_infield_haulout_distance = block.average_infield_haulout_distance
    this.currentBlock.average_bin_mass = block.average_bin_mass
    this.currentBlock.average_harvester_turnaround_time = block.average_harvester_turnaround_time
    this.currentBlock.proportion_total_allowances_paid_drivers = block.proportion_total_allowances_paid_drivers

    this.currentCrop.variety_id = varieties[0].name
    this.currentCrop.season = block.season
    this.currentCrop.crop_class = block.crop_class
    this.currentCrop.presentation = block.presentation
    this.currentCrop.topping = block.topping
    this.currentCrop.field_moisture = block.field_moisture
    this.currentCrop.default_ccs = block.default_ccs
    this.currentCrop.default_yield = block.default_yield
    this.currentCrop.default_ground_speed = block.default_ground_speed
    this.currentCrop.default_fan_speed = block.default_fan_speed

    this.block_id = block.block_id
    this.farm_id = farm_id

    this.dialog_open = true;
  }



  addBlocksinMessage(block_number: string) {
    this.selectedBlocks.add(block_number);

  }

  toggleSelection(
    chip: MatChip,
    block_number: string,
    farm_id: string,
    harvester?: any
  ) {
    chip.toggleSelected();
    if (harvester) {
      this.readyToRemove = true;
    }
    if (chip.selected) {
      this.isBlockselected = true;
      this.selectedFarm = farm_id;

      this.addBlocksinMessage(block_number);
    } else {

      this.selectedBlocks.delete(block_number);

    }
  }
  selectAll(e: any, blocks: any) {
    this.isBlockselected = true;
    if (e.currentTarget.checked) {
      for (const k in blocks) {
        this.addBlocksinMessage(blocks[k].block_number);
        this.selectedFarm = blocks[k].farm_id;
        this.readyToRemove = true;
        this.isBlockselected = true;
      }
    } else {
      this.selectedBlocks.clear();
      this.isBlockselected = false;
      this.readyToRemove = false;
      this.readyToAssign = false;
    }

  }

  assignHarvester(remove?: boolean) {
    let havesters = null;
    if (!remove) {
      havesters = this.selectedHarvester;
    }

    let data = {
      farm_id: this.selectedFarm,
      blocks: Array.from(this.selectedBlocks.values()),
      harvester_id: havesters,
    };
    this.groupApi.assignedBlocksToHarvester(this.groupId, data).subscribe(
      (h) => {
        if (h.body) {
          if (remove) {
            this.toastr.success('Harvester removed successfully.');
            this.isBlockselected = false;
            this.readyToAssign = false;
            this.readyToRemove = false;
            this.ngOnInit();
            this.reloadGroup.emit();
          } else {
            this.toastr.success('Harvester assigned successfully.');
            this.isBlockselected = false;
            this.readyToAssign = false;
            this.readyToRemove = false;
            this.ngOnInit();
            this.reloadGroup.emit();
          }
          this.ngOnInit();
        } else {
          this.toastr.error('unsuccessfully.');
        }
      },
      (error) => this.toastr.error(error.error.errorMessage)
    );
  }

  loadGrowers(groupId: any): any {
    this.groupApi.getListgrowersInGroup(groupId).subscribe((r) => {
      if (r.body.growers) {
        this.growers = r.body.growers;
      }
    });
  }


  save() {
    delete this.currentCrop.variety_id




    this.blockApi.update(this.currentBlock, this.farm_id, this.block_id).subscribe(
      (h) => {
        if (h.body) {
          this.toastr.success('Block updated successfully.');
        } else {
          this.toastr.error(h.errorMessage);
        }
      },
      (error) => this.toastr.error(error.error.errorMessage)
    );
    this.blockApi.update_crop(this.currentCrop, this.currentCrop.season, this.block_id).subscribe(
      (h) => {
        if (h.body) {
          this.toastr.success('Crop updated successfully.');
        } else {
          this.toastr.error(h.errorMessage);
        }
      },
      (error) => this.toastr.error(error.error.errorMessage)
    );
  }

  ngOnInit(): void {
    this.loadHarvester();
    this.loadData("variety", this.varietyList);
    this.loadSeasons();
    this.loadGrowers(this.groupId);
    this.dataPost = this.inputData.dataType;
  }

  resetAllCalculationsDialog(data: any) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to reset all calculations for all blocks in this group?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe(async (yes) => {
        if (yes) {
          try {
            for(let o = 0; o < data.length;o++) {
              let payload : any = {
                group_id : this.groupId,
                items: []
              }

              for(let f = 0; f < data[o].farms.length; f++) {
                let farm = data[o].farms[f];
                let farmBLocks = farm.blocks;
                for(let b = 0; b < farmBLocks.length; b++) {
                  let block = farmBLocks[b];
                  if(block.contract_element) {
                    payload.items.push({
                      farm_id: farm.id,
                      block_number: block.block_number,
                      representative_area: block.contract_element.representative_area,
                      result: []
                    })
                  }
                }
              }

              if(payload.items.length > 0) {
                await this.blockApi.save_results(payload).toPromise();
                this.toastr.success('Results for blocks reset successfully.');
              } else {
                this.toastr.success('No blocks need to reset.');
              }
            }
            
          }catch(ee : any) {
            this.toastr.error(ee.message);
          }
        }
      });
  }
}
