import { AfterContentInit, Component, OnInit, isDevMode } from '@angular/core';
import { HarvestService } from '../../services/harvest.service';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from '../../services/dialog.service';
import { AdminService } from 'src/app/services/admin.service';
import { IHarvester } from 'src/app/models/Harvester';
import { EntityStatus } from 'src/app/models/EntityStatus';

@Component({
  selector: 'app-harvest-list',
  templateUrl: './harvest-list.component.html',
  styleUrls: ['./harvest-list.component.css'],
  providers: [AdminService, HarvestService],
})
export class HarvestListComponent implements AfterContentInit {
  seasons: any = {};
  gridData: any = [];

  constructor(
    private adminApi: AdminService,
    private harverstApi: HarvestService,
    private dialog: DialogService,
    private toastr: ToastrService
  ) {}

  yesNoDialog(ext_id: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to delete the harvester ?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        let entity = 'harvester';
        let parameters = `?entity=${entity}&ext_id=${ext_id}`;
        if (yes) {

          this.adminApi.delete(parameters).subscribe(
            (s) => {

              if (s.body.includes('successfully')) {
                this.toastr.success(s.body);
                this.ngAfterContentInit();
              } else if (s.body.errorMessage) {
                this.toastr.error(
                  `Error deleting the harvester. Error: ${s.errorMessage}`
                );
              } else {
                this.toastr.error(
                  `Error deleting the harvester. Error: ${s.body}`
                );
              }
            },
            (error) => this.toastr.error(error.error.errorMessage)
          );
        }
      });
  }

  loadItems(): any {
    this.gridData = [];
    var date1 = new Date();
    this.harverstApi.listHarvesters().subscribe((harvesters) => {


      this.gridData = harvesters
        .filter((harvester) => harvester.status == 'ACTIVE')
        .map((harvester) => ({
          id: harvester.id,
          harvester: harvester.model_name,
          age: (() => {
            var date2 = new Date(harvester.manufactured_date);

            var diff = (date2.getTime() - date1.getTime()) / 1000;
            diff /= 60 * 60 * 24;
            var years: any = Math.abs(Math.round(diff / 365.25));
            return years <= 0 ? 'Less then a year' : years;
          })(),
          tracks: harvester.undercarriage,
          feedtrain: harvester.feedtrain_setup,
          extractor_config: harvester.extractor_fan_config_name,
          ext_id: harvester.ext_id,
        }));
    });
  }

  ngAfterContentInit(): void {
    this.loadItems();
  }
}
