<div class="row justify-content-center my-5">
  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h1>Admin Login - New Password</h1>
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <label for="password" class="form-label">Current Password:</label>
            <div class="input-group input-group-sm">
              <input
                [type]="user.showPassword ? 'text' : 'password'"
                id="password"
                name="password"
                #password="ngModel"
                [(ngModel)]="user.password"
                class="form-control form-control-sm"
              />
              <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="user.showPassword = !user.showPassword"
              >
                <i
                  class="bi"
                  [ngClass]="{
                    'bi-eye-fill': !user.showPassword,
                    'bi-eye-slash-fill': user.showPassword
                  }"
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <label for="newPassword" class="form-label">New Password:</label>
            <div class="input-group input-group-sm">
              <input
                [type]="user.showPassword ? 'text' : 'password'"
                id="newPassword"
                name="newPassword"
                #newPassword="ngModel"
                [(ngModel)]="user.newPassword"
                class="form-control form-control-sm"
              />
              <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="user.showPassword = !user.showPassword"
              >
                <i
                  class="bi"
                  [ngClass]="{
                    'bi-eye-fill': !user.showPassword,
                    'bi-eye-slash-fill': user.showPassword
                  }"
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-grid">
            <button
              type="button"
              (click)="resetPassword()"
              class="btn btn-sm btn-success"
              [disabled]="loading"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                *ngIf="loading"
              ></span>
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
