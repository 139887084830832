import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { InputPostData } from 'src/app/models/InputData';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-season-management',
  templateUrl: './season-management.component.html',
  styleUrls: ['./season-management.component.css'],
  providers: [AdminService, InputPostData],
})
export class SeasonManagementComponent implements OnInit {
  seasonList: any = [];
  seasonSelected: any = {};
  btnText: string = 'Add Season';
  dataPost: any = {};
  dataPre: any;

  constructor(
    private toastr: ToastrService,
    private adminApi: AdminService,
    private inputData: InputPostData
  ) { }

  ngOnInit(): void {
    this.dataPost = this.inputData.dataType;
    this.dataPre = this.inputData.dataType;
    this.loadSeasons();
  }


  // Turn 100% into 1, keep extra 2 precision so that 49.5% doesnt round to .5
  percentToNumber(input: number, precision: number = 2): number {
    return Number((input / 100).toFixed(precision + 2));
  }
  numberToPercent(input: number, precision: number = 2): number {
    return Number((input * 100).toFixed(precision + 2))
  }


  loadSeasons(): any {
    this.adminApi.getAll('admin/seasons').subscribe((l) => {
      if (l.body) {
        this.seasonList = l.body;
      }

      this.seasonList.forEach((obj:any) => {
        obj.annual_leave_loading_rate = this.numberToPercent(obj.annual_leave_loading_rate);
        obj.superannuation_rate = this.numberToPercent(obj.superannuation_rate);
      });

    });
  }


  update(season: string): void{
    this.btnText = 'Update Season';

    this.seasonSelected = this.seasonList.find(
      (obj: any) => obj.season == season
    );
  }

  save(): void{
    let data = this.seasonSelected;

    data.annual_leave_loading_rate = this.percentToNumber(data.annual_leave_loading_rate);
    data.superannuation_rate = this.percentToNumber(data.superannuation_rate);


    if ('created_at' in this.seasonSelected){
      const endpoint = 'admin/seasons/' + this.seasonSelected.season;

      delete data.season;
      delete data.created_at;
      delete data.deleted_at;
      delete data.updated_at;

      this.updateData(endpoint, JSON.stringify(data));

    }else{
      const endpoint = 'admin/seasons' ;
      this.create(endpoint, JSON.stringify(data));
    }
  }

  cancel(): void{
    this.btnText = 'Add Season';
    this.seasonSelected = {};
  }

  async create(endpoint: string, data: any): Promise<any>{
    this.adminApi.create(endpoint, data).subscribe((h) => {
      if (h.body.season) {
        this.toastr.success('Season created successfully.');
        this.cancel();
      } else {
        this.toastr.error(h.body.error);
      }
    });
  }

  async updateData(endpoint: string, data: any): Promise<any>{
    this.adminApi.update(endpoint, data).subscribe((h) => {
      if (h.body.season) {
        this.toastr.success('Season updated successfully.');
        this.loadSeasons();
        this.cancel();
      } else {
        this.toastr.error(h.body.error);
      }
    });
  }
}
