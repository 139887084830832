import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';
import { MatButtonModule } from '@angular/material/button';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  FormArray,
} from '@angular/forms';

// import * as Sentry from "@sentry/angular";

import { ToastrModule } from 'ngx-toastr';
import { UserManagementComponent } from './admin-components/user-management/user-management.component';
import { AdminSignInComponent } from './admin-components/admin-sign-in/admin-sign-in.component';
import { AdminResetPwdComponent } from './admin-components/admin-reset-pwd/admin-reset-pwd.component';
import { AdminHomeComponent } from './admin-components/admin-home/admin-home.component';

import { HarvestListComponent } from './contractor-components/harvest-list/harvest-list.component';
import { HarvestDetailsComponent } from './contractor-components/harvest-details/harvest-details.component';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { FarmListComponent } from './grower-components/farm-list/farm-list.component';
import { FarmDetailsComponent } from './grower-components/farm-details/farm-details.component';
import { BlockBulkDetailsComponent } from './grower-components/block-bulk-details/block-bulk-details.component';
import { BlockDetailsComponent } from './grower-components/block-details/block-details.component';
import { CropDetailsComponent } from './grower-components/crop-details/crop-details.component';
import { ContractorGroupsListComponent } from './contractor-components/contractor-groups-list/contractor-groups-list.component';
import { GrowerInvitationsListComponent } from './grower-components/grower-invitations-list/grower-invitations-list.component';
import { ContractorGroupDetailComponent } from './contractor-components/contractor-group-detail/contractor-group-detail.component';
import { GrowerGroupDetailComponent } from './grower-components/grower-group-detail/grower-group-detail.component';
import { GrowerGroupsListComponent } from './grower-components/grower-groups-list/grower-groups-list.component';
import { GroupBlocksLinkComponent } from './grower-components/group-blocks-link/group-blocks-link.component';
import { InviteComponent } from './dialogs/invite/invite.component';
import { ContractorSelectBlockComponent } from './contractor-components/contractor-select-block/contractor-select-block.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { NumberInputComponent } from './form-components/number-input/number-input.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { ContractorHomeComponent } from './contractor-components/contractor-home/contractor-home.component';
import { GrowerHomeComponent } from './grower-components/grower-home/grower-home.component';
import { FixedCostComponent } from './contractor-components/fixed-cost/fixed-cost.component';
import { HarvesterManagementComponent } from './admin-components/harvester-management/harvester-management.component';
import { RegionManagementComponent } from './admin-components/region-management/region-management.component';
import { CropManagementComponent } from './admin-components/crop-management/crop-management.component';
import { HarvesterSeasonComponent } from './contractor-components/harvester-season/harvester-season.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmSignUpComponent } from './confirm-sign-up/confirm-sign-up.component';
import { TextInputComponent } from './form-components/text-input/text-input.component';
import { RepairAndMaintenanceComponent } from './admin-components/repair-and-maintenance/repair-and-maintenance.component';
import { ContractNegotiationComponent } from './contractor-components/contract-negotiation/contract-negotiation.component';
import { ContractNegotiationGrowerComponent } from './contractor-components/contract-negotiation-grower/contract-negotiation-grower.component';
import { GroupGrowerBlockComponent } from './grower-components/group-grower-block/group-grower-block.component';

import { SumPipeModule } from './grower-components/block-details/block-details.component';
import { AboutComponent } from './about/about/about.component';
import { MatCardModule } from '@angular/material/card';

import { Router } from '@angular/router';
import { HarvesterCopySeasonComponent } from './contractor-components/harvester-copy-season/harvester-copy-season.component';
import { MillCopySeasonComponent } from './admin-components/mill-copy-season/mill-copy-season.component';
import { SeasonManagementComponent } from './admin-components/season-management/season-management.component';

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    SignInComponent,
    HomeComponent,
    UserManagementComponent,
    AdminSignInComponent,
    AdminResetPwdComponent,
    HarvestListComponent,
    HarvestDetailsComponent,
    ConfirmComponent,
    FarmListComponent,
    FarmDetailsComponent,
    BlockBulkDetailsComponent,
    BlockDetailsComponent,
    ContractorGroupsListComponent,
    GrowerInvitationsListComponent,
    GrowerGroupsListComponent,
    ContractorGroupDetailComponent,
    GrowerGroupDetailComponent,
    GroupBlocksLinkComponent,
    InviteComponent,
    ContractorSelectBlockComponent,
    NumberInputComponent,
    AdminHomeComponent,
    ContractorHomeComponent,
    GrowerHomeComponent,
    HarvesterManagementComponent,
    FixedCostComponent,
    RegionManagementComponent,
    CropManagementComponent,
    HarvesterSeasonComponent,
    CropDetailsComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ConfirmSignUpComponent,
    TextInputComponent,
    RepairAndMaintenanceComponent,
    ContractNegotiationComponent,
    ContractNegotiationGrowerComponent,
    GroupGrowerBlockComponent,
    AboutComponent,
    HarvesterCopySeasonComponent,
    MillCopySeasonComponent,
    SeasonManagementComponent
  ],
  imports: [
    NgbModule,
    MatButtonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatIconModule,
    MatToolbarModule,
    DragDropModule,
    CommonModule,
    MatGridListModule,
    MatDividerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatInputModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatCardModule,
    MatProgressBarModule,
    SumPipeModule,
  ],
  providers: [
  // {
  //   provide: ErrorHandler,
  //   useValue: Sentry.createErrorHandler({
  //     showDialog: true
  //   }),
  // },
  // {
  //   provide: Sentry.TraceService,
  //   deps: [Router],
  // },
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: () => () => {},
  //   deps: [Sentry.TraceService],
  //   multi: true,
  // },
    SumPipeModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
