import { Component, OnInit } from '@angular/core';
import {
  moveItemInArray,
  transferArrayItem,
  CdkDragStart,
} from '@angular/cdk/drag-drop';

import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FarmService } from '../../services/farm.service';
import { BlockService } from '../../services/block.service';
import { GroupService } from '../../services/group.service';
import { DialogService } from '../../services/dialog.service';
import { Location } from '@angular/common';

import { IFarm } from '../../models/Farm';
import { IBlock } from '../../models/Block';
import { ContentObserver } from '@angular/cdk/observers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-group-block-link',
  templateUrl: './group-blocks-link.component.html',
  styleUrls: ['./group-blocks-link.component.css'],
  providers: [FarmService, BlockService, GroupService],
})
export class GroupBlocksLinkComponent implements OnInit {
  group_id: string;
  group: string;
  farm: any;
  block: any;
  season?: string;
  selectedFarm: string;

  availableFarms: any = [];
  availableBlocks: any = [];
  assignedBlocks: any = [];
  availableSeasons: any = [];
  dragging: boolean;

  constructor(
    private location: Location,
    private farmApi: FarmService,
    private blockApi: BlockService,
    private groupApi: GroupService,
    private dialog: DialogService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.group_id = '';
    this.group = '';
    this.dragging = false;
    this.selectedFarm = '';
  }

  yesNoDialog(block_id: string, group_id: string, farm_id: string) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: `Are you sure you want to [action]?`,
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        yes ? true : false;
      });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.group = params['name'];
      this.group_id = params['id'];
      this.season = params["season"];
      let title = document.getElementById('group_blocks_title');
      if (title) title.textContent = `Assign Blocks to ${this.group}`;
      this.loadFarms();
      // this.loadSeasons();
    });
  }

  loadSeasons() {
    const currentYear = new Date().getFullYear();
    this.availableSeasons = [];

    for (let season = currentYear - 5; season <= currentYear + 5; season++) {
      this.availableSeasons.push({
        id: season.toString(),
        name: season.toString(),
      });
    }
  }

  loadFarms(): void {
    this.availableFarms = [];
    this.farmApi.getAll().subscribe((l) => {
      if (l.body) {
        this.availableFarms = l.body;
      }
    });
  }
  loadFarmBlocks(farm_id: string): void {
    if (!this.season) {
      return
    }

    this.blockApi.getAllAvailableCrops(farm_id, this.season).subscribe((l) => {
      if (l.body) {
        l.body.forEach((i: any) => {
          this.availableBlocks.push({
            id: i.block_id,
            block_number: i.block_details.block_number,
          });
        });
      }
    });
  }

  loadAssignedBlocks(group: string, farm: string) {
    this.groupApi.getAssignedBlocks(group, farm).subscribe((l) => {
      if (l.body) {
        l.body.forEach((i: any) => {
          this.assignedBlocks.push({
            id: i.id,
            block_number: i.block_number,
          });
        });
      }
    });
  }

  onSelectFarm(farm: any) {

    this.assignedBlocks = [];
    this.availableBlocks = [];

    let farm_ext_id = farm.value;
    this.selectedFarm = farm.value;
    this.loadAssignedBlocks(this.group_id, farm_ext_id);
    this.loadFarmBlocks(farm_ext_id);
  }

  assignBlocks() {
    let payload: any = { blocks: [], farm_id: this.selectedFarm }; //, "season" : this.season};

    this.assignedBlocks.forEach((block: any) =>
      payload.blocks.push(block.block_number)
    );

    if (payload.blocks.length > 0) {


      this.blockApi.assign(this.group_id, payload).subscribe((s) => {
        if (s.body) {
          this.toastr.success('Blocks assigned to group successfully.');
        } else {
          s.error
            ? this.toastr.error(
              'Error assigning blocks. Error: ' + s.error.errorMessage
            )
            : this.toastr.error(
              'Error assigning blocks. Error: ' + s.errorMessage
            );
        }
      });
    }
  }

  removeBlocks(block?: any) {
    let payload: any = { blocks: [], farm_id: this.selectedFarm };

    block != undefined
      ? payload.blocks.push(block.block_number)
      : this.assignedBlocks.forEach((block: any) =>
        payload.blocks.push(block.block_number)
      );
    if (payload.blocks.length > 0) {
      this.blockApi.remove(this.group_id, payload).subscribe((s) => {
        if (s.body) {
          this.toastr.success('Blocks removed from group successfully.');
        } else {
          s.error
            ? this.toastr.error(
              'Error removing blocks. Error: ' + s.error.errorMessage
            )
            : this.toastr.error(
              'Error removing blocks. Error: ' + s.errorMessage
            );
        }
      });
    }
  }

  addAllBlocks() {
    let _availableBlocks: any = [];
    this.availableBlocks.forEach((block: any) =>
      _availableBlocks.push(block.id)
    );

    _availableBlocks.forEach((b: any, index: number) =>
      transferArrayItem(this.availableBlocks, this.assignedBlocks, index, index)
    );

    this.assignBlocks();
  }

  removeAllBlocks() {
    this.removeBlocks();

    let _blocksToAssign: any = [];
    this.assignedBlocks.forEach((block: any) => _blocksToAssign.push(block.id));

    _blocksToAssign.forEach((b: any, index: number) =>
      transferArrayItem(this.assignedBlocks, this.availableBlocks, index, index)
    );
  }

  drop(event: any) {
    if (event.previousContainer == event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (event.previousContainer.id == 'assignedBlocksList')
        this.removeBlocks(event.previousContainer.data[event.previousIndex]);

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      if (event.previousContainer.id == 'availableBlocksList')
        this.assignBlocks();
    }
  }
  back() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  onSelectAvailableBlock = (block: any) => { };
  onSelectAssignedBlock = (block: any) => { };
}
