import { Injectable } from '@angular/core';

@Injectable()
export class InputPostData {
  dataType: any = {
    hrs: 'hrs',
    day: 'a day',
    days: 'days',
    hday: 'h/day',
    wks: 'wks',
    pwk: 'p/wk',
    unit: 'unit',
    units: 'units',
    unit_s: 'unit/s',
    rpm: 'rpm',
    dollar: '$',
    dollar_t: '$/t',
    percent: '%',
    t_year: 't/yr',
    t: 't',
    m: 'm',
    km: 'km',
    ha: 'ha',
    tha: 't/ha',
    ha_year: 'ha/yr',
    kmh: 'km/h',
    ltrh: 'ltr/h',
    sec: 'sec',
    num: '',
    min: 'min',
  };
}

export class SeasonList {
  seasons: any = [2021, 2022, 2023, 2024, 2026, 2027, 2028];
}
