import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IUser, CognitoService } from 'src/app/services/cognito.service';
import { Router, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-contractor-home',
  templateUrl: './contractor-home.component.html',
  styleUrls: ['./contractor-home.component.css'],
})
export class ContractorHomeComponent implements OnInit {
  show_disclaimer: boolean = false;
  currentUser: any = {};

  constructor(private cognitoService: CognitoService, private router: Router) {}

  ngOnInit(): void {
    this.cognitoService.clearUserData();
    this.cognitoService.getUser().then((user) => {

      this.currentUser = user;
      if (user.attributes['custom:accepted_disclaimer'] == 'false') {
        // if first time log in for current user show dialog
        this.show_disclaimer = true; 
      }
    });
  }

  accept_disclaimer(): void {
    //if disclaimer is accepted update user details so it wont be shown next time
    this.show_disclaimer = false;
    this.currentUser.attributes['custom:accepted_disclaimer'] = 'true';
    this.cognitoService.updateUser(this.currentUser);
  }

  decline_disclaimer(): void {
    //log out user if disclaimer isnt accepted
    this.show_disclaimer = false;
    this.cognitoService.signOut().then(() => {
      localStorage.removeItem(environment.keys.accessKey);
      localStorage.removeItem(environment.keys.secretKey);
      localStorage.removeItem(environment.keys.sessionToken);
      this.router.navigate(['/signIn']);
    });
  }
}
