<div class="content-1920">
  <div class="content-padding margin-top-small">
    <div class="main">
      <h2>Pending Invitations</h2>
      <div class="columns-wrap"></div>

      <div class="columns-wrap">
        <div class="columns12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Group</th>
                <th scope="col">Season</th>
                <th scope="col">Invited By</th>
                <th scope="col">Invited At</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of gridData">
                <td>{{ row.group }}</td>
                <td>{{ row.season }}</td>
                <td>{{ row.invited_by }}</td>
                <td>{{ row.invited_at | date : "dd/MM/yyyy hh:mm:ss" }}</td>
                <td>
                  <a
                    class="btn btn-small link-btn"
                    type="button"
                    (click)="acceptInvitation(row.group_ext_id)"
                    routerLinkActive="active"
                    >Accept</a
                  >
                  <button
                    class="btn-small btn-left-space"
                    type="button"
                    (click)="yesNoDialog(row.group_ext_id)"
                  >
                    Decline
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
