<form>
  <div class="content-1920">
    <div class="content-padding margin-bottom-small">
      <div class="main">
        <fieldset>
          <div class="fieldwrap label-inline">
            <label>No. of Harvesters in Group</label>
            <input type="text" id="numberHarvester" name="numberHarvester" #name="ngModel" [(ngModel)]="numberHarvester"
              readonly />
          </div>
        </fieldset>

        <h2>Fixed Costs for {{season}}
        </h2>

        <fieldset>
          <div class="columns-wrap">
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Insurance (eg. public liability)</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.insurance"
                  (numberInputChange)="countChangedHandler($event, 'insurance')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="insurance" name="insurance" #name="ngModel" [(ngModel)]="insurance" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>OHS</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.ohs"
                  (numberInputChange)="countChangedHandler($event, 'ohs')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="ohs" name="ohs" #name="ngModel" [(ngModel)]="ohs"  readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Accounting Fees</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.accounting_fees"
                  (numberInputChange)="
                    countChangedHandler($event, 'accounting_fees')
                  ">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="accounting_fees" name="accounting_fees" #name="ngModel" [(ngModel)]="accounting_fees" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Uniforms</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.uniforms"
                  (numberInputChange)="countChangedHandler($event, 'uniforms')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="uniforms" name="uniforms" #name="ngModel" [(ngModel)]="uniforms" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Telephone</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.telephone"
                  (numberInputChange)="countChangedHandler($event, 'telephone')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="telephone" name="telephone" #name="ngModel" [(ngModel)]="telephone" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Printing & Stationary</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.printing_stationary"
                  (numberInputChange)="
                    countChangedHandler($event, 'printing_stationary')
                  ">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="printing_stationary" name="printing_stationary" #name="ngModel" [(ngModel)]="printing_stationary" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Bank Charges</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.bank_charges"
                  (numberInputChange)="countChangedHandler($event, 'bank_charges')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="bankCharges" name="bankCharges" #name="ngModel" [(ngModel)]="bankCharges" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Freight</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.freight"
                  (numberInputChange)="countChangedHandler($event, 'freight')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="freight" name="freight" #name="ngModel" [(ngModel)]="freight" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Legal & Consulting</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.legal_consulting"
                  (numberInputChange)="
                    countChangedHandler($event, 'legal_consulting')
                  ">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="legal_consulting" name="legal_consulting" #name="ngModel" [(ngModel)]="legal_consulting" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Computer</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.computer"
                  (numberInputChange)="countChangedHandler($event, 'computer')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="Computer" name="Computer" #name="ngModel" [(ngModel)]="computer" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Registrations</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.registrations"
                  (numberInputChange)="countChangedHandler($event, 'registrations')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="registrations" name="registrations" #name="ngModel" [(ngModel)]="registrations" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Bookkeeper</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.bookeeper"
                  (numberInputChange)="countChangedHandler($event, 'bookeeper')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="bookeeper" name="bookeeper" #name="ngModel" [(ngModel)]="bookeeper" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Rates</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.rates"
                  (numberInputChange)="countChangedHandler($event, 'rates')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="rates" name="rates" #name="ngModel" [(ngModel)]="rates" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Crush-out Party</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.crush_out_party"
                  (numberInputChange)="
                    countChangedHandler($event, 'crush_out_party')
                  ">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="crush_out_party" name="crush_out_party" #name="ngModel" [(ngModel)]="crush_out_party" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Lease (Vehicles & Tools)</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.lease"
                  (numberInputChange)="countChangedHandler($event, 'lease')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="lease" name="lease" #name="ngModel" [(ngModel)]="lease" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Consumables</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.consumables"
                  (numberInputChange)="countChangedHandler($event, 'consumables')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="consumables" name="consumables" #name="ngModel" [(ngModel)]="consumables" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Rent (House)</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.rent"
                  (numberInputChange)="countChangedHandler($event, 'rent')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="rent" name="rent" #name="ngModel" [(ngModel)]="rent" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Driver Rent</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.driver_rent"
                  (numberInputChange)="countChangedHandler($event, 'driver_rent')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="driver_rent" name="driver_rent" #name="ngModel" [(ngModel)]="driver_rent" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Electricity</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.electricity"
                  (numberInputChange)="countChangedHandler($event, 'electricity')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="electricity" name="electricity" #name="ngModel" [(ngModel)]="electricity" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Gas Rental</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.gas_rental"
                  (numberInputChange)="countChangedHandler($event, 'gas_rental')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="gas_rental" name="gas_rental" #name="ngModel" [(ngModel)]="gas_rental" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Membership Fees</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.membership_fees"
                  (numberInputChange)="
                    countChangedHandler($event, 'membership_fees')
                  ">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="membershipFees" name="membershipFees" #name="ngModel" [(ngModel)]="membershipFees" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Sundries/Other</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.sundries"
                  (numberInputChange)="countChangedHandler($event, 'sundries')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="sundries" name="sundries" #name="ngModel" [(ngModel)]="sundries" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Training</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.training"
                  (numberInputChange)="countChangedHandler($event, 'training')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="training" name="training" #name="ngModel" [(ngModel)]="training" readonly />
                                </div> -->
              </div>
            </div>
          </div>
        </fieldset>

        <h2>Fixed Wages</h2>

        <fieldset>
          <div class="columns-wrap">
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Management Salaries</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.management_salaries"
                  (numberInputChange)="
                    countChangedHandler($event, 'management_salaries')
                  ">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="managementSalaries" name="managementSalaries" #name="ngModel" [(ngModel)]="managementSalaries" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Admin Salary</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.admin_salary"
                  (numberInputChange)="countChangedHandler($event, 'admin_salary')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="adminSalary" name="adminSalary" #name="ngModel" [(ngModel)]="adminSalary" readonly />
                                </div> -->
              </div>
              <div class="fieldwrap label-inline">
                <label>Subcontractor</label>
                <app-number-input [dataPost]="dataPost.dollar" [numberInput]="costs.subcontractor"
                  (numberInputChange)="countChangedHandler($event, 'subcontractor')">
                </app-number-input>
                <!-- <div class="calc-field">
                                    <label>Per Harv</label>
                                    <input type="text" id="subcontractor" name="subcontractor" #name="ngModel" [(ngModel)]="subcontractor" readonly />
                                </div> -->
              </div>
            </div>
          </div>
        </fieldset>

        <div class="total-wrap h2">
          <span>Total</span>
          <div class="total">
            <span class="calc-total">{{ this.costs.total_cost }}</span>
            <span class="calc-harv">{{
              this.costs.total_cost_per_harvester
              }}</span>
          </div>
        </div>
        <div class="save_button">
          <a
            class="btn__secondary btn-back"
            (click)="back()"
            >Back</a
          >
          <button type="submit" (click)="save()">Save</button>
        </div>
      </div>
    </div>
  </div>
</form>