import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Console } from 'console';
import { GroupService } from '../../services/group.service';
import { HarvestService } from 'src/app/services/harvest.service';
@Component({
  selector: 'app-contract-negotiation',
  templateUrl: './contract-negotiation.component.html',
  styleUrls: ['./contract-negotiation.component.css'],
  providers: [GroupService, HarvestService],
})
export class ContractNegotiationComponent implements OnInit {
  gridData: any = [];
  brandsLists: any = [];
  isAdd = true;
  constructor(
    private groupApi: GroupService,
    private harvesterApi: HarvestService
  ) {}

  loadItems(): any {
    ``;
    this.gridData = [];
    this.groupApi.getAll().subscribe((l) => {

      if (l.body) {
        this.gridData = l.body.filter((obj: any) => obj.status == 'ACTIVE');
      }
    });
  }

  ngOnInit(): void {
    this.loadItems();
  }
}
