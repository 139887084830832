<div class="content-1920">
  <div class="content-padding margin-top-small">
    <a class="btn__secondary" (click)="changeTabs('model')">Harvester Models</a>
    <a class="btn__secondary" (click)="changeTabs('config')"
      >Harvester Fan Configuration</a
    >
    <a class="btn__secondary" (click)="changeTabs('brand')">Harvester Brands</a>
    <h2>{{ tabHeader }}</h2>
    <div class="columns-wrap" *ngIf="tabHeader == 'Harvester Models'">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Brand Name</th>
            <th scope="col">Model Name</th>
            <th scope="col">Default Current New Value</th>
            <th scope="col">Default Life Hours</th>
            <th scope="col">Default Salvage Value</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let model of modelLists">
            <td>{{ model.brand_name }}</td>
            <td>{{ model.model_name }}</td>
            <td>{{ model.default_current_new_value }}</td>
            <td>{{ model.default_life_hours }}</td>
            <td>{{ model.default_salvage_value }}</td>
            <td>
              <!-- <a class="btn-small" routerLink="/harvester" routerLinkActive="active">Edit</a> -->
              <button
                class="btn-small btn-left-space"
                type="button"
                (click)="update('model', model.id)"
              >
                Edit
              </button>
              <!-- <button class="btn-small btn-left-space" type="button"
                                (click)="yesNoDialog('detete')">Delete</button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <fieldset>
        <div class="columns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Brand</label>
                <select
                  id="modelBrand"
                  name="modelBrand"
                  #modelBrand="ngModel"
                  [(ngModel)]="currentModel.brand_id"
                >
                  <option *ngFor="let e of brandsLists" value="{{ e.id }}">
                    {{ e.name }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Model Name</label>
                <input
                  type="text"
                  id="modelName"
                  name="modelName"
                  #modelName="ngModel"
                  [(ngModel)]="currentModel.model_name"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Fuel Burn Coefficients</label>
                <input
                  type="text"
                  id="modelFBCof"
                  name="modelFBCof"
                  #modelFBCof="ngModel"
                  [(ngModel)]="currentModel.fuel_burn_coefficient"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Harvester Purchase Default Price ($):</label>
                <input
                  type="text"
                  id="modelDefaultPrice"
                  name="modelDefaultPrice"
                  #modelDefaultPrice="ngModel"
                  [(ngModel)]="currentModel.default_new_value"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Harvester Life Hours:</label>
                <input
                  type="text"
                  id="modelDefaultLife"
                  name="modelDefaultLife"
                  #modelDefaultLife="ngModel"
                  [(ngModel)]="currentModel.default_life_hours"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Default Salvage Value ($)</label>
                <input
                  type="text"
                  id="modelDefaultSV"
                  name="modelDefaultSV"
                  #modelDefaultSV="ngModel"
                  [(ngModel)]="currentModel.default_salvage_value"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Version</label>
                <input
                  type="text"
                  id="model_version"
                  name="model_version"
                  #model_version="ngModel"
                  [(ngModel)]="currentModel.version"
                />
              </div>
              <div class="fieldwrap label-inline"></div>
              <div class="fieldwrap label-inline">
                <button
                  class="btn-save"
                  type="submit"
                  (click)="save('model', currentModel.ext_id)"
                >
                  {{ harvesterModelText }}
                </button>
                <button
                  class="btn-save"
                  type="submit"
                  (click)="cancel('model')"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
    <div
      class="columns-wrap"
      *ngIf="tabHeader == 'Harvester Fan Configuration'"
    >
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Brand</th>
            <th scope="col">Configuration Name</th>
            <th scope="col">Yield loss coefficient</th>
            <th scope="col">Bulk density coefficient</th>
            <th scope="col">Damp moisture coefficient</th>
            <th scope="col">Version</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of configLists">
            <td>{{ row.brand_name }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.yield_loss_coefficient }}</td>
            <td>{{ row.bulk_density_coefficient }}</td>
            <td>{{ row.damp_moisture_coefficient }}</td>
            <td>{{ row.version }}</td>
            <td>
              <button
                class="btn-small btn-left-space"
                type="button"
                (click)="update('config', row.id)"
              >
                Edit
              </button>
              <!-- <button class="btn-small btn-left-space" type="button"
                                (click)="yesNoDialog('detete')">Delete</button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <fieldset>
        <div class="ccolumns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline"></div>
              <div class="fieldwrap label-inline">
                <label>Brand </label>
                <select
                  id="configBrand"
                  name="configBrand"
                  #configBrand="ngModel"
                  [(ngModel)]="currentConfig.brand_id"
                >
                  <option *ngFor="let e of brandsLists" value="{{ e.id }}">
                    {{ e.name }}
                  </option>
                </select>
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration Name</label>
                <input
                  type="text"
                  id="configName"
                  name="configName"
                  #configName="ngModel"
                  [(ngModel)]="currentConfig.name"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Yield loss coefficien</label>
                <input
                  type="number"
                  id="configYLC"
                  name="configYLC"
                  #configYLC="ngModel"
                  [(ngModel)]="currentConfig.yield_loss_coefficient"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Bulk density coefficient</label>
                <input
                  type="number"
                  id="configBDC"
                  name="configBDC"
                  #configBDC="ngModel"
                  [(ngModel)]="currentConfig.bulk_density_coefficient"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label
                  >Damp moisture coefficient<span class="small"
                    >This number will be subtracted from target fan speed during
                    wet condition</span
                  ></label
                >
                <input
                  type="number"
                  id="configDMC"
                  name="configDMC"
                  #configDMC="ngModel"
                  [(ngModel)]="currentConfig.damp_moisture_coefficient"
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Small1</label>
                <input
                  type="number"
                  id="configsmall1"
                  name="configsmall1"
                  #configsmall1="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_small_1_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Small2</label>
                <input
                  type="number"
                  id="configsSmall2"
                  name="configsSmall2"
                  #configsSmall2="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_small_2_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Intermediate</label>
                <input
                  type="number"
                  id="configIntermediate"
                  name="configIntermediate"
                  #configIntermediate="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_intermediate_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Inter-Large1</label>
                <input
                  type="number"
                  id="configInterLarge1"
                  name="configInterLarge1"
                  #configInterLarge1="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_inter_large_1_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Inter-Large2</label>
                <input
                  type="number"
                  id="configInterLarge2"
                  name="configInterLarge2"
                  #configInterLarge2="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_inter_large_2_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Large1</label>
                <input
                  type="number"
                  id="configsLarge1"
                  name="configsLarge1"
                  #configsLarge1="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_large_1_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Large2</label>
                <input
                  type="number"
                  id="configsLarge2"
                  name="configsLarge2"
                  #configsLarge2="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_large_2_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Vlarge1</label>
                <input
                  type="number"
                  id="configVlarge1"
                  name="configVlarge1"
                  #configVlarge1="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_very_large_1_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Configuration - Vlarge2</label>
                <input
                  type="number"
                  id="configVlarge2"
                  name="configVlarge2"
                  #configVlarge2="ngModel"
                  [(ngModel)]="
                    currentConfig.fan_speed_for_slenderess_very_large_2_at_flow_rate_100
                  "
                />
              </div>
              <div class="fieldwrap label-inline">
                <label>Version</label>
                <input
                  type="number"
                  id="configVersion"
                  name="configVersion"
                  #configVersion="ngModel"
                  [(ngModel)]="currentConfig.version"
                />
              </div>
              <div class="fieldwrap label-inline">
                <button
                  class="btn-save"
                  type="submit"
                  (click)="save('config', currentConfig.ext_id)"
                >
                  {{ harvesterConfigText }}
                </button>
                <button
                  class="btn-save"
                  type="submit"
                  (click)="cancel('config')"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
    <div class="columns-wrap" *ngIf="tabHeader == 'Harvester Brands'">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Brand Id</th>
            <th scope="col">Brand Name</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of brandsLists">
            <td>{{ row.id }}</td>
            <td>{{ row.name }}</td>
            <td>
              <button
                class="btn-small btn-left-space"
                type="button"
                (click)="update('brand', row.id)"
              >
                Edit
              </button>
              <!-- <button class="btn-small btn-left-space" type="button"
                                (click)="yesNoDialog('ss')">Delete</button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <fieldset>
        <div class="columns12">
          <form>
            <div class="columns2">
              <div class="fieldwrap label-inline">
                <label>Brand Name: </label>
                <input
                  type="text"
                  id="branName"
                  name="branName"
                  #branName="ngModel"
                  [(ngModel)]="newBrand.name"
                />
              </div>
              <div class="fieldwrap label-inline"></div>
              <div class="fieldwrap label-inline">
                <button class="btn-save" type="submit" (click)="save('brand')">
                  {{ harvesterBrandText }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </fieldset>
    </div>
  </div>
</div>
