import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { IDatafields } from '../../models/DataFields';

@Component({
  selector: 'app-mill-copy-season',
  templateUrl: './mill-copy-season.component.html',
  styleUrls: ['./mill-copy-season.component.css'],
  providers: [AdminService]
})
export class MillCopySeasonComponent implements OnInit {
  mill_id: string = '';
  season: string = '';
  iscopy: string = '';
  millSeasonLists: any = [];
  millLists: any = [];
  currentMillSeason: any = {};
  dataPost: any = {};

  fieldNames: IDatafields[] = [
    { value: 'season', desc: 'Season' },
  ]

  constructor(
    private adminApi: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      this.mill_id = params['id'];
      this.season = params['season'];
      this.iscopy = params['iscopy'];
    })

    this.loadMills();
    this.loadMillSeason();

  }

  loadMillSeason(): any {
    this.adminApi.getAll('mills/season').subscribe((l) => {
      if (l.body) {

        this.currentMillSeason = l.body.find(
          (obj: any) =>
            obj.mill_id == Number(this.mill_id) && obj.season == Number(this.season)
        );

        this.currentMillSeason.season = null;
        this.currentMillSeason.created_at = null;
        this.currentMillSeason.updated_at = null;

        this.currentMillSeason.payroll_tax = this.numberToPercent(this.currentMillSeason.payroll_tax, 2);
        this.currentMillSeason.dust_disease_contribution = this.numberToPercent(this.currentMillSeason.dust_disease_contribution, 2);
        this.currentMillSeason.workers_compensation_premium = this.numberToPercent(this.currentMillSeason.workers_compensation_premium, 2);
        this.currentMillSeason.workers_compensation_premium_experience = this.numberToPercent(this.currentMillSeason.workers_compensation_premium_experience, 2);

      }
    });
  }

  loadMills(): any {
    this.adminApi.getAll('mills').subscribe((l) => {
      if (l.body) {
        this.millLists = l.body.filter(
          (obj: any) => obj.status == 'ACTIVE'
        );
      }
    });
  }

  checkStartDate() {
    let start = Date.parse(this.currentMillSeason.season_start_date);
    let end = Date.parse(this.currentMillSeason.season_end_date);
    if (start >= end) {

      this.toastr.warning('The end date should be greater that start date.');
      this.currentMillSeason.season_end_date =
        this.currentMillSeason.season_start_date;
    }
  }

  numberToPercent(input: number, precision: number = 2): number {
    return Number((input * 100).toFixed(precision + 2))
  }

  percentToNumber(input: number, precision: number = 2): number {
    return Number((input / 100).toFixed(precision + 2));
  }

  validateFields(): any {
    let is_valid = true;
    this.fieldNames.forEach((e) => {
      if (!this.currentMillSeason[e.value] || this.currentMillSeason[e.value] == '') {
        this.toastr.warning(`Please enter the ${e.desc}!`);
        is_valid = false;
      }
    });
    return is_valid;
  }


  save() {
    let data = { ...this.currentMillSeason };

    data.dust_disease_contribution = this.percentToNumber(data.dust_disease_contribution, 2)
    data.workers_compensation_premium = this.percentToNumber(data.workers_compensation_premium, 2)
    data.workers_compensation_premium_experience = this.percentToNumber(data.workers_compensation_premium_experience, 2);
    data.payroll_tax = this.percentToNumber(data.payroll_tax, 2);

    if (this.validateFields()) {
      if (this.currentMillSeason.season) {
        const endpoint = `mills/${this.mill_id}/season/${data['season']}`;

        delete data['season'];
        delete data['mill_id'];
        delete data['mill_details'];

        this.create(
          endpoint,
          JSON.stringify(data)
        );
      }
    }
  }

  async create(endpoint: string, data: any): Promise<any> {
    this.adminApi.create(endpoint, data).subscribe(
      data => {
        this.toastr.success('Mill Season created successfully.');
        // location.href = "/admin/regionManagement"
      },
      error => {
        this.toastr.error(error.error.errorMessage)
      }
    );
  }
}
