<form>
  <div class="content-1920">
    <div class="content-padding margin-bottom-small">
      <h2>Group Detail</h2>
      <fieldset>
        <div class="columns-wrap">
          <div class="columns2">
            <div class="fieldwrap label-inline">
              <label>Group Name</label>
              <input
                type="text"
                id="name"
                name="groupname"
                #groupname="ngModel"
                [(ngModel)]="group.name"
              />
            </div>
            <div class="fieldwrap label-inline">
              <label>Season</label>
              <select
                id="season"
                name="season"
                #season="ngModel"
                required
                [(ngModel)]="group.season"
              >
                <option *ngFor="let e of seasons" value="{{ e }}">
                  {{ e }}
                </option>
              </select>
            </div>
            <div class="fieldwrap label-inline">
              <label
                >Estimated total tonnage<span class="small"
                  >(t/annum per harvester)</span
                ></label
              >
              <input
                type="number"
                id="estimated_total_tonnage"
                name="estimated_total_tonnage"
                #estimated_total_tonnage="ngModel"
                [(ngModel)]="group.estimated_total_tonnage"
              />
            </div>
            <div class="fieldwrap label-inline">
              <label
                >Total Area Harvested<span class="small"
                  >(ha/annum per harvester)</span
                ></label
              >
              <input
                type="number"
                id="total_area_harvested"
                name="total_area_harvested"
                #total_area_harvested="ngModel"
                [(ngModel)]="group.total_area_harvested"
              />
            </div>
            <div class="fieldwrap label-inline">
              <label
                >Average Tonnes per Hectare</label
              >
              <input
                type="number"
                id="total_area_harvested"
                name="total_area_harvested"
                value="{{ averageHarvestedArea() | number : '1.0-2' }}"
              />
            </div>
            <div class="fieldwrap label-inline">
              <label
                >Average Ground Speed<span class="small">(km/hr)</span></label
              >
              <app-number-input
                [dataPost]="dataPost.kmhr"
                [numberInput]="group.average_group_ground_speed"
                (numberInputChange)="
                  countChangedHandler($event, 'average_group_ground_speed')
                "
              >
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Average Row Spacing</label>
              <app-number-input
                [dataPost]="dataPost.m"
                [numberInput]="group.average_group_row_spacing"
                (numberInputChange)="
                  countChangedHandler($event, 'average_group_row_spacing')
                "
              >
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <div class="toggle">
                <input
                  id="contract_includes_fuel"
                  name="contract_includes_fuel"
                  type="checkbox"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="group.contract_includes_fuel"
                />
                <label for="contract_includes_fuel"
                  >Contract Rate includes fuel</label
                >
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <h2>Roster</h2>
      <fieldset>
        <div class="columns-wrap">
          <div class="columns3">
            <div class="fieldwrap label-inline">
              <label>Working </label>
              <app-number-input
                [dataPost]="dataPost.days"
                [numberInput]="group.roster_start"
                (numberInputChange)="countChangedHandler($event, 'roster_start')"
              >
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>From</label>
              <app-number-input
                [dataPost]="dataPost.days"
                [numberInput]="group.roster_end"
                (numberInputChange)="countChangedHandler($event, 'roster_end')"
              >
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Shifts</label>
              <select
                id="shifts_per_day"
                name="shifts_per_day"
                #shifts_per_day="ngModel"
                [(ngModel)]="group.shifts_per_day"
              >
                <option selected value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
          </div>
        </div>

        <div class="fieldwrap label-inline" *ngIf="group.roster_end == 7">
          <label
            >Working Normal week<span class="small"
              >If working a normal week (Mon-Fri/Sat), no. of extra
              Saturdays/Sundays worked per season</span
            ></label
          >
          <app-number-input
            [dataPost]="dataPost.days"
            [numberInput]="group.working_normal_week"
            (numberInputChange)="countChangedHandler($event, 'working_normal_week')"
          >
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline" *ngIf="group.roster_end != 7">
          <label
            >Total Rostered Days Off (RDO’s) worked per season<span
              class="small"
              >Only for a staggered group.</span
            ></label
          >
          <app-number-input
            [dataPost]="dataPost.days"
            [numberInput]="group.total_rostered_days_off_worked"
            (numberInputChange)="
              countChangedHandler($event, 'total_rostered_days_off_worked')
            "
          >
          </app-number-input>
        </div>
        <div class="fieldwrap label-inline">
          <label
            >Maximum hours paid per day at ordinary wage before extra time
            (hours)</label
          >
          <app-number-input
            [dataPost]="dataPost.hrs"
            [numberInput]="group.maximum_hours_paid_per_shift"
            [default]="24"
            (numberInputChange)="
              countChangedHandler($event, 'maximum_hours_paid_per_shift')
            "
          >
          </app-number-input>
        </div>
      </fieldset>

      <h2>Preseason</h2>
      <fieldset>
        <div class="columns-wrap">
          <div class="columns2">
            <div class="fieldwrap label-inline">
              <label>Working Weeks</label>
              <app-number-input
                [dataPost]="dataPost.wks"
                [numberInput]="group.preseason_working_weeks"
                (numberInputChange)="
                  countChangedHandler($event, 'preseason_working_weeks')
                "
              >
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Working Days</label>
              <app-number-input
                [dataPost]="dataPost.pwk"
                [numberInput]="group.preseason_working_days_per_week"
                (numberInputChange)="
                  countChangedHandler($event, 'preseason_working_days_per_week')
                "
              >
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label>Working Avg Hours</label>
              <app-number-input
                [dataPost]="dataPost.day"
                [numberInput]="group.preseason_average_working_hours_per_day"
                (numberInputChange)="
                  countChangedHandler(
                    $event,
                    'preseason_average_working_hours_per_day'
                  )
                "
              >
              </app-number-input>
            </div>
            <div class="fieldwrap label-inline">
              <label
                >Number of labour units utilised for pre-season
                maintenance</label
              >
              <app-number-input
                [dataPost]="dataPost.unit_s"
                [(numberInput)]="group.number_of_labour_units_utilised"
                [format]="formatLabour"
              >
              </app-number-input>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="bg-light">
        <div class="content-1920">
          <div class="content-padding margin-top-small margin-bottom-small">
            <div class="fieldwrap fieldwrap-type-submit align-right">
              <a class="btn__secondary btn-back" routerLink="/contractor-groups"
                >Back</a
              >
              <button class="btn-save" type="submit" (click)="save()">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<form>
  <div class="content-1920">
    <div class="content-padding margin-bottom-small">
      <h2>Growers</h2>
      <fieldset *ngIf="group.id">
        <div class="fieldwrap">
          <label>Invite Grower</label>
          <div class="button-inline">
            <input
              type="text"
              id="invite_info"
              name="invite_info"
              placeholder="Enter a phone number or email"
              #invite_info="ngModel"
              [(ngModel)]="group.invite_info"
            />
            <button
              class="tn__tertiary btn-add"
              type="submit"
              (click)="invite()"
            >
              Invite
            </button>
          </div>
        </div>
        <div class="columns-wrap"></div>
        <app-contractor-select-block
          (reloadGroup)="ngOnInit()"
          [gridData]="growerData"
          [groupId]="group.ext_id"
        >
        </app-contractor-select-block>
      </fieldset>
    </div>
  </div>
</form>


<div id="show_results" [ngClass]="{ 'overlay open': dialog_open }">
  <div class="modal-box">
    <button
      class="close"
      type="button"
      (click)="dialog_open = false"
    ></button>
    <div class="modal-header">
      <h2>Duplicate Repair And Maintenance Items</h2>
    </div>
    <form>
      <fieldset>
        <div class="columns4">
          <div class="fieldwrap fieldwrap-type-submit">
            <button
              class="btn-save"
              type="submit"
              (click)="dialog_open = false"
            >
              Close
            </button>
            <button
              (click)="save(); dialog_open = false"
              class="btn__secondary"
              type="button"
            >
              Save
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>