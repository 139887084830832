<div class="header">
  <h3 mat-dialog-title class="orange">{{ data?.title }}</h3>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>X</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>{{ data?.message }}</p>
</div>

<div mat-dialog-actions [align]="'end'">
  <button mat-raised-button class="grey" [mat-dialog-close]="false">
    {{ data?.cancelCaption }}
  </button>
  <button
    mat-raised-button
    class="orange"
    style="margin-left: 10px !important"
    [mat-dialog-close]="true"
  >
    {{ data?.confirmCaption }}
  </button>
</div>
