import { Component, isDevMode, OnInit } from '@angular/core';
import { GroupService } from '../../services/group.service';
import { IGroup } from '../../models/Group';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { InputPostData } from 'src/app/models/InputData';
import { IDatafields } from '../../models/DataFields';
import { AdminService } from 'src/app/services/admin.service';
import { HarvestService } from 'src/app/services/harvest.service';
import { formatNumber} from "@angular/common"
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-contractor-group-detail',
  templateUrl: './contractor-group-detail.component.html',
  styleUrls: ['./contractor-group-detail.component.css'],
  providers: [AdminService, GroupService, InputPostData, HarvestService],
  
})
export class ContractorGroupDetailComponent implements OnInit {
  
  id: string = '';
  invite_info: any = '';
  group: any = {};
  seasons: any = {};
  harvester_driver_compensation_types: any = {};

  isEdit: boolean = false;
  growerData: any = [];
  inviteError: any = {};
  dataPost: any = {};
  Counter: number = 0;
  group_ext_id: string = '';
  allowance_open: boolean = false;
  dialog_open: boolean = false;

  fieldNames: IDatafields[] = [
    { value: 'name', desc: 'Group Name' },
    { value: 'season', desc: 'Season' },
    { value: 'estimated_total_tonnage', desc: 'Estimated total tonnage' },
    { value: 'total_area_harvested', desc: 'Total Area Harvested' },
    { value: 'roster_start', desc: 'Roster Start' },
    { value: 'roster_end', desc: 'Roster End' },
    { value: 'shifts_per_day', desc: 'Shifts' },
    {
      value: 'maximum_hours_paid_per_shift',
      desc: 'Maximum hours paid per day at ordinary wage before extra time',
    },
    { value: 'average_group_ground_speed', desc: 'Average Ground Speed' },
    { value: 'average_group_row_spacing', desc: 'Average Row Spacing' },
  ];

  formatLabour(input: number | null): string {
    return formatNumber((input ?? 0), 'en-AU', "0.1-1");
  }

  constructor(
    private adminApi: AdminService,
    private groupApi: GroupService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private inputData: InputPostData,
    private harversterService: HarvestService,
    private logging: LoggingService
  ) {
    this.group = {} as IGroup;
    this.allowance_open = false;
  }

  showAllowance(): void {

    this.allowance_open = true;
  }

  loadGrowers(): any {
    this.groupApi.getListgrowersInGroup(this.group_ext_id).subscribe((r) => {
      if (r.body.growers) {
        this.growerData = r.body.growers;

      }
    });
  }

  loadSeasons(): any {
    this.adminApi.getSeasons().subscribe((l) => {

      if (l.body) {
        this.seasons = l.body;
      }
    });
  }

  onUpdate(): void {
    this.dialog_open = true;

  }

  countChangedHandler(event: number, variableName: string) {
    this.group[variableName] = event;
  }

  ngOnInit(): void {
    this.dataPost = this.inputData.dataType;
    // this.group.includes_super = false;
    // this.group.includes_annual_leave_loading = false;

    this.route.queryParams.subscribe((params) => {
      this.group_ext_id = params['id'];

      if (this.group_ext_id) {
        this.groupApi.get(this.group_ext_id).subscribe(
          (r) => {
            if (r.body.status == 'ACTIVE') {
              this.group = r.body;
              this.logging.info(this.group);
            }
          },
          (error) => this.toastr.error(error.error.errorMessage)
        );
        this.loadGrowers();
      }
    });
    this.loadSeasons();
    this.harvester_driver_compensation_types = ['tonne'];
  }

  validateFields(): any {
    let is_valid = true;
    this.fieldNames.forEach((e) => {
      if (!this.group[e.value] || this.group[e.value] == '') {
        this.toastr.warning(`Please enter the ${e.desc}!`);
        is_valid = false;
      }
    });
    return is_valid;
  }

  async save(): Promise<any> {
    if (await this.validateFields()) {
      if (!this.group.id) {
        this.groupApi.create(this.group).subscribe(
          (h) => {
            if (h.body.status == 'ACTIVE') {
              this.group.id = h.body.id;
              this.toastr.success('Group created successfully.');
            } else {
              this.toastr.error(h.body.errorMessage);
            }
          },
          (error) => this.toastr.error(error.error.errorMessage)
        );
      } else {
        this.groupApi.update(this.group, this.group.ext_id).subscribe(
          
          (h) => {
            this.logging.info(this.group);
            if (h.body) {
              this.toastr.success('Group update successfully.');
            } else {
              this.toastr.error(h.errorMessage);
            }
          },
          (error) => this.toastr.error(error.error.errorMessage)
        );
      }
    }
  }

  invite(): void {
    if (this.group.invite_info && this.group.id) {
      this.groupApi
        .postInvitation(this.group.ext_id, {
          share_with: this.group.invite_info,
        })
        .subscribe(
          (h) => {
            if (h.body) {
              this.toastr.success('Invite created successfully.');
              this.loadGrowers();
            }
          },
          (error) => this.toastr.error(error.error.errorMessage)
        );
    }
  }
  averageHarvestedArea() {
    return this.group.estimated_total_tonnage / this.group.total_area_harvested;
  }
}
