import { Component, OnInit, NgModule } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { AdminService } from 'src/app/services/admin.service';
import { InputPostData, SeasonList } from 'src/app/models/InputData';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HarvestService } from 'src/app/services/harvest.service';
import { IDatafields } from '../../models/DataFields';
import { Console, debug } from 'console';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { ConsoleLogger } from '@aws-amplify/core';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'app-repair-and-maintenance',
  templateUrl: './repair-and-maintenance.component.html',
  styleUrls: ['./repair-and-maintenance.component.css'],
  providers: [AdminService, InputPostData, HarvestService],
})
export class RepairAndMaintenanceComponent implements OnInit {
  currentYear = new Date().getFullYear();
  years = [
    this.currentYear - 3,
    this.currentYear - 2,
    this.currentYear - 1,
    this.currentYear,
    this.currentYear + 1,
    this.currentYear + 2,
    this.currentYear + 3,
  ];
  selectStartYear: any;
  typeList: any = [];
  selectEndYear: any;
  repairMaintenance: any = {};
  selectedRepairMaintenance: any = {};
  selectedSeason: any = 'All'
  selectedType: any
  itemsText = 'Add Items';
  brandsText = 'Add Brands';
  flag1: Boolean = true;
  typesList: any = ['harvester', 'haulouts', 'other'];
  practiceAffectList: any = [
    {
      value: 'no_change',
      label: 'No Change',
    },
    {
      value: 'harvesting_hours',
      label: 'Harvesting Hours',
    },
    {
      value: 'tonnes',
      label: 'Tonnes',
    },
    {
      value: 'harvester_engine_hours',
      label: 'Harvester Engine Hours',
    },
    {
      value: 'area',
      label: 'Area',
    },
    {
      value: 'haulout_engine_hours',
      label: 'Haulout Engine Hours',
    },
  ];
  costAndFrequencyTypeList: any = ['years', 'hours', 'tonnes', 'hectares'];
  escalationCriteriaList: any = [
    {
      value: 'ground_speed',
      label: 'Ground Speed',
    },
    {
      value: 'fan_speed * flow_rate',
      label: 'Fan Speed * Flow Rate',
    },
    {
      value: 'annum',
      label: 'Annum',
    },
    {
      value: 'tonnage',
      label: 'Tonnage',
    },
  ];
  periodList: any = [
    'no',
    'Yes, every 1-2 years',
    'Sometimes, every 1-2 years',
    "Yes, every 2-3 years",
    'Yes, every 3-4 years',
    'Sometimes, every 3-4 years',
    'Yes, every 5-6 years',
    'Sometimes, every 5-6 years',
    'Yes, every 7-8 years',
    'Sometimes, every 7-8 years',
    'Yes, every 9-10 years',
    'Sometimes, every 9-10 years',
    'Yes, every 11-12 years',
    'Sometimes, every 11-12 years',
    'Yes, every 13-14 years',
    'Sometimes, every 13-14 years',
    "Yes, every 15 years",
    "Yes, every 20 years"
  ];
  checkedToggle: Boolean = true;
  brandsLists: any = [];
  itemsList: any = [];
  savedItemsList: any = [];
  seasonList: any = [];
  names: any = [];
  affect: any = [];
  brandID: any = [];
  cost: any = [];
  frequency: any = [];
  tabHeader: String = 'Repair And Maintenance Items';
  dataPost: any = {};
  show_results_dialog_open: boolean = false;
  selectedItem: any = {};
  selectable = {
    selectables: [{ selectableName: '', Affect: '' }],
  };
  form: FormGroup = this.formBuilder.group({
    selectables: this.buildContacts(this.selectable.selectables),
  });
  brand = {
    brands: [{ brandID: '', Cost: '', Frequency: '' }],
  };
  formBrands: FormGroup = this.formBuilder.group({
    brands: this.buildBrands(this.brand.brands),
  });
  fieldNames: IDatafields[] = [
    { value: 'name', desc: 'name' },
    // { value: 'season', desc: 'season' },
    { value: 'type', desc: 'type' },
    { value: 'cost_frequency_type', desc: 'Cost and frequency type' },
    { value: 'escalation_criteria', desc: 'Escalation criteria' },
    { value: 'practice_affect', desc: 'Practice Affect' },
  ];

  constructor(
    private inputData: InputPostData,
    private dialog: DialogService,
    private formBuilder: FormBuilder,
    private harvesterApi: HarvestService,
    private toastr: ToastrService,
    private adminApi: AdminService
  ) { }

  //selectables form group
  get selectables(): FormArray {
    return this.form.get('selectables') as FormArray;
  }
  buildContacts(
    selectables: { selectableName: string; Affect: string }[] = []
  ) {
    return this.formBuilder.array(
      selectables.map((selectable) => this.formBuilder.group(selectable))
    );
  }
  addContactField() {
    this.selectables.push(
      this.formBuilder.group({ selectableName: '', Affect: '' })
    );
  }
  removeContactField(): void {
    this.selectables.controls.pop();
    this.names.pop();
    this.affect.pop();
  }

  //brands formgroup
  get brands(): FormArray {
    return this.formBrands.get('brands') as FormArray;
  }
  buildBrands(
    brands: { brandID: string; Cost: string; Frequency: string }[] = []
  ) {
    return this.formBuilder.array(
      brands.map((brand) => this.formBuilder.group(brand))
    );
  }
  addBrandField() {
    this.brands.push(
      this.formBuilder.group({ brandID: '', Cost: '', Frequency: '' })
    );
  }
  removeBrandsField(): void {
    this.brands.controls.pop();
    this.brandID.pop();
    this.cost.pop();
    this.frequency.pop();
  }

  loadBrands(): any {
    this.harvesterApi.getAll('harvester/brands').subscribe((l) => {
      if (l.body) {
        this.brandsLists = l.body;
      }
    });
  }

  loadItems(): any {
    this.harvesterApi.getAll('rnm/items').subscribe((l) => {
      this.itemsList = l.body ?? [];
      this.savedItemsList = this.itemsList;

      //create array of seasons for dropdown menu
      let seasons = new Set()
      this.itemsList.forEach(function (value: any) {
        seasons.add(value.season_coverage_start);
        seasons.add(value.season_coverage_end)
      });
      let seasons_no_duplicates = [...seasons].filter((element): element is string => { return element !== null; }).sort(); //del duplicates and null values
      this.seasonList = seasons_no_duplicates
      this.seasonList = ['All'].concat(this.seasonList) // add All at the start

    });
  }

  currentSeason() {
    this.itemsList = this.savedItemsList

    //filter just for obj.season = selectedSeason 
    if (this.selectedSeason == 'All') {
      { } // skip
    } else {

      this.itemsList = this.itemsList.filter((obj: any) => obj.season_coverage_start <= this.selectedSeason && this.selectedSeason <= obj.season_coverage_end)
      console.log(this.itemsList);
    }
  }

  edit(id: number, _?: String) {
    //empty selectables array before load the new item
    this.selectables.controls = [];
    this.brands.controls = [];
    this.itemsText = 'Update Items';
    //use selected obj to populate fields
    this.repairMaintenance = this.itemsList.find((obj: any) => obj.id == id);

    this.repairMaintenance.toggleSelectables =
      this.repairMaintenance['selectable'];

    this.repairMaintenance['options']?.map((item: any, index: number) => {
      this.names[index] = item.name;
      this.affect[index] = item.affect;
      this.selectables.push(
        this.formBuilder.group({
          selectableName: item.name,
          Affect: item.affect,
        })
      );
    });

    this.repairMaintenance?.brands.map((item: any, index: number) => {

      this.brandID[index] = item.id;
      this.cost[index] = item.cost;
      this.frequency[index] = item.frequency;
      this.brands.push(
        this.formBuilder.group({
          brandID: item.brand_id,
          Cost: item.cost,
          Frequency: item.frequency,
        })
      );
    }) ??
      this.brands.push(
        this.formBuilder.group({ brandID: '', Cost: '', Frequency: '' })
      );

    if (this.repairMaintenance?.brands.length == 0)
      this.brands.push(
        this.formBuilder.group({ brandID: '', Cost: '', Frequency: '' })
      );
  }

  deleteDialog(id: number) {
    this.dialog
      .confirmDialog({
        title: 'Are you sure?',
        message: 'Are you sure you want to delete?',
        confirmCaption: 'Yes',
        cancelCaption: 'No',
      })
      .subscribe((yes) => {
        if (yes) {
          this.adminApi.delete_rnm_item(id).subscribe((h) => {

            if (h.body == null) {
              this.toastr.success('Item delete successfully.');
            }
            if (h.body.id) {
              this.toastr.success('Item delete successfully.');
            } else {
              this.toastr.error(h.body.error);
            }
          });
        }
      });
  }

  duplicate(id: number, _?: String): void {
    //use selected obj to populate fields
    this.selectedRepairMaintenance = this.itemsList.find(
      (obj: any) => obj.id == id
    );


    this.edit(id, _);
    this.show_results_dialog_open = true;
  }

  cancel(): void {
    //empty selectables array before load the new item
    this.selectables.controls = [];
    this.selectables.push(
      this.formBuilder.group({ selectableName: '', Affect: '' })
    );
    this.names = [];
    this.affect = [];

    //empty brands array before load the new item
    this.brands.controls = [];
    this.brands.push(
      this.formBuilder.group({ brandID: null, Cost: null, Frequency: null })
    );
    this.brandID = [];
    this.cost = [];
    this.frequency = [];

    this.itemsText = 'Add Items';
    this.repairMaintenance.toggleSelectables = false;
    this.repairMaintenance = {};
  }

  changeTabs(type: string) {
    switch (type) {
      case 'Items':
        this.tabHeader = 'Repair And Maintenance Items';
        break;
      case 'Brands':
        this.tabHeader = 'Repair And Maintenance Brands';
        break;
      default:
        break;
    }
  }

  countChangedHandler(event: number, variableName: string) {
    this.repairMaintenance[variableName] = event;
  }

  save() {
    //fill in checkings
    let is_valid = true;

    this.fieldNames.forEach((e) => {
      if (!this.repairMaintenance[e.value]) {
        this.toastr.warning(`Please select the ${e.desc}`);
        return (is_valid = false);
      } else {
        return (is_valid = true);
      }
    });

    if (this.repairMaintenance.season_coverage_start > this.repairMaintenance.season_coverage_end) {
      this.toastr.warning('Season Coverage End value for item cannot be less than Season Coverage Start');
      is_valid = false;
    }
    if (this.repairMaintenance.selectable == true) {
      for (let i = 0; i < this.names.length; i++) {
        if (this.names[i] == undefined) {
          this.toastr.warning('Please enter Selectable Names');
          is_valid = false;
        }
        if (this.affect[i] == undefined) {
          this.toastr.warning('Please enter Selectable Affects');
          is_valid = false;
        }
      }
    }
    if (this.show_results_dialog_open == true) {
      if (!this.selectStartYear) {
        this.toastr.warning('Please select Season Start Year');
        is_valid = false;
      }
      if (!this.selectEndYear) {
        this.toastr.warning('Please select Season End Year');
        is_valid = false;
      }
    }
    for (let i = 0; i < this.brandID.length; i++) {
      if (!this.brandID[i]) {
        this.toastr.warning('Please enter Brand ID');
        is_valid = false;
      }
      if (!this.cost[i]) {
        this.toastr.warning('Please enter Cost');
        is_valid = false;
      }
      if (!this.frequency[i]) {
        this.toastr.warning('Please enter Frequency');
        is_valid = false;
      }
    }


    //prepare obj for backend
    //push brands if exists
    if (is_valid) {
      this.repairMaintenance.brands = [];
      if (this.brandID.length > 0) {
        for (let i = 0; i < this.brandID.length; i++) {
          if (
            this.brandID[i] == undefined ||
            this.cost[i] == undefined ||
            this.frequency[i] == undefined
          ) {
            {
            }
          } else {
            this.repairMaintenance.brands.push({
              brand_id: this.brandID[i],
              cost: this.cost[i],
              frequency: this.frequency[i],
            });
          }
        }
      }

      //push options if exists
      this.repairMaintenance.options = [];
      if (this.repairMaintenance.selectable == true) {
        for (let i = 0; i < this.names.length; i++) {
          this.repairMaintenance.options.push({
            name: this.names[i],
            affect: this.affect[i],
          });
        }
      }
      let data = this.repairMaintenance;


      //convert escalation criteria to percentage
      data.escalation_impact = Number(this.repairMaintenance.escalation_impact);

      //if item is duplicated
      if (
        this.selectStartYear !== undefined &&
        this.selectEndYear !== undefined
      ) {
        delete data.id;
        data.season_coverage_start = this.selectStartYear;
        data.season_coverage_end = this.selectEndYear;

        this.create(
          'rnm/items',
          'Repar and Maintenance Items',
          JSON.stringify(data)
        );
      } else {
        //if item is edited
        this.create(
          'rnm/items',
          'Repar and Maintenance Items',
          JSON.stringify(data)
        );
      }
    }
  }

  async create(endpoint: string, type: string, data: any): Promise<any> {
    this.adminApi.create(endpoint, data).subscribe((h) => {

      if (h.body.id) {
        this.toastr.success(type + ' created successfully.');
      } else {
        this.toastr.error(h.body.error);
      }
    });
  }

  ngOnInit(): void {
    this.dataPost = this.inputData.dataType;
    this.loadBrands();
    this.loadItems();
  }
}
