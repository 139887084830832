import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConsoleLogger } from '@aws-amplify/core';
import { InputPostData } from 'src/app/models/InputData';
import { HarvestService } from 'src/app/services/harvest.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from "@angular/common";

@Component({
  selector: 'app-fixed-cost',
  templateUrl: './fixed-cost.component.html',
  styleUrls: ['./fixed-cost.component.css'],
  providers: [InputPostData, HarvestService],
})
export class FixedCostComponent implements OnInit {
  harvester_id: string = '';
  season: string = '';
  route_link: string = '';
  costs: any = {};
  dataPost: any = {};
  total: number = 0;
  total_per_harv: number = 0;
  numberHarvester: any;
  // selectedYear: number = 2022;
  nonCostFields = [
    'created_at',
    'updated_at',
    'deleted_at',
    'status',
    'season',
    'id',
    'owner_id',
  ];

  constructor(
    private inputData: InputPostData,
    private harversterApi: HarvestService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  responseError(error: any): any {
    let message = '';
    if ('errorType' in error) {
      if (error.errorType != 'LambdaHttpError') {
        message += error.errorType + ':';
      }
    }
    if ('errorMessage' in error) {
      message += error.errorMessage.slice(0, 150);
    }
    return this.toastr.error(message);
  }

  totalCalculation(obj: any) {
    delete obj.total_cost;
    delete obj.total_cost_per_harvester;
    delete obj.created_at;
    delete obj.updated_at;
    delete obj.deleted_at;
    delete obj.status;
    delete obj.season;
    delete obj.id;
    delete obj.owner_id;

    var sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }

  countChangedHandler(event: number, variableName: string) {
    this.costs[variableName] = event;
    this.costs.total_cost = this.totalCalculation(this.costs);
    this.costs.total_cost_per_harvester =
      Math.trunc(this.costs.total_cost / this.numberHarvester);

  }

  loadHarvesterSeason(year: number): any {
    this.harversterApi.getAll('/harvester/seasons/' + year).subscribe((l) => {
      if (l.body) {
        this.numberHarvester = l.body.length;
      }
    });
  }

  loadFixedCost(year: number): any {
    this.harversterApi.getFixedCost(year.toString()).subscribe((l) => {
      if (l.body) {
        this.costs = l.body;
        this.costs.total_cost_per_harvester = Math.trunc(this.costs.total_cost_per_harvester)

        // set 0 value on all null cost fields
        // for( var el in this.costs ) {
        //   if( this.costs.hasOwnProperty( el ) ) {
        //     if(this.costs[el] == null && !this.nonCostFields.includes(el))

        //       this.costs[el] = 0;
        //   }
        // }
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.harvester_id = params['id'];
      this.season = params['season'];
      this.route_link = '/' + params['route_link'];
    })

    // this.selectedYear = new Date().getFullYear();
    this.dataPost = this.inputData.dataType;
    this.loadHarvesterSeason(parseInt(this.season));
    this.loadFixedCost(parseInt(this.season));
  }


  back(): void{
    this.location.back();
  }

  save(): any {

    delete this.costs.owner_id;
    this.harversterApi
      .postFixedCost(this.costs, this.season.toString())
      .subscribe(
        (s) => {

          if (s.body.status == 'ACTIVE') {
            this.toastr.success('Fixed cost updated successfully.');
          } else {
            this.toastr.error(s.errorMessage);
          }
        },
        (error) => {
          this.responseError(error);
        }
      );
  }
}
