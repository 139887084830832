import { Component, OnInit } from '@angular/core';
import {
  IUser,
  AdminCognitoService,
} from '../../services/admin-cognito.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-reset-pwd',
  templateUrl: './admin-reset-pwd.component.html',
  styleUrls: ['./admin-reset-pwd.component.css'],
})
export class AdminResetPwdComponent {
  loading: boolean;
  user: IUser;

  constructor(
    private router: Router,
    private cognitoService: AdminCognitoService,
    private toastr: ToastrService
  ) {
    this.loading = false;
    this.user = {} as IUser;
  }

  public resetPassword(): void {
    this.loading = true;
    this.cognitoService
      .changePasswordUser(this.user)
      .then((u) => {
        this.router.navigate(['/userManagement']);
      })
      .catch((e) => {
        this.toastr.error(e.message);
        this.loading = false;
      });
  }
}
